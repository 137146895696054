import React from 'react';
import { useSelector } from 'react-redux';

import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 

import { Link } from 'react-router-dom';
 
import ReactGA from 'react-ga4';
ReactGA.initialize('G-P7N889SGDD');











const HelpCenterPublic = () => {

    const { Login } = useSelector((state) => state); 
    const isLoggedIn = Login?.user?.email ? true : false;
    const notLoggedIn = !isLoggedIn;

    if( isLoggedIn ){
        ReactGA.send({ hitType: "pageview", page: "/Resources/HelpCenter", title: "Respondent Help Center Page" });
    } else {
        ReactGA.send({ hitType: "pageview", page: "/Resources/HelpCenter", title: "Public Help Center Page" });
    }
    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
                <CardHeader className="align-items-center d-flex">
                <h2 className="mb-10 flex-grow-1">Frequently Asked Questions</h2>
                </CardHeader>
                <CardBody style={{maxWidth:'1385px'}}>
                {notLoggedIn && (
                    <>
                    
<div className="d-flex justify-content-center mb-4"><Link to="/requestaccess" className="btn btn-primary add-btn mb-1" style={{marginRight:'8px'}}>Get in touch with the EM team</Link> <a className="btn btn-primary add-btn mb-1" href="https://www.ecosystemmarketplace.com" target="_blank">EM News</a></div>
<p><h3 className="help-center-header">ABOUT ECOSYSTEM MARKETPLACE &amp; FOREST TRENDS:</h3></p>
<p><strong>Ecosystem Marketplace (EM)</strong>, a non-profit initiative of Forest Trends, is a leading global source of credible information on environmental finance, markets, and payments for ecosystem services. For nearly two decades, EM has run the world’s first and only globally recognized and standardized reporting and transparency platform for carbon market credit pricing data, news, and insights.</p>
<p>EM has earned the trust of carbon market stakeholders by collecting, tracking, and aggregating proprietary market data from EM Respondents for almost 20 years – part of our commitment to create a more transparent and accessible carbon market. EM Respondents are market actors such as project developers, investors, traders, retailers, etc., who regularly disclose their confidential transaction and project data to EM via the Hub, supporting EM’s effort to drive transparency by providing market actors and observers with EM derived insights, analysis and visualizations.</p>
<p>EM is also a leading convener in the sector, bringing together experts to provide technical insights that cover emerging policy and key trends across environmental markets for decision- and policy-makers, market actors and observers.</p>
<p><strong>Forest Trends Association (FT)</strong> is a 501(c)(3) organization founded in 1999. Forest Trends works to conserve forests and other ecosystems through the creation and wide adoption of a broad range of environmental finance, markets, and other payment and incentive mechanisms. Forest Trends does so by 1) providing transparent information on ecosystem values, finance, and markets through knowledge acquisition, analysis, and dissemination; 2) convening diverse coalitions, partners, and communities of practice to promote environmental values and advance development of new markets and payment mechanisms; and 3) demonstrating successful tools, standards, and models of innovative finance for conservation.</p>
<p><h3 className="help-center-header">HUB ACCESS</h3></p>
<p><strong>What does public general access to the Hub look like?&nbsp; </strong></p>
<p>The <strong>public</strong> accesses the Hub through EM’s website without logging into the platform with a user account. This includes access to:</p>
<ul className="checkmark-ul">
<li><strong>Most recent full year data that EM has tracked and analyzed </strong></li>
<li><strong>Total Volume and Average Price Data by year, category and location </strong></li>
<li><strong>EM Taxonomy Overview</strong>: A detailed breakdown of how Ecosystem Marketplace categorizes projects within its database.</li>
<li><strong>Project Catalog</strong>: A comprehensive list of projects.
<ul>
<li>Users can filter projects by name, ID, type, country, and Sustainable Development Goals (SDGs).</li>
<li>Specific details about each project, such as type, methodology, and credits issued or retired, are also available.</li>
</ul>
</li>
</ul>
<p><strong>Respondents</strong> are market participants who regularly disclose and submit their confidential data to EM via the Hub through a user account. This includes access to:</p>
<ul className="checkmark-ul">
<li><strong>Respondent organization data submission and download</strong>: The ability to submit trade data and download all historical trade submissions.</li>
<li><strong>Respondent organization aggregated data</strong>: Access to personalized graphs and tables containing aggregated data submitted by users.</li>
<li><strong>Most recent pricing data</strong>: Access to the latest graphs and tables showcasing pricing data, organized by standards, categories, and more.</li>
</ul>
<p><strong>Supporters</strong> are partners of Ecosystem Marketplace who help make the work and mission of EM possible, specifically by supporting key products like EM’s State of the VCM report and the Global Carbon Markets Hub.</p>
<p><strong>I am a market participant interested in becoming an EM Respondent. How do I get more information on becoming an EM Respondent?</strong></p>
<p><Link to="/requestaccess" ref={(node) => {
      if (node) {
        node.style.setProperty("text-decoration", "underline", "important");
      }
    }}>Get in touch</Link> with the Ecosystem Marketplace team to learn more. </p>
<p><h3 className="help-center-header">HUB FEATURES</h3></p>
<p><strong>What information is available on the Hub homepage?</strong></p>
<ul className="checkmark-ul">
<li>Total volume by year: hover your mouse over each year to see the exact volume represented</li>
<li>Average price by year: hover your mouse over each year to see the average price represented</li>
<li>Total volume of credits traded by country: hover your mouse over each country to see the exact volume of credits traded represented
<ul>
<li>Select a specific date range of one or multiple years to see volume in an exact data range by country</li>
</ul>
</li>
</ul>
<p><strong>What information is available on the categories page of the Hub?</strong></p>
<ul className="checkmark-ul">
<li>Explore total volume by project category and year:
<ul>
<li>Hover your mouse of color-coded categories in a specific year to see the exact volume presented</li>
<li>Select or deselect project categories in the legend to see specific categories represented alone or in comparison with other categories (e.g. select only Forestry &amp; Land Use and Renewable Energy categories to see exact volumes by year)</li>
</ul>
</li>
<li>Explore average price by project category and year:
<ul>
<li>Hover your mouse of color-coded average price line for each category to see average price per year</li>
<li>Select or deselect project categories in the legend to see specific categories represented alone or in comparison with other categories (e.g. select only Forestry &amp; Land Use and Renewable Energy categories to see average prices by year)</li>
</ul>
</li>
</ul>
<p><strong>What information is available on the EM Taxonomy page of the Hub?</strong></p>
<p>Ecosystem Marketplace places carbon credits into eight broad categories based on the methodology used to achieve emissions reductions or removals. Within these categories there are multiple clusters of related project types with similar methodologies and mechanisms of emissions reductions and removals. EM utilizes this taxonomy to provide aggregated data insights such as average prices and volumes for related project types at the sub-category level.</p>
<ul className="checkmark-ul">
<li>Select a project category on the EM Taxonomy page and click on project clusters to expand the information shown and see definitions of each project category and cluster</li>
</ul>
<p><strong>What information is available on the Projects page of the Hub?</strong></p>
<p>Find detailed project information on over 20,000 carbon projects. You can filter EM’s project list by Registry ID, Project Name, Type, Country, and SDGs.</p>
<p>Select a project to find detailed information on that project:</p>
<ul className="checkmark-ul">
<li>Registry</li>
<li>Methodology</li>
<li>Project Category</li>
<li>First year of credit issuances for the project</li>
<li>Total number of credits issued for the project</li>
<li>Total number and percentage of issued credits remaining for the project</li>
<li>Total number and percentage of issued credits retired for the project</li>
<li>Sustainable Development Goals</li>
<li>Project Location</li>
<li>Links to the project page in the issuing standard’s registry and/or project website</li>
</ul>
<p><strong>How often is data on the hub updated?</strong></p>
<ul>
    <li>Transaction data is updated annually after the publication of EM’s State of the VCM report</li>
    <li>Project data will be updated regularly</li>
</ul>
<p><h3 className="help-center-header">CONFIDENTIALITY</h3></p>

<p><strong>Can I see prices for specific projects? Does EM only share average prices for project categories and clusters?</strong></p>
<p>EM does not publish price data for specific projects due to EM’s confidentiality policy.</p>
<p>EM aggregates the confidential information of EM Respondents and therefore only shares average prices by project category and/or cluster.</p>
<p><strong>Ecosystem Marketplace’s confidentiality policy:</strong></p>
<p>EM protects respondents trade data by only disclosing aggregated data and following our “rule of three”: EM’s policy is that at least three different respondents need to submit information on any specific data point for EM to disclose that information. If there are not three or more respondents for a given combination of attributes, EM does not publish aggregated data for that data point.</p>
<p><h3 className="help-center-header">USING EM’S HUB DATA</h3></p>
<p><strong>Can I cite EM’s data accessible on the Hub in content that my organization generates?</strong></p>
<p>Yes, but EM requires that that the data you cite is attributed to the Global Carbon Markets Hub. Please use the following citation:</p>
<p>Forest Trends’ Ecosystem Marketplace. 2024. <em>[Title of page].</em> Global Carbon Markets Hub. Retrieved [date], from [URL].</p>
<p>If you have any other questions about how to use data from the Global Carbon Markets Hub or how to cite the Hub in your research, please reach out to the EM team at hub@ecosystemmarketplace.com.</p>
<p>&nbsp;</p>


                    
                    </>
                )}

                {isLoggedIn && (
                    <>
                   
                   <div className="d-flex justify-content-center mb-4"><Link to="/login" className="btn btn-primary add-btn mb-1" style={{marginRight:'8px'}}>Hub login</Link> <Link className="btn btn-primary add-btn mb-1" to="/trade/list" style={{marginRight:'8px'}}>My trades</Link> <Link className="btn btn-primary add-btn mb-1" to="/trade/list" style={{marginRight:'8px'}}>Import new trades</Link><Link to="/requestaccess" className="btn btn-primary add-btn mb-1" style={{marginRight:'8px'}}>Get in touch with the EM team</Link> <a className="btn btn-primary add-btn mb-1" href="https://www.ecosystemmarketplace.com" target="_blank">EM News</a></div>
<p><h3 className="help-center-header">HUB ACCESS</h3></p>
<p><strong>How is access to the Hub different for Respondents than the public?</strong></p>
<p><strong>The public</strong> accesses the Hub through EM’s website without having to log into the platform through a user account. The data insights the public can access include:</p>
<ul>
<li><strong>Data published in the most recent EM SOVCM report, but not the current year of data.</strong></li>
<li><strong>Total Volume and Price Data by year and category </strong></li>
<li><strong>EM Taxonomy Overview</strong>: A detailed breakdown of how Ecosystem Marketplace categorizes projects within its database.</li>
<li><strong>Project Catalog</strong>: A comprehensive list of projects. Users can filter projects by name, ID, type, country, and Sustainable Development Goals (SDGs). Specific details about each project, such as type, methodology, and credits issued or retired, are also available.</li>
</ul>
<p><strong>Respondents</strong> are registered users who disclose their data to EM via the Hub. Through user accounts, Respondents can access their data and the following features:</p>
<ul>
<li><strong>Most Recent Pricing Data</strong>: Access to the latest graphs and tables showcasing pricing data, organized by standards, categories, and more.</li>
<li><strong>Data Submission and Download</strong>: The ability to submit trade data and download all historical trade submissions.</li>
<li><strong>Organization’s aggregated data</strong>: Access to personalized graphs and tables containing aggregated data submitted by users.</li>
</ul>
<p><strong>Supporters </strong>are partners of Ecosystem Marketplace who help make the work and mission of EM possible, specifically by supporting key products like EM’s State of the VCM report and the Global Carbon Markets Hub Supporters.</p>
<p><strong>How do I invite additional users from my organization to set up a Hub account?</strong></p>
<ul>
<li>Once logged in, click on the top right button where the name of your organization is, then click on “Manage Users” option.</li>
<li>This will open a new page, where you’ll click on the “Invite” button and enter the email of the person you wish to give access to the Hub.</li>
<li>An invite link will be sent by which they can set up their Hub account.</li>
</ul>
{/* <p><em>W</em><em>atch the “How to give access to other users” video in the Help Center on the “Resources” tab for more details.</em></p> */}
<p><strong>Who can access my organization’s trade information?</strong></p>
<ul>
<li>Only registered users within the Respondent organizations can see the confidential trade data contained in the Respondent Data tab of the Hub. (see EM Confidentiality Policy below)</li>
<li>Respondent Data tab:
<ul>
<li>When users select “My data” in the dropdown menu in the navigation bar, they’ll see a summary of their organization’s trade and project activity on the Hub, including historic volume, number of trades, projects from where the transactions come from, as well as some graphs with aggregated data.</li>
<li>When users select “Trades” in the dropdown menu in the navigation bar, users can see all submitted transactions details arranged chronologically.</li>
</ul>
</li>
</ul>
<p><h3 className="help-center-header">CONFIDENTIALITY: </h3></p>
<p><strong>How do I know the trade data submitted to the Hub is protected? What is EM’s Confidentiality Policy?</strong></p>
<ul>
<li>EM protects Respondent trade data by only publishing aggregated data and following our “rule of three” policy, which is EM’s confidentiality policy that requires responses from at least three different respondents submitting information on a specific data point for EM to share that aggregated information.
<ul>
<li>If there isn’t at least three respondents for a given datapoint, EM does not publish aggregated data for that datapoint.</li>
<li>In practice, this means that EM may be able to share information at the broader project category level (e.g. Forestry and Land Use) but not every project type or cluster of project type within those categories (e.g. Assisted Natural Regeneration and Improved Land Management).</li>
</ul>
</li>
</ul>
<p><h3 className="help-center-header">SUBMITTING &amp; EDITING DATA</h3></p>
<p><strong>How do I submit trade data to the Hub?</strong></p>
<p>There are two ways for registered users to submit transactions to the Hub:</p>
<ul>
<li>Option 1: using the “Submit New Trade” button on the “Trades” tab, respondents can submit one transaction at a time using the “New Trade” table.</li>
<li>Option 2: using the “Upload a file” button allows respondents to upload transactions in bulk using the spreadsheet template downloadable using the “2024 EM Global Carbon Markets Trade Report Template” button, which will download the template file to your computer so you can fill it out.
<ul>
<li>This spreadsheet contains the same metrics as the “New Trade” table. Once you add the Project ID, the rest of the Project info columns (methodology, country, etc.) do not need to be filled out. However, If Project ID info is not available, please fill out as much project-related information as possible.</li>
<li>Most of the columns have a dropdown menu that users can choose from; for metrics where options are not available, please be careful to follow the format/instructions described at the top of each column to enter the data.</li>
<li>Once all trades have been entered, save the file to your computer and click the “Drop files here” or “click to upload” button to upload the file to the Hub.</li>
<li>The Hub system will match the columns from the file and check that the transactions contained in the file match the format required. Depending on the number of trades, the uploading process will take between 2 and 3 mins.</li>
</ul>
</li>
</ul>
{/* <p><em>For more detailed information, view the “How to submit trades” video in the Help Center in the “Resources” tab.</em></p> */}
<p><strong>When submitting data, do I have to fill out all the metrics included in the survey table and the spreadsheet?</strong></p>
<ul>
<li>Although EM appreciates and encourages as much granularity as possible in the data submission, not all organizations can or are willing to share the same level of detail. Project ID, volume, price, trade date and buyer information are the most important metrics to include in your data submission.</li>
</ul>
<p>&nbsp;</p>
<p><strong>Why do I see data highlighted in red while trying to upload data via the template spreadsheet?</strong></p>
<p>There may be several reasons for this to happen. If you continue see your data highlighted in red after trying the options below, please get in touch with the EM team via hub@ecosystemmarketplace.com or submit a ticket via the “Get in Touch” button at the top of this page or the bottom of the Hub homepage.</p>
<ul>
<li>In columns with a dropdown option, entering text that is not part of the original options will prevent data from being uploaded. The system will highlight such issues in red.</li>
<li>Columns where users need to enter data such as “volume, price, date”, have a distinctive format described on the top of each column. Not complying with the standard format will not allow data to be imported as entered and will be highlighted in red.</li>
<li>What to do:
<ul>
<li>Depending on the number of “errors” found, users can modify format and/or select available options from the dropdown without closing the online version of the template spreadsheet on the Hub. Once all the highlighted data is resolved, try to upload the data again.</li>
<li>If the number of errors is too big, users can take note of the issue(s), close the upload screen and work on the original template spreadsheet making the changes required by the system to upload. Once the changes are done, upload the file again. If the issues persist, please contact the EM team to help with the issue.</li>
</ul>
</li>
</ul>
{/* <p><em>For more information, please watch the “How to submit trades” demo videos in the Help Center on the “Resources” tab.</em></p> */}
<p><strong>I can’t find the project information for my trade when using the “Submit New Trade” button to upload a trade, what can I do?</strong></p>
<ul>
<li>Try to find the trade’s project by ID or name. If you can’t find it, that specific project is not in EM’s database.</li>
<li>To add the project, please go to the “All Projects” tab and click the “Create Project” button, located on the right side of the screen. A small table will pop up, where you can enter the new project information, such as ID, country, methodology, etc.</li>
<li>The EM team will review the new project information to make sure it’s correct according to the standard before approving it.</li>
</ul>
{/* <p><em>Please watch the “How to add a new project” video on the Help Center on the “Resources” tab for more details.</em></p> */}
<p><strong>What if the Project ID for a transaction is missing or still not available?</strong></p>
<ul>
<li>Respondents can enter data without Project ID by choosing the “NO ID” option using the “Submit New Trade” button. Once that option is chosen, an additional new table where you can enter additional relevant information about the project will appear.</li>
<li>If at some point the Project ID information becomes available, respondents can always come back to the table and add that information to those specific transactions.</li>
</ul>
{/* <p><em>For more information, please watch the “How to Submit trades” video on the Help Center on the “Resources” tab.</em></p> */}
<p><strong>What if I want to add or edit Buyer information?</strong></p>
<p>There are three ways to add new buyers to your organization’s buyers catalog.</p>
<ul>
<li>Using the “New Trade” table, use the “Create Buyer” button where you can add information for a buyer that’s not included in your list.</li>
<li>Go to Buyers in the dropdown on the “Respondent Data” tab and click on the “Create Buyer” button and do the same as above.</li>
<li>If you’re using the template spreadsheet, you can include buyer’s information in columns AD-AK.</li>
</ul>
{/* <p><em>For more information, please watch the “How to Add new buyers” video on the “Resources” tab.</em></p> */}
<p><strong>What if I can’t disclose buyer information due to my organization’s confidentiality policies, such as an NDA? </strong></p>
<ul>
<li>Although EM never discloses confidential information from our Respondents, such as buyer names, due to our own confidentiality policy, we understand this might be information that some respondents cannot disclose.</li>
<li>If you are not disclosing buyer name, you can create an alias or ID for every buyer as long you know which buyer is associated with that ID or alias and include other information such as sector, country, etc.</li>
</ul>
{/* <p><em>For more information, please watch the “How to Submit trades” video on the Help Center on the “Resources” tab.</em></p> */}
<p><strong>Can respondents edit or add information for buyers already in the Hub system?</strong></p>
<p>Yes, click on the pencil button next to the buyer’s name and use the pop up table to add or edit or even delete information for that buyer.</p>
<p><strong>If I have questions while navigating the Hub or submitting data that is not covered in the Resources tab, how can I get additional support?</strong></p>
<p>On the Hub Homepage or Home tab, navigate to the bottom of the page and click on the “Get in Touch” button or email hub@ecosystemmarketplace.com. The EM team will get in touch right away to offer support.</p>
<p><h3 className="help-center-header">NAVIGATING ADDITIONAL HUB FEATURES</h3></p>
<p><strong>Do new respondents have access to the Pricing Data tab in the Hub as soon as their account is created?</strong></p>
<p>Data Pricing will be available once the respondent has submitted trades through their Hub account.</p>
<p><strong>Where can Respondents find Pricing data on the Hub?</strong></p>
<p>Registered users will find the most updated pricing data under the Home, Categories, Taxonomy and Standard tabs:</p>
<ul>
<li>Home: Total volume and Price graphs and a volume by location.</li>
<li>Categories: Volume and Price by category and year.</li>
<li>Taxonomy: Price and volume by cluster</li>
<li>Standard: Total volume by standard and project category volumes by standard.</li>
</ul>
<p><strong>Can Respondents download data from the Hub?</strong></p>
<p>Yes, Respondents can download their organization’s trade data by using the “Download your trades” button on the “Trades” section of the “Respondent Data” tab. Click the button once to get the file ready and click it again to download it.</p>
{/* <p><em>Please watch the “How to download trades” video on the Help Center on the “Resources” tab for more details.</em></p> */}
<p><strong>Can users delete or edit trades once they’ve been submitted?</strong></p>
<ul>
<li>Yes, all trades can be edited and/or add new information at any time using the “pencil” icon on the right side of the trade line on the Trade tab. Once changes have been entered, click on the “Update trade” button at the bottom of the table.</li>
<li>Within the first two weeks after submitting any given trade, deleting a trade can be accomplished without any extra steps. Click the “trash” icon button next to the trade, confirm the deletion, and the trade will no longer be in your inventory.</li>
<li>However, if you are trying to delete a trade you submitted two or more weeks requires more detail to explain the deletion. Once you click the “trash” icon button, the Hub will request an explanation as to why that trade is being deleted. This is for the EM team to have a record of deleted trades in the system.</li>
</ul>
<p><h3 className="help-center-header">USING EM DATA</h3></p>
<p><strong>Can I cite EM’s data on the Hub in content that my organization generates?</strong></p>
<p>Your organization can only cite data that is accessible on the public version of the Hub, which includes data from the following pages.</p>

<ul className="checkmark-ul">
<li>Market Overview data on the Home tab</li>
<li>Category Data
<ul>
<li>Volume by Category and Year, not including current calendar year</li>
<li>Price by Category and Year, not including current calendar year</li>
</ul>
</li>
<li>Details from Project pages</li>
</ul>
<p>EM requires that you attribute the data used to the Global Carbon Markets Hub. Please use the following citation:</p>
<p>Forest Trends’ Ecosystem Marketplace. 2024. <em>[Title of page].</em> Global Carbon Markets Hub. Retrieved [date], from [URL].</p>
<p>If you have any other questions about how to use data from the Global Carbon Markets Hub or how to cite the Hub in your research, please reach out to the EM team at hub@ecosystemmarketplace.com.</p>


                    
                    </>
                )}
                    
                </CardBody>
                
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default HelpCenterPublic;
