import React, {useState} from 'react';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend
//import fakeBackend from './helpers/AuthType/fakeBackend';
import { ToastContainer } from 'react-toastify';
import { Switch } from 'react-router-dom';

// Activating fake backend
//fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

function App() {
  const testPassword = () => {
    if (document.getElementById('psw').value === '#T0ughp@$$w0rd!') { 
      setAccessible(true);
    }
  }
  const [accessible, setAccessible] = useState(false);

  return (
    <React.Fragment>

      <Switch>
        {accessible && <Route />}
        {! accessible && <div style={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%,-50%'}}>Password: <input id="psw" type="password" onChange={testPassword} /></div>}
      </Switch>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;
