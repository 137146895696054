import React from 'react';
import { Redirect } from 'react-router-dom';


import Landing from '../pages/Landing';
import CategoriesPublic from '../pages/Public/Categories';
import Taxonomy from '../pages/Public/Taxonomy';
import Standards from '../pages/Public/Standards';
import Projects from '../pages/Public/Projects';
import TermsOfUse from '../pages/Public/TermsOfUse';
import DemosAndFAQsPublic from '../pages/Public/DemosAndFAQs/';
import AboutUs from '../pages/Public/AboutUs/';
import OurSupportersPublic from '../pages/Public/OurSupporters/';
import ListOfRespondentsPublic from '../pages/Public/ListOfRespondents/';
import SupportersOnly from '../pages/Public/SupportersOnly/';

import TaxonomyComponent from '../pages/Public/Taxonomy/Taxonomy-Public';
import StandardsComponent from '../pages/Public/Standards/Standards-Public';
import TermsOfUseComponent from '../pages/Public/TermsOfUse/Terms-Public';
import DemosAndFAQsComponent from '../pages/Public/DemosAndFAQs/DemosAndFAQs-Public';
import AboutUsComponent from '../pages/Public/AboutUs/AboutUs-Public';

import OurSupportersComponent from '../pages/Public/OurSupporters/OurSupporters-Public';
import ListOfRespondentsComponent from '../pages/Public/ListOfRespondents/ListOfRespondents-Public';

import RespondentBuyersPublic from '../pages/Public/RespondentBuyers'; 
import RespondentMyDataPublic from '../pages/Public/RespondentMyData';
import RespondentTradesPublic from '../pages/Public/RespondentTrades';

//login
import Login from '../pages/Authentication/Login';
import Requestaccess from '../pages/Authentication/Requestaccess';
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import ResetPassword from '../pages/Authentication/RestPassword';

// User Profile
import UserProfile from '../pages/Authentication/user-profile';
import InvitationFrom from '../pages/Authentication/invitationFrom';
import AccountConfirm from '../pages/Authentication/AccountConfirm';

// Trade app
import Dashboard from '../pages/Dashboard';
import Categories from '../pages/Private/Categories';
import MyData from '../pages/Private/MyData';
import TradeList from '../pages/Trade/TradeList';
import BuyerList from '../pages/Buyer/BuyerList';
import ProjectList from '../pages/Project/ProjectList';
//import RespondentList from '../pages/Respondents/Respondent';
import RespondentEmployee from '../pages/RespondentEmployee/RespondentEmployee';
//import ProjectListImport from '../pages/Project/ProjectListImport';
import Utilities from '../pages/Utilities/Utilities';

import NewCharts from '../pages/Charts/NewCharts';


// Analysis
//import MarketData from '../pages/MarketData';
import DataProjects from '../pages/MarketData/Projects';
import DataDashboard from '../pages/MarketData/PlatinumReportPowerBI';
//import DataPricing from '../pages/MarketData/Pricing';
//import DataTransparency from '../pages/MarketData/Transparency';
import DataCorsia from '../pages/MarketData/Corsia';
import DataStandards from '../pages/MarketData/Standards';
import DataCategories from '../pages/MarketData/Categories';
//import DataRespondents from '../pages/MarketData/Respondents';
import DataBuyers from '../pages/MarketData/Buyers'; 

// Paths
import {
  PATH_AUTH,
  PATH_TRADE,
  PATH_BUYER,
  PATH_PROJECT,
  PATH_RESPONDENT,
} from './paths';
import PageNotFound from '../pages/pageNotFound/PageNotFound';
import ProjectDetail from '../pages/Project/ProjectDetail';
import ProjectDetailPublic from '../pages/Public/Projects/ProjectDetail-Public';
//import MyRespondent from '../pages/Respondents/MyRespondent';
//import ProjectUpdate from '../pages/Project/ProjectUpdate';
//import EditTrade from '../pages/Trade/components/EditTrade';

const authProtectedRoutes = [
  //User Profile
  { path: '/profile', component: UserProfile },
  { path: '/employees/:respondent_id', component: RespondentEmployee },
  // Trade app
  { path: '/dashboard/', component: Dashboard },
  { path: '/categories-resp/', component: Categories },
  { path: '/my-data/', component: MyData },
  { path: PATH_TRADE.list, component: TradeList },
  // { path: '/trade/edit/:tradeId', component: EditTrade },
  { path: PATH_BUYER.list, component: BuyerList },
  { path: PATH_PROJECT.list, component: ProjectList },
  //{ path: PATH_PROJECT.import, component: ProjectListImport },
  //{ path: PATH_RESPONDENT.list, component: RespondentList },
  
  //{ path: '/project/edit/:product_id', component: ProjectUpdate },
  { path: '/utilities', component: Utilities },
  { path: '/new-charts', component: NewCharts },
  { path: '/em-taxonomy', component: Taxonomy },
  { path: '/project/:product_id', component: ProjectDetail },

  //{ path: '/respondent/my-own', component: MyRespondent },
  { path: '/landing/', component: Landing },
  { path: '/page-not-found', component: PageNotFound },

  { path: '/taxonomy/', component: TaxonomyComponent },
  { path: '/standards/', component: StandardsComponent },
  { path: '/Resources/TermsAndPrivacy', component: TermsOfUseComponent },
  { path: '/Resources/DemosAndFAQs', component: DemosAndFAQsComponent },
  { path: '/About/AboutUs', component: AboutUsComponent },
  
  { path: '/About/OurSupporters', component: OurSupportersComponent },
  { path: '/About/ListOfRespondents', component: ListOfRespondentsComponent },

  // Ed-Pages
  
  // { path: '/ed-pages/starter', component: Starter },
  // { path: '/ed-pages/trades', component: Trades },
  // { path: '/ed-pages/projects', component: Projects },
  // { path: '/ed-pages/project-add', component: ProjectsAdd },
  
  // { path: '/ed-pages/about', component: About },
  // { path: '/ed-pages/join', component: Join },
  // { path: '/ed-pages/docs', component: Docs },
  // { path: '/ed-pages/buyer', component: Buyer },
  // { path: '/ed-pages/respondent', component: RespondentList },
  

  


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/dashboard' />,
  },
  // {
  //   path: '*',
  //   component: () => <Redirect to='/page-not-found' />,
  // },
];

const marketDataRoutes = [
  // Market Data
  //{ path: '/marketdata/', component: MarketData },
  { path: '/data/projects/', component: DataProjects },
  { path: '/data/categories/', component: DataCategories },
  //{ path: '/data/transparency/', component: DataTransparency },
  { path: '/data/corsia/', component: DataCorsia },
  { path: '/data/standards/', component: DataStandards },
  //{ path: '/data/respondents/', component: DataRespondents },
  { path: '/data/buyers/', component: DataBuyers },
  { path: '/data/data-dashboard/', component: DataDashboard },
];

let publicRoutes = [
  // Authentication Page
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/landing' />,
  },
  { path: '/landing/', component: Landing },
  { path: '/categories/', component: CategoriesPublic },
  { path: '/taxonomy/', component: Taxonomy },
  { path: '/standards/', component: Standards },
  { path: '/projects/', component: Projects },
  { path: '/projectDetail/:product_id', component: ProjectDetailPublic },
  { path: '/SupportersOnly', component: SupportersOnly },
  { path: '/Resources/TermsAndPrivacy', component: TermsOfUse },
  { path: '/Resources/DemosAndFAQs', component: DemosAndFAQsPublic },
  { path: '/About/AboutUs', component: AboutUs },
  { path: '/About/OurSupporters', component: OurSupportersPublic },
  { path: '/About/ListOfRespondents', component: ListOfRespondentsPublic },
  { path: '/RespondentData-Buyers', component: RespondentBuyersPublic },
  { path: '/RespondentData-YourData', component: RespondentMyDataPublic },
  { path: '/RespondentData-Trades', component: RespondentTradesPublic },
  
  // CHAD AUTH
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/requestaccess', component: Requestaccess },
  { path: '/forgot-password', component: ForgetPasswordPage },
  { path: '/register', component: Register },
  { path: '/reset-password/:token', component: ResetPassword },
  { path: '/page-not-found', component: PageNotFound },
  { path: '/respondent/invite/:token', component: InvitationFrom },
  { path: PATH_AUTH.accountConfirm, component: AccountConfirm },
];



export { authProtectedRoutes, marketDataRoutes, publicRoutes };
