import React from 'react';
import Navbar from '../../Landing/navbar';
import Footer from '../../Landing/footer';
import { useSelector } from 'react-redux';
import Spinner from '../../../Components/Common/Spinner';


import Sidebar from '../../../Layouts/Sidebar';
import MyData from './MyData';




const Index = (props) => {
  const loading = useSelector((state) => state?.Login?.loading);


  const {
      layoutType,
  } = useSelector(state => ({
      layoutType: state.Layout.layoutType,
  }));

  
  document.title = 'My Data | Ecosystem Marketplace';

  if (loading) return <Spinner />;

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" data-layout="horizontal">
        <Navbar />
        <Sidebar layoutType={layoutType} />

        <MyData />

        <Footer />
      </div>
      
    </React.Fragment>
  );
};

export default Index;
