import React, { useEffect, useState } from 'react';
import Navbar from './navbar';
import Home from './home';
import Client from './client';
import Cta from './cta';
import Footer from './footer';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../../Components/Common/Spinner';
import { useLocation, withRouter } from 'react-router-dom';
import {
  Alert,
  Container,
  CardHeader,
  Row,
  Col,
  Card,
  CardBody, 
} from 'reactstrap'; 
import TotalVolumeBarChartPublic from '../Charts/components/TotalVolume-BarChart-Public';
import VWAPLineChartPublic from '../Charts/components/VWAP-Line-Chart-Public';
import VolumeByCountryMapPublic from '../Charts/components/VolumeByCountry-Map-Public';

import Sidebar from '../../Layouts/Sidebar';



//import actions
import {
    changeLayoutMode,
} from "../../store/actions";

import ReactGA from 'react-ga4';
ReactGA.initialize('G-P7N889SGDD');
ReactGA.send({ hitType: "pageview", page: "/landing", title: "Public Home Page" });



const Index = (props) => {
  const loading = useSelector((state) => state?.Login?.loading);

  let location = useLocation();
    const [headerClass, setHeaderClass] = useState("");
    const dispatch = useDispatch();
    const {
        layoutType,
        layoutModeType,
        
    } = useSelector(state => ({
        layoutType: state.Layout.layoutType,
        layoutModeType: state.Layout.layoutModeType,
    }));

    const onChangeLayoutMode = (value) => {
      if (changeLayoutMode) {
          dispatch(changeLayoutMode(value));
      }
  };

  document.title = ' Voluntary Carbon Market Data | Ecosystem Marketplace ';

  if (loading) return <Spinner />;

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" data-layout="horizontal">
        <Navbar />
        {!location?.pathname?.includes('not-found') && (
          <>
              <Sidebar layoutType={layoutType} />
          </>
        )}
        
        {/* <Home /> */}
        
        
        <div data-layout='horizontal'>
          <Container fluid={true}>
            <div className="page-content">
          <Row>
            <Col xl={12}>
              <Card className='bg-primary'>
                <CardBody className='p-0'>
                  <Row className='align-items-end'>
                    <Col sm={10} className=''>
                      <div className='p-3 ps-4'>
                        <h1 className='display-6 fw-bold text-white'>
                        Global Carbon Markets Hub
                        </h1>
                        <h2 className="text-white">By Ecosystem Marketplace</h2>
                        <p className='fs-16 lh-base text-white'>
                        EM's Carbon Markets Hub is the largest publicly available database of carbon market transactions providing graphics, key metrics, and information on the VCM to the public. Ecosystem Marketplace has earned the trust of carbon market stakeholders by tracking this data for almost 20 years - part of our commitment to creating a more transparent and accessible carbon market for those who want to enhance their understanding of carbon markets through data insights.
                        
                        </p>

                        {/* <Alert color="danger">
                        
                        <p className="fs-16 lh-base">
                          
                          Hover over the interactive graphs below to explore Ecosystem Marketplace's carbon credit volume and price data.
                        </p>
                        
                        </Alert> */}
                      </div>
                    </Col> 
                  </Row>
                 
                </CardBody>
                
              </Card>
              
              
            <Card style={{boxShadow: 'none'}}>
                <CardHeader className="align-items-center d-flex" style={{borderBottom: 'none'}}><h2 className="mb-10 flex-grow-1">Market Overview</h2></CardHeader>
                

                <CardBody className=""  style={{maxWidth:'1385px', paddingTop: '0'}}>
                
                Hover over the interactive graphs below to explore Ecosystem Marketplace's carbon credit volume and price data.
                </CardBody>
                        
                  
                    
                  
                        
                
              </Card>
              
            </Col>
          </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody className="p-0">
                    <TotalVolumeBarChartPublic />
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <CardBody className="p-0">
                    <VWAPLineChartPublic />
                </CardBody>
              </Col>
            </Row>
            <Row>
              <Card>
                <CardBody className="p-0">
                    <VolumeByCountryMapPublic />
                </CardBody>
              </Card>
            </Row>
            </div>
          </Container>
        </div>
        {/* <Client /> */}
        <Cta />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Index;
