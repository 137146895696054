import React, { useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from 'reactstrap';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { withRouter, Link } from 'react-router-dom';

// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// actions
import {
  loginUser,
  resetLoginFlag,
  resetLoginFlagError,
} from '../../store/actions';

//import images
import logoLight from '../../assets/images/em_logo.png';
import FeatherIcon from 'feather-icons-react';

// import paths
import { PATH_AUTH } from '../../Routes/paths';
import { useCookies } from 'react-cookie';

const Login = (props) => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => ({
    user: state.Account.user,
    loading: state.Login.loading,
  }));

  const [showPassword, setShowPassword] = useState(false);

  const [userLogin, setUserLogin] = useState([]);
  const [cookies, setCookies] = useCookies(['user']);
  const [checkedValue, setCheckedValue] = useState(
    cookies.checked == 'true' ? true : false
  );
  const firstCall = '';
  useEffect(() => {
    if (user && user) {
      setUserLogin({
        email: user.user.email,
        password: user.user.confirm_password,
      });
    }
  }, [user]);

  // CHAD AUTH
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // email: userLogin.email || "" || "",
      email: userLogin.email
        ? userLogin.email
        : cookies.email
        ? cookies.email
        : '',
      password: userLogin.password
        ? userLogin.password
        : cookies.password
        ? cookies.password
        : '',
      // password: userLogin.password || "" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
      localStorage.setItem('userLogout', false);
      if (checkedValue) {
        // behave the same as if not checked value :)
      }
    },
  });

  const { error } = useSelector((state) => ({
    error: state.Login.error,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetLoginFlag());
    }, 5000);
  }, [dispatch, error]);

  useEffect(() => {
    dispatch(resetLoginFlagError());
  }, []);

  const getPasswordCookie = () => {
    if (cookies.password) {
      return cookies.password;
    }
    if (validation.values.password) {
      return validation.values.password;
    }

    return '';
  };

  const getEmailCookie = () => {
    if (cookies.email) {
      return cookies.email;
    }
    if (validation.values.email) {
      return validation.values.email;
    }
    return '';
  };

  useEffect(() => {
    getEmailCookie();
    getPasswordCookie();
  }, [firstCall]);

  document.title = 'Ecosystem Marketplace — Login';
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container>
            {/* <Row>
              <Col lg={12}>
                <div className='text-center mt-sm-5 mb-4 text-white-50'>
                  <div>
                    <Link to='/' className='d-inline-block auth-logo'>
                      <img src={logoLight} alt='' height='100' />
                    </Link>
                  </div>
                  <p className='mt-3 fs-15 fw-medium'>
                    Data Dashboard. Alpha — version 0.1
                  </p>
                </div>
              </Col>
            </Row> */}

            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='mt-4'>
                  <CardBody className='p-4'>

                    <div className='text-center mb-5'> 
                      
                        <img src={logoLight} alt='' height='100' />
                      
                    </div>

                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>Welcome Back!</h5>
                      <p className='text-muted'>
                        Sign in to continue to Ecosystem Marketplace
                      </p>
                    </div>
                    {error && error ? (
                      <Alert color='danger'> {error} </Alert>
                    ) : null}
                    <div className='p-2 mt-4'>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action='#'>
                        <div className='mb-3'>
                          <Label htmlFor='email' className='form-label'>
                            Email
                          </Label>
                          <Input
                            name='email'
                            className='form-control'
                            placeholder='Enter email'
                            type='email'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            // value={validation.values.email || cookies.email || ""}
                            value={
                              validation.values.email
                                ? validation.values.email
                                : cookies.email
                                ? cookies.email
                                : ''
                            }
                            // value={getEmailCookie}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          <div className='float-end'>
                            <Link
                              to={PATH_AUTH.forgotPassword}
                              className='text-muted'>
                              Forgot password?
                            </Link>
                          </div>
                          <Label
                            className='form-label'
                            htmlFor='password-input'>
                            Password
                          </Label>
                          <div className='position-relative auth-pass-inputgroup mb-3'>
                            <Input
                              name='password'
                              // value={validation.values.password || cookies.password || ""}
                              value={
                                validation.values.password
                                  ? validation.values.password
                                  : cookies.password
                                  ? cookies.password
                                  : ''
                              }
                              // value={getPasswordCookie}
                              // type='password'
                              type={showPassword ? 'text' : 'password'}
                              className='form-control pe-5'
                              placeholder='Enter Password'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
 
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                              type='button'
                              id='password-addon'

                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}>
                              {!showPassword ? (
                                <FeatherIcon icon='eye-off' size={14} />
                              ) : (
                                <FeatherIcon icon='eye' size={14} />
                              )}
                            </button>
                          </div>
                        </div>

                        <div className='form-check'>
                          <Input
                            className='form-check-input'
                            type='checkbox'
                            // value={checkValue}
                            checked={checkedValue}
                            onChange={(e) =>
                              setCheckedValue(
                                e.target.checked == true ? true : false
                              )
                            }
                            id='auth-remember-check'
                          />
                          <Label
                            className='form-check-label'
                            htmlFor='auth-remember-check'>
                            Remember me
                          </Label>
                        </div>

                        <div className='mt-4'>
                          <Button
                            color='success'
                            className='btn btn-success w-100'
                            type='submit'
                            disabled={loading}>
                            Sign In {loading && <Spinner size={'sm'} />}
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className='mt-4 text-center'>
                  <p className='mb-0'>
                    Don't have an account ?{' '}
                    <Link
                      to={PATH_AUTH.register}
                      className='fw-semibold text-primary text-decoration-underline'>
                      {' '}
                      Signup{' '}
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
