import React, { useEffect, useState } from 'react';
import { URL } from '../../../constants';
import axios from 'axios';
import { Card, CardHeader, Col, Row } from 'reactstrap'; 
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const StandardsVolumeByCategory = () => {

    const [dataReady, setDataReady] = useState(false);
    const [standards, setStandards] = useState([]);
    const [categories, setCategories] = useState([]);
    const [years, setYears] = useState([]);
    const [yearMin, setYearMin] = useState(0);
    const [yearMax, setYearMax] = useState(0);
    const [standardsData, setStandardsData] = useState([]);
    const [selectedStandards, setSelectedStandards] = useState([]);

    
    const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

    const fixed_categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
        'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other'];

    



    let initialBarChartOptions = {
        colors: colors,
        chart: {
            type: 'column',
            height: '550',
            marginBottom: '170',
            marginTop: '70',
        },
        title: {
            text: '',
            align: 'left'
        },
        subtitle: {
            text: 'Click on categories to show/hide them',
            align: 'left'
        },
        
        yAxis: {
            min: 0,
            title: {
                text: 'Volume (tCO2e)'
            },
            stackLabels: {
                enabled: true
            }
        },
        legend: {
            align: 'right',
            verticalAlign: 'top',
            floating: false,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,

        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal:,.0f}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                },
            }
        },
        series: [],
        }

    const [stackedBarChartOptions, setStackedBarChartOptions] = useState(initialBarChartOptions);

    const updateChart = (initialLoad = false, yearRangeUpdated = false) => {

        if( initialLoad ){

            axios.get(URL.get_resp_standard_categories).then((response) => {
                
                let respStandardCategories = response.data;
                setStandardsData(respStandardCategories);

                let standards = [];
                let years = [];
                respStandardCategories.forEach((d) => {

                    if (!standards.includes(d.project_standard) ) {
                        standards.push(d.project_standard);
                    }

                    if (!years.includes(d.year_transact)) {
                        years.push(d.year_transact);
                    }

                });

                standards.sort();
                years.sort();
                setYearMin(years[0]);
                setYearMax(years[years.length - 1]);
                setCategories(fixed_categories);
                setYears(years);
                
                let volume_by_standard_and_category = {};
                let standardsTotals = {};

                standards.forEach((standard) => {
                    standardsTotals[standard] = 0;
                    volume_by_standard_and_category[standard] = {};
                    categories.forEach((category) => {
                        volume_by_standard_and_category[standard][category] = 0;
                    });
                    respStandardCategories.forEach((d) => {
                        if (d.project_standard === standard) {
                            volume_by_standard_and_category[standard][d.project_category] += d.volume_tco2e;
                            standardsTotals[standard] += d.volume_tco2e;
                        }
                    });
                });

                // get the eight standards with the highest total volume
                let sortedStandards = standards.sort((a, b) => standardsTotals[b] - standardsTotals[a]);
                let topStandards = sortedStandards.slice(0, 8);

                setStandards(topStandards);
                setSelectedStandards(topStandards);

                let volumeBarChartData = [];

                categories.forEach((category) => {
                    let volume_obj = {name: category, data: []};
                    standards.forEach((standard) => {
                        volume_obj.data.push(volume_by_standard_and_category[standard][category]);
                    });
                    volumeBarChartData.push(volume_obj);
                });

                
                let xAxisObj = {categories: standards, labels: {
                    style: { fontSize: '12px'} 
                }};

                
                setStackedBarChartOptions({ ...stackedBarChartOptions, series: volumeBarChartData, xAxis: xAxisObj});
                setDataReady(true);
        
            });
        } else {

            let volume_by_standard_and_category = {};
            let standardsTotals = {};

            if( yearRangeUpdated ){
                let standards = [];
                standardsData.forEach((d) => {

                    if (!standards.includes(d.project_standard) ) {
                        standards.push(d.project_standard);
                    }

                });

                standards.forEach((standard) => {
                    standardsTotals[standard] = 0;
                    volume_by_standard_and_category[standard] = {};
                    categories.forEach((category) => {
                        volume_by_standard_and_category[standard][category] = 0;
                    });
                    standardsData.forEach((d) => {

                        if (d.project_standard === standard && d.year_transact >= yearMin && d.year_transact <= yearMax) {
                            volume_by_standard_and_category[standard][d.project_category] += d.volume_tco2e;
                            standardsTotals[standard] += d.volume_tco2e;
                        }
                    });
                });

                let sortedStandards = standards.sort((a, b) => standardsTotals[b] - standardsTotals[a]);
                let topStandards = sortedStandards.slice(0, 8);

                setStandards(topStandards);
                setSelectedStandards(topStandards);
                

            } else {

                selectedStandards.forEach((standard) => {
                    standardsTotals[standard] = 0;
                    volume_by_standard_and_category[standard] = {};
                    categories.forEach((category) => {
                        volume_by_standard_and_category[standard][category] = 0;
                    });
                    standardsData.forEach((d) => {

                        if (d.project_standard === standard && d.year_transact >= yearMin && d.year_transact <= yearMax) {
                            volume_by_standard_and_category[standard][d.project_category] += d.volume_tco2e;
                            standardsTotals[standard] += d.volume_tco2e;
                        }
                    });
                });

            }


            let volumeBarChartData = [];
            
            categories.forEach((category) => {
                let volume_obj = {name: category, data: []};
                selectedStandards.forEach((standard) => {
                    volume_obj.data.push(volume_by_standard_and_category[standard][category]);
                });
                volumeBarChartData.push(volume_obj);
            });

            
            let xAxisObj = {categories: selectedStandards, labels: {
                style: { fontSize: '12px'} 
            }};

            
            setStackedBarChartOptions({ ...stackedBarChartOptions, series: volumeBarChartData, xAxis: xAxisObj});
        }
    };

    useEffect(() => {
        updateChart(true);
    }, []);

    useEffect(() => {
        updateChart(false);
    }, [selectedStandards]);

    useEffect(() => {
        updateChart(false, true);
    }, [yearMin, yearMax]);




    return (
        <React.Fragment>
            
            <style dangerouslySetInnerHTML={{__html: `
            .standardsCheckboxes label { font-size: 12px; text-transform:none; top:-5px; position:relative; margin-bottom:0; }
            .standardsCheckboxes input[type="checkbox"] { appearance:none; -webkit-appearance: none; background-color: #375f47;	border: 1px solid #cacece;
	            box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05); padding: 7px; border-radius: 3px; display: inline-block;
	            position: relative; margin-right:4px; }

            .standardsCheckboxes input[type="checkbox"]:checked:after { content: '\\2714'; font-size: 10px; color: #fff; position: absolute; top: 0px; left: 3px; }
            .dateRangeSelectors select { margin:0 25px 0 5px;}
            
            `}} />
            <Row>
            <Col className="col-12 col-lg-12"><CardHeader><strong style={{fontSize : "1.4em"}}>Volume (tCO2e) by Standard and Category</strong><br />Only the eight standards with the highest volumes for the selected date range are shown.  Uncheck standards to hide them.</CardHeader></Col>
            </Row>
            { /* insert years dropdown selector here */ }
            <CardHeader>
                <Row>
                    <Col className="col-12 col-lg-12 dateRangeSelectors">
                        <strong>Date Range: <label style={{ textTransform:"none", marginLeft:"20px", fontWeight: "bold"}}>Year Min</label> </strong>
                        
                        <select id="yearMin" name="yearMin" onChange={(e) => {
                            let selectedYear = e.target.value;
                            console.log('selectedYear', selectedYear);
                            setYearMin(selectedYear);
                        }}>
                            { 
                            years.map((year, index) => {
                                if( year <= yearMax ){
                                    return <option key={index} value={year} selected={year == yearMin}>{year}</option>
                                }
                            })}
                            
                            
                        </select>

                        <strong>Year Max </strong>
                        
                        <select id="yearMax" name="yearMax" onChange={(e) => {
                            let selectedYear = e.target.value;
                            console.log('selectedYear', selectedYear);
                            setYearMax(selectedYear);
                        }}>
                            { years.reverse().map((year, index) => {
                                if( year >= yearMin ){
                                    return <option key={index} value={year} selected={year == yearMax}>{year}</option>
                                }
                            })}
                            {years.reverse()}
                            
                        </select>
                          
                        { (yearMin != years[0] || yearMax != years[years.length - 1]) && <><input type="checkbox" id="resetYearRange" name="resetYearRange" value="resetYearRange" onChange={(e) => {
                            if( e.target.checked ){
                                setYearMin(years[0]);
                                setYearMax(years[years.length - 1]);
                                e.target.checked = false;
                            }
                        }} /> Reset Date Range</> }
                    </Col>
                </Row>    

            </CardHeader>

            { /* insert standards checkboxes here  */ }
            <CardHeader><Row className="standardsCheckboxes">
                {standards.map((standard, index) => {
                    return (
                        <Col key={index} className="col-3 col-lg-3">
                            <input type="checkbox" id={standard} name={standard} value={standard} checked={selectedStandards.includes(standard)} onChange={(e) => {
                                let checked = e.target.checked;
                                if (checked) {
                                    setSelectedStandards([...selectedStandards, standard]);
                                } else {
                                    setSelectedStandards(selectedStandards.filter((s) => s.indexOf(standard) === -1));
                                }
                            }} />
                            <label htmlFor={standard}>{standard}</label>
                        </Col>
                    );
                })}
                
            </Row></CardHeader>

            <Row>
                <Col className="col-12 col-lg-12"><div>
                {dataReady && <HighchartsReact
                    highcharts={Highcharts}
                    options={stackedBarChartOptions}
                />}</div>
                </Col>
            </Row>

        </React.Fragment>
    );


}

export default StandardsVolumeByCategory;