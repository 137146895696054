import React, {useEffect, useState} from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 
import { URL } from '../../../constants';
import axios from 'axios';
import { useSelector } from 'react-redux';

import StandardsScatterWithJitter from '../../Charts/components/StandardsScatterWithJitter-Private';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-P7N889SGDD');






const TaxonomyPublic = () => {

    const { Login } = useSelector((state) => state); 

    const [categoryData, setCategoryData] = useState([]);
    const [clusterData, setClusterData] = useState([]);
    const [dataReady, setDataReady] = useState(false);
    const [categoriesFiltered, setCategoriesFiltered] = useState([]);
    const [allShown, setAllShown] = useState(false);

    console.log('Login',Login);

    const isLoggedIn = Login?.user?.email ? true : false;
    console.log('isLoggedIn',isLoggedIn);

    const notLoggedIn = !isLoggedIn;


    if( isLoggedIn ){
        ReactGA.send({ hitType: "pageview", page: "/taxonomy", title: "Respondent Taxonomy Page" });
    } else {
        ReactGA.send({ hitType: "pageview", page: "/taxonomy", title: "Public Taxonomy Page" });
    }

    useEffect(() => {
        axios.get(URL.get_hub_categories_clusters).then((response) => {
            setClusterData(response.data);
        });
        axios.get(URL.get_hub_categories).then((response) => {
            setCategoryData(response.data);
        });
    }, []);

    useEffect(() => {
        if (categoryData.length > 0 && clusterData.length > 0) {
            let categoryDataCopy = [...categoryData];
            categoryDataCopy = categoryDataCopy.filter((category) => { 
                let include = false;
                clusterData.forEach((cluster) => {
                    if (cluster.categoryid === category.categoryid && cluster.summary != '') {
                        if( category.categoryid == 3 ){
                            console.log('cluster',cluster);
                        }
                        include = true;
                    }
                });
                return include;
            });
            console.log('categoryDataCopy',categoryDataCopy);
            // sort by categoryid
            categoryDataCopy.sort((a, b) => (a.categoryid > b.categoryid) ? 1 : -1);
            setCategoriesFiltered(categoryDataCopy);
            
        }
    }, [categoryData, clusterData]);

    useEffect(() => {
        setDataReady(true);
    }, [categoriesFiltered]);

    const toggleShowCatSummary = (e) => {
        const target = e.target;
        const summary = target.closest('.categoryContainer').querySelector('.taxCategory__Summary');
        if( ! summary.classList.contains('show') ) {
            summary.classList.add('show');
            target.closest('.taxCategory__name').classList.add('showing');
        } else {
            summary.classList.remove('show');
            target.closest('.taxCategory__name').classList.remove('showing');
        }
    }


    const toggleShowClusterSummary = (e) => {
        const target = e.target;
        const summary = target.closest('.taxCluster').querySelector('.taxCluster__Summary');
        if( ! summary.classList.contains('show') ) {
            summary.classList.add('show');
            target.classList.add('showing');
        } else {
            summary.classList.remove('show');
            target.classList.remove('showing');
        }
    }

    const showAllCatSummaries = () => {
        const summaries = document.querySelectorAll('.taxCategory__Summary');
        summaries.forEach((summary) => {
            summary.classList.add('show');
        });
        const names = document.querySelectorAll('.taxCategory__name');
        names.forEach((name) => {
            name.classList.add('showing');
        });
    }

    const hideAllCatSummaries = () => {
        const summaries = document.querySelectorAll('.taxCategory__Summary');
        summaries.forEach((summary) => {
            summary.classList.remove('show');
        });
        const names = document.querySelectorAll('.taxCategory__name');
        names.forEach((name) => {
            name.classList.remove('showing');
        });
    }

    const showAllClusterSummaries = () => {
        const summaries = document.querySelectorAll('.taxCluster__Summary');
        summaries.forEach((summary) => {
            summary.classList.add('show');
        });
        const names = document.querySelectorAll('.taxCluster__name');
        names.forEach((name) => {
            name.classList.add('showing');
        });
    }

    const hideAllClusterSummaries = () => {
        const summaries = document.querySelectorAll('.taxCluster__Summary');
        summaries.forEach((summary) => {
            summary.classList.remove('show');
        });
        const names = document.querySelectorAll('.taxCluster__name');
        names.forEach((name) => {
            name.classList.remove('showing');
        });
    }

    const showAll = () => {
        showAllCatSummaries();
        showAllClusterSummaries();
        setAllShown(true);
    }

    const hideAll = () => {
        hideAllCatSummaries();
        hideAllClusterSummaries();
        setAllShown(false);
    }


    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
            {isLoggedIn && (
                    <>
                    <CardHeader className="align-items-center d-flex p-10">
                    <h2>Cluster Data</h2>
                    </CardHeader>
                    <CardBody className="p-10" style={{maxWidth:'1385px'}}>This graph provides data about the average price of carbon credits from each of Ecosystem Marketplace’s project clusters, grouped within the larger project category. Vertical position indicates average price, and the area of each bubble is proportional to the volume of credits traded during the indicated date range. Hover over the graph for exact prices and volumes for each cluster. To maintain respondent confidentiality, volumes and average prices are displayed only for clusters that include data from at least three unique respondents. See the taxonomy below for more information about each cluster.</CardBody>
                    <CardBody className="p-10 pt-0">
                        <StandardsScatterWithJitter />
                    </CardBody> 
                    
                    </>
                )}
            <CardHeader className="align-items-center d-flex"><h2 className="mb-10 flex-grow-1">EM Carbon Project Taxonomy</h2></CardHeader>
                
                
                
                <CardBody>
                    <style dangerouslySetInnerHTML={{__html: `
                        .categoryContainer {
                            padding:30px 30px 30px 30px;
                            color:#fff;
                        } 
                        .categoryContainer * {
                            transition:all 0.25s ease-out;
                            transform-origin:top center;
                            padding:3px;
                            
                        }
                        .categoryContainer i {
                            position:relative;
                            width:36px;
                            height:36px;
                            display:flex;
                            align-items:center;
                            justify-content:center;
                            transform-origin:center center;
                        }
                        .taxCategory__name {
                            font-size: 24px;
                            font-weight: bold;
                            color: #ffffff;
                            cursor:pointer;
                            display:flex;
                        }  
                        .taxCategory__name.showing i {
                            transform:rotate(90deg);
                        }
                        .taxCategory__name:hover {
                            opacity:0.8;
                            transform:translateX(-3px);
                        }
                        .taxCategory__Summary {
                            font-size: 16px;
                            color: #ffffff;
                            font-weight:400;
                        }  
                        .taxClusterHeading {    
                            font-size: 20px;
                        } 
                        .btn-toggle {
                            cursor:pointer;
                            padding-left:0;
                            font-weight:bold;
                        }
                        .btn-toggle:hover {
                            opacity:0.8;
                            transform:translateX(-3px);
                        }
                        .taxClusterContainer {
                            padding:30px 30px 30px 30px;
                            line-height:1.2;
                            color:#000;
                        }
                        .taxCluster {
                            height:auto!important;
                            min-height:0;
                            color:#000;
                            font-weight:bold;
                        }
                        .categoryContainer .taxCluster i {
                            width:19px;
                            height:19px;
                        }
                        .taxCluster__name {
                            cursor:pointer;
                            color:#000;
                            display:flex;
                        }
                        .taxCluster__name.showing i {
                            transform:rotate(90deg);
                        }

                        .taxCluster__name:hover {
                            opacity:0.8;
                            transform:translateX(-3px);
                        }
                        .taxCluster__Summary {
                            font-size:16px;
                            font-weight:400;
                            color:#000;
                        }
                        .taxClusterHeading {
                            font-weight:bold;
                        }
                        .hide {
                            height:0;
                            overflow:hidden;
                            transform:scale(1,0);
                            margin-top:-9px;
                        }    
                        .show {
                            height:auto!important;
                            transform:scale(1,1)!important;
                            margin-top:0;
                        }
                    }          
                `}} />

                <CardBody className="p-10" style={{maxWidth:'1385px'}}>
               Ecosystem Marketplace places carbon credits into seven broad categories based on the methodology used to achieve emissions reductions or removals. Within these categories there are multiple clusters of related project types with similar methodologies and mechanisms of emissions reductions and removals. Explore the full list of categories and clusters using the expandable taxonomy below.</CardBody>
                    {dataReady && (
                        <>
                        <div className="d-flex justify-content-end"><button className="btn btn-primary add-btn mb-1" style={{marginRight:'8px'}} onClick={() => { showAll(); }}>Show All</button><button className="btn btn-primary add-btn mb-1 ml-2" onClick={() => { hideAll(); }}>Hide All</button></div>
                        {categoriesFiltered.filter((category) => category.summary != '').map((category) => (
                            <div key={'category'+category.categoryid} className="categoryContainer" style={{backgroundColor: category.color_med}}>
                                
                                <div className="taxCategory"><div className="taxCategory__name" onClick={toggleShowCatSummary}>{category.categoryname} <i className="ri-arrow-right-s-fill"></i></div>
                                    <div className="taxCategory__Summary hide">{category.summary}
                                        <br />
                                        <br />
                                        <div className="taxClusterContainer" style={{backgroundColor: category.color_light}}>
                                    <div className="taxClusterHeading">Clusters</div>
                                {clusterData.filter((cluster) => cluster.categoryid === category.categoryid  && cluster.summary != '').map((cluster) => (
                                    <div key={'cluster'+cluster.tagid} className="taxCluster" style={{color: '#ffffff'}} >
                                        <div className="taxCluster__name" onClick={toggleShowClusterSummary}>{cluster.clustername} <i className="ri-arrow-right-s-fill"></i></div>
                                        <div className="taxCluster__Summary hide">{cluster.summary}</div>
                                    </div>
                                ))}
                                </div>
                                    
                                    </div>
                                </div>
                                
                            </div>
                        ))}
                        <div className="d-flex justify-content-end mt-1"><button className="btn btn-primary add-btn mb-1" style={{marginRight: '8px'}} onClick={() => { showAll(); }}>Show All</button><button className="btn btn-primary add-btn mb-1" onClick={() => { hideAll(); }}>Hide All</button></div>
                        
                        </>
                    )}

                </CardBody>
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default TaxonomyPublic;
