import React from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 
import { useSelector } from 'react-redux';



import StandardsVolumeByCategory from '../../Charts/components/CategoriesStandards-StackedBarChart';
import StandardsVolumeTotals from '../../Charts/components/StandardsTotalVolume-BarChart';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-P7N889SGDD');






const StandardsPublic = () => {

    const { Login } = useSelector((state) => state);

    console.log('Login',Login);

    const isLoggedIn = Login?.user?.email ? true : false;
    console.log('isLoggedIn',isLoggedIn);

    const notLoggedIn = !isLoggedIn;

    if( isLoggedIn ){
        ReactGA.send({ hitType: "pageview", page: "/standards", title: "Respondent Standards Page" });
    } else {
        ReactGA.send({ hitType: "pageview", page: "/standards", title: "Public Standards Page" });
    }
    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>

            

                {isLoggedIn && (
                    <>
                    <CardHeader className="align-items-center d-flex"><h2 className="mb-10 flex-grow-1">Standards Data</h2></CardHeader>

                    <CardBody className="p-10" style={{maxWidth:'1385px'}}>
                    The charts on this page provide insights into the most prevalent credit issuing standards in Ecosystem Marketplace’s transaction data. Credit transaction volumes are indicated for standards only if data on those standards was provided by at least three unique respondents. In the second chart, transaction volumes are indicated for categories within a standard if data was provided by at least three respondents. Transactions from categories with fewer than three respondents are included in “Other.”
                    </CardBody>
                    
                    <CardBody className="p-10 pt-0">
                        <StandardsVolumeTotals />
                    </CardBody>
                    <CardBody className="p-10">
                        <StandardsVolumeByCategory />
                    </CardBody>
                
                    </>
                )}

                {notLoggedIn && (
                    <>
                        <CardHeader className="align-items-center d-flex p-10">
                        <h4>Message to Non-Users</h4>
                        </CardHeader>


                        
                        <CardBody className="p-10">
                            <p>
                            The standards section is only available to registered users. Please log in or register to access this section.
                            </p>
                        </CardBody>
                    </>
                )}

                
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default StandardsPublic;
