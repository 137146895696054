import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { URL } from '../../../constants';
import axios from 'axios';
import { Col, Row } from 'reactstrap'; 
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const RespondentsVolumeBarChart = () => {

    const [dataReady, setDataReady] = useState(false);

    const { user } = useSelector((state) => ({
        user: state.Login.user,
      }));
    
    const respondent_id = user?.respondent_id;
    const respondent_name = user?.respondent_name;

    const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
        'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other', 'Unspecified'];
    const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];



    let initialBarChartOptions = {
        colors: colors,
        chart: {
            type: 'column',
            height: '600',
            marginTop: '80',
        },
        title: {
            text: respondent_name+ ' Volume (tCO2e) by Year',
            align: 'left'
        },
        xAxis: {
            categories: [2020, 2021, 2022, 2023],
            crosshair: true,
            accessibility: {
                description: 'Countries'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Volume (tCO2e)'
            },
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0,
                color:'#3a3b8b',
            },
            

        },
        
        tooltip: {
            valueSuffix: ' tCO2e'
        },

        series: [],
        }

    const [barChartOptions, setBarChartOptions] = useState(initialBarChartOptions);

    useEffect(() => {
        axios.get(URL.get_resp_total_volume+`/?respondent_id=${respondent_id}`).then((response) => {
            let respVolumes = response.data;

            console.log('respVolumes', respVolumes);

            const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
                'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other', 'Unspecified'];
            const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

            let years = [];
            respVolumes.forEach((d) => {
                if (!years.includes(d.year_transact)) {
                    years.push(d.year_transact);
                }
            });

            years.sort();

            let volume_by_year = {};
        
            years.forEach((year) => {
                volume_by_year[year] = 0;
                respVolumes.forEach((d) => {
                    if (d.year_transact === year) {
                        volume_by_year[year] = d.volume_tco2e;
                    }
                });
            });

            let barChartData = [];
            let dataObj = {name: respondent_name, data: []};
            years.forEach((year) => {
                dataObj.data.push(volume_by_year[year]);
            });
            barChartData.push(dataObj);

            let xAxisObj = {categories: years};

            
            setBarChartOptions({ ...barChartOptions, series: barChartData, xAxis: xAxisObj});
            setDataReady(true);
    
            
        });
    }, []);


    return (
        <React.Fragment>
            
            <style dangerouslySetInnerHTML={{__html: `
            #volume_by_year_by_category_stacked_bar_chart, #vwap_by_year_by_category_line_chart { margin-top: 20px; }
            #volume_by_year_by_category_stacked_bar_chart > figure > div > span { margin-right: 20px; margin-bottom:10px; font-size:16px; }
            #volume_by_year_by_category_stacked_bar_chart > figure > div { max-width:100%; padding:20px 0; }
            #volume_by_year_by_category_stacked_bar_chart g[aria-label="y-axis label"] text { font-size: 24px!important; }
            
            `}} />

            <Row>
                <Col className="col-12 col-lg-12"><div id="sbc">
                {dataReady && <HighchartsReact
                    highcharts={Highcharts}
                    options={barChartOptions}
                />}</div>
                </Col>
            </Row>

        </React.Fragment>
    );


}

export default RespondentsVolumeBarChart;