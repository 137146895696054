import React, { useEffect, useState } from 'react';
import { URL } from '../../../constants';
import axios from 'axios';
import { Card, CardHeader, Col, Row } from 'reactstrap'; 
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const StandardsVolumeTotals = () => {

    const [dataReady, setDataReady] = useState(false);
    const [standards, setStandards] = useState([]);
    const [categories, setCategories] = useState([]);
    const [years, setYears] = useState([]);
    const [yearMin, setYearMin] = useState(0);
    const [yearMax, setYearMax] = useState(0);
    const [standardsData, setStandardsData] = useState([]);
    const [selectedStandards, setSelectedStandards] = useState([]);

    
    const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

    const fixed_categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
        'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other'];

    



    let initialBarChartOptions = {
        colors: colors, 
        chart: {
            type: 'column',
            height: '550',
            marginBottom: '170',
            marginTop: '70',
        },
        title: {
            text: '',
            align: 'left'
        },
        
        
        yAxis: {
            min: 0,
            title: {
                text: 'Volume (tCO2e)'
            },
            stackLabels: {
                enabled: true
            }
        },
        legend: {
            align: 'right',
            verticalAlign: 'top',
            floating: false,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                },
                color: '#3a3b8b',
            }
        },
        series: [],
        }

    const [barChartOptions, setBarChartOptions] = useState(initialBarChartOptions);

    const updateChart = (initialLoad = false, yearRangeUpdated = false) => {

        if( initialLoad ){

            axios.get(URL.get_standard_total_volume).then((response) => {
                
                let standardVolumes = response.data;

                setStandardsData(standardVolumes);


                const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
                    'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other', 'Unspecified'];
                const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

                let years = [];
                let standards = [];
                standardVolumes.forEach((d) => {
                    if (!years.includes(d.year_transact)) {
                        years.push(d.year_transact);
                    }
                    if (!standards.includes(d.project_standard)) {
                        standards.push(d.project_standard);
                    }
                });

                years.sort();
                setYears(years);
                setYearMin(years[0]);
                setYearMax(years[years.length - 1]);
                setStandards(standards);
                setSelectedStandards(standards);

                let volume_by_standard = {};

                standards.forEach((standard) => {
                    volume_by_standard[standard] = 0;
                });
                standards.forEach((standard) => {
                    standardVolumes.forEach((d) => {
                        if (d.project_standard === standard) {
                            volume_by_standard[standard] += d.volume_tco2e;
                        }
                    });
                });

                // sort volume_by_standard by value
                standards.sort((a, b) => volume_by_standard[b] - volume_by_standard[a]);


                let barChartData = [];
                let dataObj = {name: 'Volume (tCO2e)', data: []};
                standards.forEach((standard) => {
                    dataObj.data.push(volume_by_standard[standard]);
                });
                barChartData.push(dataObj);

                let xAxisObj = {categories: standards};

                
                setBarChartOptions({ ...barChartOptions, series: barChartData, xAxis: xAxisObj});
                setDataReady(true);
    
        
            });
        } else {

            let volume_by_standard = {};

            if( yearRangeUpdated ){

                standards.forEach((standard) => {
                    volume_by_standard[standard] = 0;
                });
                standards.forEach((standard) => {
                    standardsData.forEach((d) => {
                        if (d.project_standard === standard && d.year_transact >= yearMin && d.year_transact <= yearMax) {
                            volume_by_standard[standard] += d.volume_tco2e;
                        }
                    });
                });

                standards.sort((a, b) => volume_by_standard[b] - volume_by_standard[a]);

                setSelectedStandards(standards);
                    
                
            } else {
                selectedStandards.forEach((standard) => {
                    volume_by_standard[standard] = 0;
                });
                selectedStandards.forEach((standard) => {
                    standardsData.forEach((d) => {
                        if (d.project_standard === standard && d.year_transact >= yearMin && d.year_transact <= yearMax) {
                            volume_by_standard[standard] += d.volume_tco2e;
                        }
                    });
                });

                selectedStandards.sort((a, b) => volume_by_standard[b] - volume_by_standard[a]);
            }

            let barChartData = [];
            let dataObj = {name: 'Volume (tCO2e)', data: []};
            selectedStandards.forEach((standard) => {
                dataObj.data.push(volume_by_standard[standard]);
            });
            barChartData.push(dataObj);

            let xAxisObj = {categories: selectedStandards};
            
            setBarChartOptions({ ...barChartOptions, series: barChartData, xAxis: xAxisObj});
        }
    };

    useEffect(() => {
        updateChart(true);
    }, []);

    useEffect(() => {
        updateChart(false);
    }, [selectedStandards]);

    useEffect(() => {
        updateChart(false, true);
    }, [yearMin, yearMax]);




    return (
        <React.Fragment>
            
            <style dangerouslySetInnerHTML={{__html: `
            .standardsCheckboxes label { font-size: 12px; text-transform:none; top:-5px; position:relative; margin-bottom:0; }
            .standardsCheckboxes input[type="checkbox"] { appearance:none; -webkit-appearance: none; background-color: #375f47;	border: 1px solid #cacece;
	            box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05); padding: 7px; border-radius: 3px; display: inline-block;
	            position: relative; margin-right:4px; }

            .standardsCheckboxes input[type="checkbox"]:checked:after { content: '\\2714'; font-size: 10px; color: #fff; position: absolute; top: 0px; left: 3px; }
            .dateRangeSelectors select { margin:0 25px 0 5px;}
            
            `}} />
            <Row>
            <Col className="col-12 col-lg-12"><CardHeader><strong style={{fontSize : "1.4em"}}>Total Volume (tCO2e) by Standard</strong><br />Only the eight standards with the highest volumes for the selected date range are shown.  Uncheck standards to hide them.</CardHeader></Col>
            </Row>
            { /* insert years dropdown selector here */ }
            <CardHeader>
                <Row>
                    <Col className="col-12 col-lg-12 dateRangeSelectors">
                        <strong>Date Range: <label style={{ textTransform:"none", marginLeft:"20px", fontWeight: "bold"}}>Year Min</label> </strong>
                        
                        <select id="yearMin" name="yearMin" onChange={(e) => {
                            let selectedYear = e.target.value;
                            console.log('selectedYear', selectedYear);
                            setYearMin(selectedYear);
                        }}>
                            { 
                            years.map((year, index) => {
                                if( year <= yearMax ){
                                    return <option key={index} value={year} selected={year == yearMin}>{year}</option>
                                }
                            })}
                            
                            
                        </select>

                        <strong>Year Max </strong>
                        
                        <select id="yearMax" name="yearMax" onChange={(e) => {
                            let selectedYear = e.target.value;
                            console.log('selectedYear', selectedYear);
                            setYearMax(selectedYear);
                        }}>
                            { years.reverse().map((year, index) => {
                                if( year >= yearMin ){
                                    return <option key={index} value={year} selected={year == yearMax}>{year}</option>
                                }
                            })}
                            {years.reverse()}
                            
                        </select>
                          
                        { (yearMin != years[0] || yearMax != years[years.length - 1]) && <><input type="checkbox" id="resetYearRange" name="resetYearRange" value="resetYearRange" onChange={(e) => {
                            if( e.target.checked ){
                                setYearMin(years[0]);
                                setYearMax(years[years.length - 1]);
                                e.target.checked = false;
                            }
                        }} /> Reset Date Range</> }
                    </Col>
                </Row>    

            </CardHeader>

            { /* insert standards checkboxes here  */ }
            <CardHeader><Row className="standardsCheckboxes">
                {standards.map((standard, index) => {
                    return (
                        <Col key={index} className="col-3 col-lg-3">
                            <input type="checkbox" id={standard} name={standard} value={standard} checked={selectedStandards.includes(standard)} onChange={(e) => {
                                let checked = e.target.checked;
                                if (checked) {
                                    setSelectedStandards([...selectedStandards, standard]);
                                } else {
                                    setSelectedStandards(selectedStandards.filter((s) => s.indexOf(standard) === -1));
                                }
                            }} />
                            <label htmlFor={standard}>{standard}</label>
                        </Col>
                    );
                })}
                
            </Row></CardHeader>

            <Row>
                <Col className="col-12 col-lg-12"><div>
                {dataReady && <HighchartsReact
                    highcharts={Highcharts}
                    options={barChartOptions}
                />}</div>
                </Col>
            </Row>

        </React.Fragment>
    );


}

export default StandardsVolumeTotals;