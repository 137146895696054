import React, { useEffect, useState } from 'react';

//redux
import { useSelector, useDispatch } from 'react-redux';
import {
  Col,
  Modal,
  ModalBody,
  Row,
  Label,
  Input,
  Button,
  ModalHeader,
  FormFeedback,
  Form,
} from 'reactstrap';

import {
  addNewProject,
  getCountryList,
  updateProject,
  getTypeList,
  getStandardList,
  getProjectList,
} from '../../../store/actions';

// Formik
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Select from 'react-select';

const ProjectForm = ({
  isEdit,
  currentProject,
  modal,
  setModal,
  toggle,
  onClosed,
}) => {
  const dispatch = useDispatch();

  const {
    user,
    standardList,
    isProjectCreated,
    isProjectSuccess,
    error,
    countries,
    typeList,
    newProject,
  } = useSelector((state) => ({
    user: state.Login.user,
    isProjectCreated: state.Project.isProjectCreated,
    isProjectSuccess: state.Project.isProjectSuccess,
    error: state.Project.error,
    countries:
      state.Login.countries.map((el) => ({ value: el.id, label: el.name })) ||
      [],
    typeList:
      state.Project.typeList.map((el) => ({
        value: el.typeid,
        label: el.typename,
      })) || [],
    standardList:
      state.Project.standardList
        .map((el) => ({ value: el.standardid, label: el.name }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)) || [],
  }));

  

  const ProjectSchema = Yup.object({
    project_code: Yup.string().required('Please Enter Code'),
    project_name: Yup.string().required('Please Enter Name'),
    typeid: Yup.number(),
    methdology: Yup.string(),
    standard: Yup.number(),
    //corsia: Yup.boolean(),
    country: Yup.number().required('Please Select Country').oneOf(countries.map((el) => ( el.value ))),
  });

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      project_code: currentProject?.project_code || '',
      project_name: currentProject?.project_name || '',
      typeid: currentProject?.typeid || '',
      methdology: currentProject?.methdology || '',
      standard: currentProject?.standard || '',
      //corsia: currentProject?.corsia || '',
      country: currentProject?.country || null,
      projectIdKnown: currentProject?.ProjID_Known || false,
    },

    validationSchema: ProjectSchema,

    onSubmit: (values) => { 
      if (isEdit) {
        const updatedProject = {
          projectid: currentProject ? currentProject.projectid : 0,
          project_name: values.project_name,
          project_code: values.project_code,
          typeid: values.typeid,
          country: values.country,
          methdology: values.methdology,
          standard: values.standard,
          //corsia: values.corsia,
          ProjID_Known: values.projectIdKnown,
        };
        // update customer
        dispatch(updateProject(updatedProject));
        validation.resetForm();
      } else {
        const newProject = {
          project_code: values['project_code'],
          project_name: values['project_name'],
          typeid: values['typeid'],
          country: values['country'],
          methdology: values['methdology'],
          standard: values['standard'],
          //corsia: values['corsia'],
          ProjID_Known: values['projectIdKnown'],
          user_email: user.email,
        };
        console.log('newProject', newProject);
        // save new customer
        dispatch(addNewProject(newProject));
        validation.resetForm();
        setModal(false);
        setTimeout(() => {
          dispatch(getProjectList({ skip: 0 }));
        }, 1000);
      }
    },
  });

  useEffect(() => {
    // Passed the limit  9999 to get all countries
    dispatch(getTypeList({ limit: 9999 }));
    dispatch(getCountryList({ limit: 9999 }));
    dispatch(getStandardList({ limit: 9999 }));
  }, []);

  const onSelectChange = (e, name) => {
    validation.setFieldValue(name, e.value);
  };

  const selectValue = (option, options) => {
    return options.find((el) => el.value === option);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size='md'
        className='border-0'
        modalClassName='modal fade zoomIn'
        onClosed={onClosed}>
        <ModalHeader className='p-3 bg-soft-success' toggle={toggle}>
          {!!isEdit ? 'Edit Project' : 'Add a New Project'}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}>
          <ModalBody className='modal-body'>
            <div className='py-2 mb-4 alert alert-warning info'> 
              Projects will be reviewed and verified after submission. We may be in touch if we have questions.</div>
            <Row className='g-3'>
              <Col lg={12}>
                <Label for='projectCode-field' className='form-label text-uppercase d-none'>
                  Project Code
                </Label>
                <Input
                  name='project_code'
                  id='projectCode-field'
                  className='form-control'
                  placeholder='Project Code '
                  type='text'
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.project_code || ''}
                  invalid={
                    validation.touched.project_code &&
                    validation.errors.project_code
                  }
                />
                <div className='form-text'>
                What is the official project code? Include the standard, eg VCS123 / GS456
                </div>
                {validation.touched.project_code &&
                validation.errors.project_code ? (
                  <FormFeedback type='invalid'>
                    {validation.errors.project_code}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col lg={12}>
                <Label for='projectName-field' className='form-label  d-none'>
                  Project Name
                </Label>
                <Input
                  name='project_name'
                  id='projectName-field'
                  className='form-control'
                  placeholder='Project name'
                  type='text'
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.project_name || ''}
                  invalid={
                    validation.touched.project_name &&
                    validation.errors.project_name
                  }
                />
                {validation.touched.project_name &&
                  validation.errors.project_name && (
                    <FormFeedback type='invalid'>
                      {validation.errors.project_name}
                    </FormFeedback>
                  )}
              </Col>

              <Col lg={12}>
                <Label for='standard' className='form-label d-none'>
                  Standard
                </Label>
                <Select
                  value={selectValue(validation.values.standard, standardList)}
                  onChange={(e) => onSelectChange(e, 'standard')}
                  options={standardList}
                  placeholder='Standard'
                  name='standard'
                  id='standard'
                  classNamePrefix='select2-selection form-select'
                />
                {validation.touched.methdology &&
                  validation.errors.methdology && (
                    <FormFeedback type='invalid'>
                      {validation.errors.methdology}
                    </FormFeedback>
                  )}
              </Col>

              <Col lg={12}>
                <Label for='typeid' className='form-label d-none'>
                  Type
                </Label>
                <Select
                  value={selectValue(validation.values.typeid, typeList)}
                  onChange={(e) => onSelectChange(e, 'typeid')}
                  options={typeList}
                  placeholder='Type'
                  name='typeid'
                  id='typeid'
                  classNamePrefix='select2-selection form-select'
                />
                {validation.touched.typeid && validation.errors.typeid && (
                  <FormFeedback type='invalid'>
                    {validation.errors.typeid}
                  </FormFeedback>
                )}
              </Col>

              <Col lg={12}>
                <Label for='country' className='form-label d-none'>
                  Country
                </Label>
                <Select
                  value={selectValue(validation.values.country, countries)}
                  onChange={(e) => onSelectChange(e, 'country')}
                  options={countries}
                  placeholder='Country'
                  name='country'
                  id='country'
                  classNamePrefix={validation.touched.country && validation.errors.country && (
                    'orange-border'
                  )+' select2-selection form-select'}
                  
                  
                />
                {validation.touched.country && validation.errors.country && (
                  <FormFeedback className="d-block">
                    Please select a country
                  </FormFeedback>
                )}
              </Col>
              <Col lg={12} className={ user?.is_superuser ? 'd-block' : 'd-none' }>
                <div className='form-check form-switch form-switch-secondary mb-3'>
                  <Input
                    name='projectIdKnown'
                    id='projectIdKnown-field'
                    placeholder='projectIdKnown'
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    onChange={() =>
                      validation.setFieldValue(
                        'projectIdKnown',
                        !validation.values.projectIdKnown
                      )
                    }
                    onBlur={validation.handleBlur}
                    checked={validation.values.projectIdKnown || false}
                    invalid={
                      validation.touched.projectIdKnown &&
                      validation.errors.projectIdKnown
                    }
                  />
                  <Label
                    for='projectIdKnown-field'
                    className='form-check-label'>
                    Project Live
                  </Label>
                  <FormFeedback type='invalid' className='d-block'>
                    Approve Project Immediately?
                  </FormFeedback>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <div className='modal-footer'>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                type='button'
                onClick={() => {
                  setModal(false);
                }}
                className='btn-light'>
                Close
              </Button>
              <button type='submit' className='btn btn-success' id='add-btn'>
                {!!isEdit ? 'Update Project' : 'Add Project'}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ProjectForm;
