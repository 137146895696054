import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

// Import Images
import logolight from "../../assets/images/em_logo.png";

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="custom-footer bg-dark py-2 position-relative">
                <Container>
                    <Row>
                        {/* <Col lg={4} className="mt-4">
                            <div>
                                <div>
                                    <img src={logolight} alt="logo light" height="140" className="bg-white p-3" />
                                </div>
                                <div className="mt-4">
                                    <p>Voluntary carbon market data, intelligence, and insights.</p>
                                </div>
                            </div>
                        </Col> */}

                        <Col lg={7} className="ms-lg-auto">
                            <Row>
                                {/* <Col sm={4} className="mt-4">
                                    <h5 className="text-white mb-0">Organisation</h5>
                                    <div className="text-muted mt-3">
                                        <ul className="list-unstyled ff-secondary footer-list">
                                            <li><Link to="/pages-profile">About Us</Link></li>
                                            <li><Link to="/pages-gallery">Gallery</Link></li>
                                            <li><Link to="/apps-projects-overview">Projects</Link></li>
                                            <li><Link to="/pages-timeline">Timeline</Link></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col sm={4} className="mt-4">
                                    <h5 className="text-white mb-0">Apps Pages</h5>
                                    <div className="text-muted mt-3">
                                        <ul className="list-unstyled ff-secondary footer-list">
                                            <li><Link to="/pages-pricing">Calendar</Link></li>
                                            <li><Link to="/apps-mailbox">Mailbox</Link></li>
                                            <li><Link to="/apps-chat">Chat</Link></li>
                                            <li><Link to="/apps-crm-deals">Deals</Link></li>
                                            <li><Link to="/apps-tasks-kanban">Kanban Board</Link></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col sm={4} className="mt-4">
                                    <h5 className="text-white mb-0">Support</h5>
                                    <div className="text-muted mt-3">
                                        <ul className="list-unstyled ff-secondary footer-list">
                                            <li><Link to="/pages-faqs">FAQ</Link></li>
                                            <li><Link to="/pages-faqs">Contact</Link></li>
                                        </ul>
                                    </div>
                                </Col> */}
                            </Row>
                        </Col>

                    </Row>

                    <Row className="text-center text-sm-start align-items-center mt-2">
                        <Col sm={6}>

                            <div>
                                <p className="copy-rights mb-0">
                                    {new Date().getFullYear()} © Ecosystem Marketplace
                                </p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end mt-3 mt-sm-0">
                                <ul className="list-inline mb-0 footer-social-link">
                                    <li className="list-inline-item">
                                        <a href="https://twitter.com/EcoMarketplace" className="avatar-xs d-block" target="_blank">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-twitter-fill"></i>
                                            </div>
                                        </a>
                                    </li> 
                                    <li className="list-inline-item">
                                        <a href="https://www.linkedin.com/company/ecosystem-marketplace/" className="avatar-xs d-block" target="_blank">
                                            <div className="avatar-title rounded-circle">
                                                <i className="ri-linkedin-fill"></i>
                                            </div>
                                        </a>
                                    </li> 
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment >
    );
};

export default Footer;