import React from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 



import VolumeByCategoryStackedBarChartPrivate from '../../Charts/components/VolumeByCategory-StackedBarChart'; 
import VWAPByCategoryAndYearMultiLineChartPrivate from '../../Charts/components/VWAPByCategoryAndYear-MultiLineChart-Private';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-P7N889SGDD');
ReactGA.send({ hitType: "pageview", page: "/categories-resp", title: "Respondent Categories Page" });






const CategoriesPrivate = () => {


    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
                <CardHeader className="align-items-center d-flex"><h2 className="mb-10 flex-grow-1">Category Data</h2></CardHeader>
                

                <CardBody className="p-10"  style={{maxWidth:'1385px'}}>
                
                The graphs on this page break down the annual volume and average price of carbon credit trades by category from Ecosystem Marketplace’s  project taxonomy. For more information, see the <a href="/taxonomy">Taxonomy</a> page.
                </CardBody>
                
                <CardBody className="p-10">
                    <VolumeByCategoryStackedBarChartPrivate />
                </CardBody>
                <CardBody className="p-10">
                    <VWAPByCategoryAndYearMultiLineChartPrivate />
                </CardBody>
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default CategoriesPrivate;
