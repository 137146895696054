//REGISTER
import {
  RESPONDENT_INVITE_VALIDATE_FAILED,
  RESPONDENT_REGISTER_USER,
} from '../store/auth/register/actionTypes';

export const POST_FAKE_REGISTER = '/auth/signup';

//LOGIN
export const POST_FAKE_LOGIN = '/auth/signin';
export const POST_FAKE_JWT_LOGIN = '/post-jwt-login';
export const POST_FAKE_PASSWORD_FORGET = '/auth/forgot-password';
export const POST_FAKE_JWT_PASSWORD_FORGET = '/jwt-forget-pwd';
export const SOCIAL_LOGIN = '/social-login';

//PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile';
export const POST_EDIT_PROFILE = '/user';

// Rest Auth API Endpoints
export const AUTH_API = {
  LOGIN: '/api/auth/login/',
  USER: '/api/auth/user/',
  REGISTER: '/api/auth/registration/',
  VERIFY_EMAIL: '/api/auth/registration/verify-email/',
  WORLD_COUNTRY: '/api/worldcountries/',
  RESPONDENT_INVITE_VALIDATE: '/api/auth/respondent-registration/validate',
  RESPONDENT_REGISTER: '/api/auth/respondent-registration/',
};
//TODO Move everything related to the project to PROJECT_API
export const TRADE_API = {
  BUYER: '/api/buyer/',
  GETBUYER: '/api/all-buyers/',
  TYPE: '/api/type/',
  PROJECT: '/api/project/',
  GETPROJECT: '/api/some-projects/', 
  TRADE: '/api/trade/',
  GETTRADELIST: '/api/data/get_tradelist',
  GETTRADE: '/api/all-trades/',
  RESPONDENT: '/api/respondent/',
  GETRESPONDENT: '/api/all-respondents/',
  RESPONDENT_USERS: '/api/respondent/',
  INVITE_RESPONDENT: '/api/respondent-invite/send/',
  BACKFILL_CURRENCY_CONVERSION: '/api/data/back-fill-currency-conversion-values',
};

export const PROJECT_API = {
  STANDARD: '/api/standards/',
  COBENEFIT: '/api/cobenefit/',
  SDG: '/api/sdgs/'
};

export const BUYER_API = {
  SECTOR: '/api/sector/'
}

