import React from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 



import VolumeByCategoryStackedBarChartPrivate from '../../Charts/components/VolumeByCategory-StackedBarChart';
import VWAPByCategoryAndYearMultiLineChartPublic from '../../Charts/components/VWAPByCategoryAndYear-MultiLineChart-Public';








const CategoriesPrivate = () => {


    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
                <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Volume and Price by Category</h4>
                <div className="flex-shrink-0"></div>
                </CardHeader>
                
                <CardBody className="p-0">
                    <VolumeByCategoryStackedBarChartPrivate />
                </CardBody>
                <CardBody className="p-0">
                    <VWAPByCategoryAndYearMultiLineChartPublic />
                </CardBody>
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default CategoriesPrivate;
