import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { logoutUser } from '../../store/auth/login/actions';
import { getRespondentList } from '../../store/actions';
import { deleteAllCookies } from '../../common/helper';

const ProfileDropdown = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user, respondentId } = useSelector((state) => ({
    user: state.Profile.user,
    respondentId: state?.Login?.user?.respondent_id,
  }));

  const [userName, setUserName] = useState('Admin');
  const [userRespondent, setUserRespondent] = useState('');
  const { Login } = useSelector((state) => state);

  useEffect(() => {
    if (sessionStorage.getItem('authUser')) {
      const obj = JSON.parse(sessionStorage.getItem('authUser'))['user'];
      setUserName(user.first_name || obj.first_name || 'Admin');
      setUserRespondent(obj.respondent_name || "No user's Respondent");
    }
  }, [userName, user]);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className='ms-sm-3 header-item topbar-user'>
        <DropdownToggle tag='button' type='button' className='btn'>
          <span className='d-flex align-items-center'>
            {/* <img
              className="rounded-circle header-profile-user"
              src={avatar1}
              alt="Header Avatar"
            /> */}
            <span className='text-start ms-xl-2'>
              <span className='d-none d-sm-inline-block ms-1 fw-medium user-name-text'>
                {userName}
              </span>
              <span className='d-none d-sm-block ms-1 fs-12 text-muted user-name-sub-text'>
                {userRespondent}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-end'>
          {/* <h6 className='dropdown-header'>Welcome {userName}!</h6> */}
          {/* <DropdownItem href="/profile">
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
            <span className="align-middle">Profile</span>
          </DropdownItem> */}
          {/* <DropdownItem href="/apps-chat">
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Messages</span>
          </DropdownItem>
          <DropdownItem href="/apps-tasks-kanban">
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Taskboard</span>
          </DropdownItem>
          <DropdownItem href="/pages-faqs">
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Help</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem href="/pages-profile">
            <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              Balance : <b>$5971.67</b>
            </span>
          </DropdownItem>
          <DropdownItem href="/pages-profile-settings">
            <span className="badge bg-soft-success text-success mt-1 float-end">
              New
            </span>
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Settings</span>
          </DropdownItem>
        */}

          { Login?.user?.role != 3 && (<DropdownItem
            onClick={() => {
              history.push(`/employees/${respondentId}`);
            }}>
            <i className='ri-user-follow-line text-muted fs-16 align-middle me-1'></i>{' '}
            <span className='align-middle' data-key='t-logout'>
              Manage users
            </span>
          </DropdownItem>
          ) }
          <DropdownItem
            onClick={() => {
              deleteAllCookies();
              // history.push(window?.location?.origin);
              localStorage.setItem('userLogout', true);
              dispatch(logoutUser());
              window.location.href = window?.location?.origin;
            }}>
            <i className='ri-logout-circle-r-line text-muted fs-16 align-middle me-1'></i>{' '}
            <span className='align-middle' data-key='t-logout'>
              Logout
            </span>
          </DropdownItem>
          <div className='dropdown-divider'></div>
          <DropdownItem>
            <i className='mdi mdi-lightbulb text-info fs-16 align-middle me-1'></i>{' '}
            <span className='align-middle text-info' data-key='t-logout'>
              <a href='http://roadmap.ecosystemmarketplace.com/feature-requests' target='_blank'>Feedback? Ideas?</a>
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
