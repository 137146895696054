import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Button,
  Spinner,
} from 'reactstrap';

import HubspotForm from 'react-hubspot-form';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// action
import { registerUser, apiError, resetRegisterFlag } from '../../store/actions';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { Link, useHistory, withRouter } from 'react-router-dom';

//import images
import logoLight from '../../assets/images/em_logo.png';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';
import FeatherIcon from 'feather-icons-react';
 
// import path
import { PATH_AUTH, PATH_HOME } from '../../Routes/paths';




  

const Register = (props) => {
  // TODO Need to create a route for register/respondent/<token>
  // TODO this will contain a register form without email, please send the token to the backend along with other fields
  // TODO call /create-respondent-user/ backend will create the respondent user
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      first_name: Yup.string().required('Please Enter Your First Name'),
      last_name: Yup.string().required('Please Enter Your Last Name'),
      password: Yup.string()
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        ),
    }),
    onSubmit: (values) => {
      let user = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password1: values.password,
        password2: values.password,
      };
      dispatch(registerUser({ user, history: props.history }));
    },
  });

  const { error, registrationError, success } = useSelector((state) => ({
    registrationError: state.Account.registrationError,
    success: state.Account.success,
    error: state.Account.error,
  }));

  useEffect(() => {
    dispatch(apiError(''));
  }, [dispatch]);


 

  useEffect(() => {
    validation.setSubmitting(false);
    if (success) {
      validation.resetForm({});
    }

    setTimeout(() => {
      dispatch(resetRegisterFlag());
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, success, error]);

  document.title = 'Ecosystem Marketplace - Contact Form';

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className='auth-page-content'>
          <Container className="contactFormContainer">
      
            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                
                <Card className='mt-4'>
                  <CardBody className='p-4'>

                    <div className='text-center mb-3'> 
                      <Link
                        to={PATH_HOME.root}
                        className='d-inline-block auth-logo p-3 bg-white'>
                        <img src={logoLight} alt='' height='100' />
                      </Link>
                    </div>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>Contact Form</h5>
                      <p className='text-muted p-5 pt-0 pb-0'>
                      Request more information about the Hub from the EM team
                      </p>
                   
                    </div>
                    <div className='p-2 mt-4'>
                    
                       
 
                          <HubspotForm
                            portalId='3298623'
                            formId='be40e9ad-6a5e-4cdf-8d66-a20f2c1fa0b0'
                            onSubmit={() => console.log('Submit!')}
                            onReady={(form) => console.log('Form ready!')}
                            loading={<div>Loading...</div>}
                            region='na1'
                          />



                 

                        <div className='mb-4'>
                          <p className='mb-0 fs-12 text-muted fst-italic'>
                            By registering you agree to the Ecosystem
                            Marketplace
                            <a
                              href='https://docs.ecosystemmarketplace.com/hub/legal/terms'
                              target='_blank'
                              className='text-primary text-decoration-underline fst-normal fw-medium ms-1'>
                              Terms of Use
                            </a>
                          </p>
                        </div>
 
                    </div>
                  </CardBody>
                </Card>
                <div className='mt-4 text-center'>
                  <p className='mb-0'>
                    Already have an account ?{' '}
                    <Link
                      to={PATH_AUTH.login}
                      className='fw-semibold text-primary text-decoration-underline'>
                      {' '}
                      Signin{' '}
                    </Link>{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Register);
