import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Container,
  Table,
  Input,
  Alert,
  Label,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';
import ReactTable from '../../Components/Common/ReactTable';
import DeleteModal from '../../Components/Common/DeleteModal';
import Loader from '../../Components/Common/Loader';
import { deleteBuyer, getBuyerList } from '../../store/actions';
import BuyerForm from './components/BuyerForm';

const BuyerList = () => {
  const dispatch = useDispatch();
  const {
    buyers,
    Buyer,
    buyerNext,
    buyerPrev,
    buyerNumPages,
    error,
    isBuyerSuccess,
    isBuyerCreated,
    isBuyerDeleted,
    user,
  } = useSelector((state) => ({
    buyers: state.Buyer.buyerList,
    Buyer: state.Buyer,
    buyerNext: state.Buyer.buyerNext,
    buyerPrev: state.Buyer.buyerPrev,
    buyerNumPages: state.Buyer.buyerListNumPages,
    isBuyerSuccess: state.Buyer.isBuyerSuccess,
    isBuyerCreated: state.Buyer.isBuyerCreated,
    isBuyerDeleted: state.Buyer.isBuyerDeleted,
    error: state.Buyer.error,
    user: state.Login.user,
  }));

  const [buyerList, setBuyerList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [currentBuyer, setCurrentBuyer] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  // Buyer Form Modal
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggle = useCallback(() => {
    // console.log(modal);
    if (modal) {
      setModal(false);
      setCurrentBuyer(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  useEffect(() => {
    // the buyerList is an array of objects
    // sort the buyerList array by the buyer_id key in descending order
    let sortedBuyerList = [];
    if( Buyer.buyerList && Buyer.buyerList.length > 0 ){
      sortedBuyerList = Buyer.buyerList ? Buyer.buyerList.sort((a, b) => b.buyer_id - a.buyer_id) : []; 
    }
    setBuyerList(sortedBuyerList);
  }, [buyers, Buyer.buyerList]);

  useEffect(() => {
    dispatch(getBuyerList());
  }, [dispatch, isBuyerCreated, isBuyerDeleted]);

  useEffect(() => {
    dispatch(
      getBuyerList({
        search: searchTerm,
        skip: 0,
      })
    );
  }, [dispatch, searchTerm]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'buyer_name',
        filterable: false,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        // Cell: (cell) => {
        //   return (
        //     <Link to='#' className='fw-medium link-primary'>
        //       {cell.value}
        //     </Link>
        //   );
        // },
      },
      {
        Header: 'Profit Status',
        accessor: 'buyer_profit_status',
        filterable: false,
      },
      {
        Header: 'Sector',
        accessor: 'buyer_sector',
        filterable: false,
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },

      // {
      //   Header: 'Save as',
      //   accessor: 'is_bulk_uploaded',
      //   filterable: false,
      //   cellStyle: {
      //     overflow: 'hidden',
      //     textOverflow: "ellipsis",
      //   },
      //   Cell: (cell) => cell.value ? "Bulk": "Manual",
      // },
      {
        Header: 'Country',
        accessor: 'country.name',
        cellStyle: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
      {
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <ul className='list-inline hstack gap-2 mb-0'>
              <li className='list-inline-item edit'>
                <Link
                  to='#'
                  onClick={() => {
                    const buyerData = cellProps.row.original;
                    handleCustomerClick(buyerData);
                  }}
                  className='text-primary d-inline-block edit-item-btn'
                >
                  <i className='ri-pencil-fill fs-16'></i>
                </Link>
              </li>
              <li className='list-inline-item'>
                <Link
                  to='#'
                  onClick={() => {
                    const buyerData = cellProps.row.original;
                    onClickDelete(buyerData);
                  }}
                  className='text-danger d-inline-block remove-item-btn'
                >
                  <i className='ri-delete-bin-5-fill fs-16'></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const getRowId = (row, relativeIndex, parent) => {
    // In row object you have access to data.
    // Yon choose parameter. In this example I used uniqueId
    return row.buyer_id;
  };

  // Update Data

  const handleCustomerClick = useCallback(
    (arg) => {
      const buyer = arg;
      setCurrentBuyer({
        buyer_id: buyer.buyer_id,
        buyer_name: buyer.buyer_name,
        buyer_profit_status: buyer.buyer_profit_status,
        buyer_profit_status_description: buyer.buyer_profit_status_description,
        buyer_sector: buyer.buyer_sector,
        country: buyer.country.id,
      });

      toggle();
    },
    [toggle]
  );

  // Delete Data
  const onClickDelete = (buyer) => {
    setCurrentBuyer(buyer);
    setDeleteModal(true);
  };

  const onClosed = () => {
    setCurrentBuyer(null);
    dispatch(getBuyerList());
  };

  // Delete Data
  const handleDeleteTask = () => {
    if (currentBuyer) {
      dispatch(deleteBuyer(currentBuyer)); 
      setDeleteModal(false);
      setCurrentBuyer(null);
    }
  };

  const onChangeSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Paginate the data for react table
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      console.log(pageSize, pageIndex, searchTerm);
      dispatch(
        getBuyerList({
          // limit: pageSize,
          skip: pageSize * pageIndex,
          search: searchTerm,
        })
      );
      setCurrentPage(pageIndex);
      // console.log("fetchData is being called")
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.
      // Give this fetch an ID
      // const fetchId = ++fetchIdRef.current;
      // setLoading(true);
      // if (fetchId === fetchIdRef.current) {
      //   fetchAPIData({
      //     limit: pageSize,
      //     skip: pageSize * pageIndex,
      //     search: searchTerm,
      //   });
      // }
    },
    [dispatch]
  );

  const debouncedResults = useMemo(() => {
    return debounce(onChangeSearch, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  document.title = 'Ecosystem Marketplace - Buyer List';
  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTask}
        onCloseClick={() => setDeleteModal(false)}
      />
      <BuyerForm
        isEdit={currentBuyer}
        modal={modal}
        setModal={setModal}
        currentBuyer={currentBuyer}
        toggle={toggle}
        onClosed={onClosed}
      />
      <div className='page-content'>
        <Container fluid={true}>
          <BreadCrumb title='Our Buyers' pageTitle='Buyers' />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <div className='d-flex align-items-center'>
                    <h5 className='card-title mb-0 flex-grow-1'>Buyers</h5>
                    <div className='flex-shrink-0'>
                      <button
                        type='button'
                        className='btn btn-success add-btn'
                        id='create-btn'
                        onClick={() => setModal(true)}
                      >
                        <i className='ri-add-line align-bottom me-1'></i> Create
                        Buyer
                      </button>{' '}
                    </div>
                  </div>
                </CardHeader>
                {/* <CardBody className='border border-dashed border-end-0 border-start-0'>
                  <Row>
                    <Col sm={5}>
                      <div className='search-box me-2 mb-2 d-inline-block col-12'>
                        <input
                          id='search-bar-0'
                          type='text'
                          className='form-control search /'
                          placeholder={`Search...`}
                          onChange={debouncedResults}
                        />
                        <i className='bx bx-search-alt search-icon'></i>
                      </div>
                    </Col>
                  </Row>
                </CardBody>   */}
                  <CardBody className="p-0 card-border-info ">
                      <Alert color='info' className="border-0 rounded-top rounded-0 m-0 d-flex align-items-center">
                          <i className="ri-information-line me-2 fs-16"
                          />
                          <div className="flex-grow-1 text-truncate">
                          Privacy Note: this page and the buyers list contained herein is private per EM Respondent organization.                         
                          </div>
                      </Alert>
                  </CardBody>
           

                <CardBody>
                  {isBuyerSuccess && buyerList.length ? (
                    <ReactTable
                      tableStyles={{ tableLayout: 'fixed' }}
                      columns={columns}
                      data={buyerList}
                      customPageSize={10}
                      divClass='table-responsive table-card mb-1'
                      tableClass='align-middle table-nowrap'
                      theadClass='table-success text-muted'
                      getRowId={getRowId}
                      fetchData={fetchData}
                      pageCount={buyerNumPages}
                      currentPage={currentPage}
                      isNextPage={buyerNext}
                      isPrevPage={buyerPrev}
                      nextHandler={() =>
                        dispatch(
                          getBuyerList({
                            page: buyerNext,
                          })
                        )
                      }
                      prevHandler={() =>
                        dispatch(
                          getBuyerList({
                            page: buyerPrev > 2 ? buyerPrev - 1 : 1,
                          })
                        )
                      }
                    />
                  ) : isBuyerSuccess && !error ? (
                    'No buyer found'
                  ) : (
                    <Loader error={error} />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(BuyerList);
