import React, { useEffect, useState } from 'react';
import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Container,
  Input,
  Label,
  Tooltip,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import CountUp from 'react-countup';
import SimpleBar from 'simplebar-react';

import BreadCrumb from '../../Components/Common/BreadCrumb';

// There should be 17 of these.
// I've not imported them all beacause I'm not sure that is the right way to do it
import sdg1 from '../../assets/images/sdg/E-WEB-Goal-01.png';
import sdg2 from '../../assets/images/sdg/E-WEB-Goal-02.png';
import sdg3 from '../../assets/images/sdg/E-WEB-Goal-03.png';
import sdg4 from '../../assets/images/sdg/E-WEB-Goal-04.png';
import sdg5 from '../../assets/images/sdg/E-WEB-Goal-05.png';
import sdg6 from '../../assets/images/sdg/E-WEB-Goal-06.png';
import sdg7 from '../../assets/images/sdg/E-WEB-Goal-07.png';
import sdg8 from '../../assets/images/sdg/E-WEB-Goal-08.png';
import sdg9 from '../../assets/images/sdg/E-WEB-Goal-09.png';
import sdg10 from '../../assets/images/sdg/E-WEB-Goal-10.png';
import sdg11 from '../../assets/images/sdg/E-WEB-Goal-11.png';
import sdg12 from '../../assets/images/sdg/E-WEB-Goal-12.png';
import sdg13 from '../../assets/images/sdg/E-WEB-Goal-13.png';
import sdg14 from '../../assets/images/sdg/E-WEB-Goal-14.png';
import sdg15 from '../../assets/images/sdg/E-WEB-Goal-15.png';
import sdg16 from '../../assets/images/sdg/E-WEB-Goal-16.png';
import sdg17 from '../../assets/images/sdg/E-WEB-Goal-17.png';
import { VectorMap } from 'react-jvectormap';

import { productDetailsWidgets, reviews } from '../../common/data/ecommerce';

//import images
import avatar8 from '../../assets/images/users/avatar-8.jpg';
import avatar10 from '../../assets/images/users/avatar-10.jpg';
import avatar6 from '../../assets/images/users/avatar-6.jpg';
import avatar2 from '../../assets/images/users/avatar-2.jpg';
import avatar3 from '../../assets/images/users/avatar-3.jpg';
import avatar4 from '../../assets/images/users/avatar-4.jpg';
import avatar7 from '../../assets/images/users/avatar-7.jpg';
import image4 from '../../assets/images/small/img-4.jpg';
import image5 from '../../assets/images/small/img-5.jpg';

import SwiperCore, { FreeMode, Navigation, Thumbs } from 'swiper';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProject } from '../../store/project/action';

SwiperCore.use([FreeMode, Navigation, Thumbs]);

const SDGImages = {
  1: sdg1,
  2: sdg2,
  3: sdg3,
  4: sdg4,
  5: sdg5,
  6: sdg6,
  7: sdg7,
  8: sdg8,
  9: sdg9,
  10: sdg10,
  11: sdg11,
  12: sdg12,
  13: sdg13,
  14: sdg14,
  15: sdg15,
  16: sdg16,
  17: sdg17,
};

const ProductReview = (props) => {
  return (
    <React.Fragment>
      <li className='py-2'>
        <div className='border border-dashed rounded p-3'>
          <div className='d-flex align-items-start mb-3'>
            <div className='hstack gap-3'>
              <div className='badge rounded-pill bg-success mb-0'>
                <i className='mdi mdi-star'></i> {props?.review?.rating}
              </div>
              <div className='vr'></div>
              <div className='flex-grow-1'>
                <p className='text-muted mb-0'>{props?.review?.comment}</p>
              </div>
            </div>
          </div>
          {props?.review?.subItems && (
            <React.Fragment>
              <div className='d-flex flex-grow-1 gap-2 mb-3'>
                {props.review.subItems.map((subItem, key) => (
                  <React.Fragment key={key}>
                    <Link to='#' className='d-block'>
                      <img
                        src={subItem?.img}
                        alt=''
                        className='avatar-sm rounded object-cover'
                      />
                    </Link>
                  </React.Fragment>
                ))}
              </div>
            </React.Fragment>
          )}

          <div className='d-flex align-items-end'>
            <div className='flex-grow-1'>
              <h5 className='fs-14 mb-0'>{props?.review?.name}</h5>
            </div>

            <div className='flex-shrink-0'>
              <p className='text-muted fs-13 mb-0'>{props?.review?.date}</p>
            </div>
          </div>
        </div>
      </li>
    </React.Fragment>
  );
};

const PricingWidgetList = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} sm={6}>
        <div className='p-2 border border-dashed rounded'>
          <div className='d-flex align-items-center'>
            <div className='avatar-sm me-2'>
              <div className='avatar-title rounded bg-transparent text-success fs-24'>
                <i className={props?.pricingDetails?.icon}></i>
              </div>
            </div>
            <div className='flex-grow-1'>
              <p className='text-muted mb-1'>
                {props?.pricingDetails?.label} :
              </p>
              <h5 className='mb-0'>{props?.pricingDetails?.labelDetail}</h5>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

const Vectormap = (props) => {
  const map = React.createRef(null);
  return (
    <>
      <div style={{ width: props?.width, height: 300 }}>
        <VectorMap
          map={props?.value}
          backgroundColor='transparent'
          ref={map}
          containerStyle={{
            width: '100%',
            height: '70%',
          }}
          regionStyle={{
            initial: {
              // fill: props.color,
              fill: '#AFC19888',
              stroke: 'transparent',
              'stroke-width': 0,
              'stroke-opacity': 0,
            },
            hover: {
              'fill-opacity': 0.5,
              cursor: 'pointer',
            },
            selected: {
              fill: '#DC6B2F', //what colour clicked country will be
            },
            selectedHover: {},
          }}
          series={{
            regions: [
              {
                values: { [props?.country]: 100000 }, //this is your data
                scale: ['#ff0000', '#DC6B2F'], //your color game's here
                normalizeFunction: 'polynomial',
              },
            ],
          }}
          containerClassName='map'
        />
      </div>
    </>
  );
};

function ProjectDetail(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { product_id } = useParams();

  const { project } = useSelector((state) => ({
    project: state.Project.project,
  }));
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [ttop, setttop] = useState(false);

  const [ssize, setssize] = useState(false);
  const [msize, setmsize] = useState(false);
  const [lsize, setlsize] = useState(false);
  const [xlsize, setxlsize] = useState(false);
  const [customActiveTab, setcustomActiveTab] = useState('1');

  const [green, setGreenProgress] = useState();
  const [red, setRedProgress] = useState();

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  useEffect(() => {
    product_id && dispatch(getProject(product_id));
  }, []);

  useEffect(() => {
    let retired_credit = project?.berkeley_details?.total_credits_retired;
    let issued = project?.berkeley_details?.total_credits_issued;
    let remaining = project?.berkeley_details?.total_credits_remaining;
    setGreenProgress((remaining / issued) * 100);
    setRedProgress((retired_credit / issued) * 100);
  }, [red, green]);

  document.title = project.project_name;
  // add Project Name here
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          title='Project Details'
          pageTitle='Projects'
          pageTitleLink='/project/list'
        />

        <Row>
          <Col lg={12}>
            <Card className=''>
              <div
                className=''
                style={{
                  borderColor:
                    (project?.typeid?.typecategory_set &&
                      project?.typeid?.typecategory_set[0]?.categoryid
                        ?.colour) ||
                    '#d4d7d4',
                  borderRadius: '5px',
                  borderWidth: '0 0 0 10px',
                  borderStyle: 'solid',
                  // backgroundColor: project?.typeid?.typecategory_set[0]?.categoryid?.colour || '#d4d7d4',
                  backgroundOpacity: '0.3',
                }}
              >
                <CardBody className='pb-0 px-4'>
                  <Row className='mb-3'>
                    <div className='col-md'>
                      <Row className='align-items-center g-3'>
                        <div className='col-md pb-2'>
                          <div>
                            <h1 className='fw-bold mt-1 mb-3'>
                              {project.project_name || '-'}
                            </h1>

                            <div className='hstack gap-3 flex-wrap'>
                              <div>
                                <i className='ri-building-line align-bottom me-1'></i>
                                {project?.projectstandards_set?.[0]?.standards
                                  .name || '-'}
                              </div>
                              <div className='vr'></div>
                              <span className=''>{project.project_code}</span>
                              <div className='vr'></div>
                              
                              {project?.berkeley_details
                                    ?.first_year_of_project && (<div>
                                First Year :{' '}
                                <span className='fw-medium'>
                                  {project?.berkeley_details
                                    ?.first_year_of_project || '-'}
                                </span>
                              </div>)}
                              <div className='vr'></div>
                              {project?.berkeley_details?.project_owner}
                              {/* <div className="badge rounded-pill bg-danger fs-12">High</div> */}

                              <div
                                className={
                                  project.corsia
                                    ? 'bg-success badge rounded-pill fs-12'
                                    : 'bg-danger badge rounded-pill fs-12' +
                                      ' badge rounded-pill fs-12'
                                }
                              >
                                <i className='ri-plane-line align-middle me-2' />
                                {project.corsia
                                  ? 'CORSIA Eligible'
                                  : 'Not CORSIA eligible'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                    {/* <div className="col-md-auto">
                                        <div className="hstack gap-1 flex-wrap">
                                            <button type="button" className="btn py-0 fs-16 favourite-btn active">
                                                <i className="ri-star-fill"></i>
                                            </button>
                                            <button type="button" className="btn py-0 fs-16 text-body">
                                                <i className="ri-share-line"></i>
                                            </button>
                                            <button type="button" className="btn py-0 fs-16 text-body">
                                                <i className="ri-flag-line"></i>
                                            </button>
                                        </div>
                                    </div> */}
                  </Row>
                </CardBody>
                <div
                  className='text-white py-2'
                  style={{
                    backgroundColor:
                      (project?.typeid?.typecategory_set &&
                        project?.typeid?.typecategory_set[0]?.categoryid
                          ?.colour) ||
                      '#d4d7d4',
                  }}
                >
                  {project?.typeid?.typecategory_set?.map(
                    (single) => `${single?.categoryname}`
                  ) || 'No Category name'}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xl={9} lg={8}>
            <Card>
              <CardBody>
                <div className='text-muted'>
                  <div className='hstack gap-2'>
                    <div className='pe-4  '>
                      <p className='mb-2 text-uppercase fw-medium'>Type :</p>
                      <h5 className='x'>
                        {project?.typeid?.typename || 'None'}
                      </h5>
                    </div>

                    <div className='pe-4 ms-auto'>
                      <p className='mb-2 text-uppercase fw-medium'>
                        Reduction / Removal :
                      </p>
                      <div className='badge bg-info fs-12'>
                        {project?.typeid?.credittypename || 'None'}
                      </div>
                    </div>

                    <div className='ms-auto'>
                      <p className='mb-2 text-uppercase fw-medium'>
                        Methodology :
                      </p>
                      <div className='badge bg-light text-dark fs-12'>
                        {project?.methdology || 'None'}
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>


            <Alert color='warning' className='alert lead border border-3 border-start border-warning'>
              <div className='fs-4 py-1'><strong>Would you like more insights into projects like this one? </strong></div>
              <p>
              Chat with us or
              <a href="mailto:hub@ecosystemmarketplace.com?subject=Richer%20insights%20please" className='px-1 text-decoration-underline'>
              email your account manager 
              </a>
              telling us what you'd like to know, and we'll get back to you ASAP.
              </p>
            </Alert>

            

            {/* <Card className='bg-soft-success'>
              <CardHeader className='align-items-center d-flex bg-warning'>
                <h5 className='card-title mb-0 flex-grow-1 text-white'>
                  Pricing & transactions
                </h5>
              </CardHeader>
              <CardBody className='bg-soft-success'>
                <Row> 
                  <Col sm={6} className='height-100 bg-soft-light'>
                    <div className='p-2 border border-dashed rounded  bg-soft-light'>
                      <div className='d-flex align-items-center'>
                        <div className='avatar-sm me-2'>
                          <div className='avatar-title rounded bg-transparent text-success fs-24'>
                            <i className='ri-exchange-dollar-line'></i>
                          </div>
                        </div>
                        <div className='flex-grow-1'>
                          <p className='text-muted mb-1'>
                            Price Range (TCO2e) :
                          </p>
                          <div className='mt-1'>
                            <Link
                              to='/pages-pricing'
                              className='btn btn-outline-primary btn-sm'
                            >
                              Upgrade to reveal
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} className='height-100'>
                    <div className='p-2 border border-dashed rounded  bg-soft-light'>
                      <div className='d-flex align-items-center'>
                        <div className='avatar-sm me-2'>
                          <div className='avatar-title rounded bg-transparent text-success fs-24'>
                            <i className=' ri-arrow-left-right-line'></i>
                          </div>
                        </div>
                        <div className='flex-grow-1'>
                          <p className='text-muted mb-1'>
                            Transactions history :
                          </p> 
                          <div className='mt-1'>
                            <Link
                              to='/pages-pricing'
                              className='btn btn-outline-primary btn-sm'
                            >
                              Upgrade to reveal
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card> */}

            <Row>
              <Col>
                <Card className='bg-primary card-height-100'>
                  <CardBody>
                    <p className='fw-medium text-white text-uppercase text-opacity-75'>
                      Co-benefits
                    </p>
                    <div className='vstack gap-2 fs-14 lh-base text-white'>
                      <div>
                        {
                          project?.projectcobenefits_set?.[0]?.cobenefitid
                            .cobenefitname
                        }
                        {project?.projectcobenefits_set?.length > 0
                          ? ''
                          : 'No known co-benefits'}
                      </div>
                      <div>
                        {
                          project?.projectcertifications_set?.[0]
                            ?.certificationid.certificationname
                        }
                        {project?.projectcertifications_set?.length > 0
                          ? ''
                          : 'No known certifications'}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card
                  className={
                    project.corsia
                      ? 'bg-success'
                      : 'bg-danger card-height-100'
                  }
                >
                  <CardBody className='p-0'>
                    <div className='d-flex p-3 align-items-center'>
                      <div className='flex-shrink-0'>
                        <div className='avatar-md me-3'>
                          <span className='avatar-title bg-white rounded-circle fs-1'>
                            <i
                              className={
                                project.corsia
                                  ? 'text-success ri-plane-line '
                                  : 'text-danger ri-plane-line '
                              }
                            ></i>
                          </span>
                        </div>
                      </div>

                      <div className=''>
                        <p className='fs-22 mb-0 text-white'>
                          {project.corsia
                            ? 'CORSIA Eligible'
                            : 'Not CORSIA eligible'}
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Card className='card-animate bg-soft-info'>
              <CardBody>
                <div className='d-flex justify-content-between'>
                  <div>
                    <p className='fw-medium mb-0'>ISSUANCES REMAINING ({project?.berkeley_details?.issuances_remaining_parenthetical ? project?.berkeley_details?.issuances_remaining_parenthetical : 'October 2023'})</p>
                    <h2 className='mt-2 ff-secondary fw-semibold display-6'>
                      <span className='counter-value' data-target='3045938'>
                        {project?.berkeley_details?.total_credits_remaining ? (
                          <CountUp
                            start={0}
                            end={
                              project?.berkeley_details
                                ?.total_credits_remaining || '-'
                            }
                            duration={2}
                            separator=','
                          />
                        ) : (
                          '-'
                        )}
                      </span>
                      <span className='text-muted fs-6'> TCO2e </span>
                    </h2>

                    <div className='hstack gap-3'>
                      <div className='mb-1 text-muted'>
                        <span className='badge bg-light text-primary mb-0 fs-12'>
                          <i className='ri-arrow-right-line align-middle'></i>
                          {project?.berkeley_details?.total_credits_issued
                            ? parseInt(
                                project?.berkeley_details?.total_credits_issued
                              )?.toLocaleString()
                            : '-'}{' '}
                        </span>{' '}
                        &nbsp; issued
                      </div>

                      <div className='mb-1 text-muted me-auto'>
                        <span className='badge bg-light text-danger mb-0 fs-12'>
                          <i className='ri-arrow-down-line align-middle'></i>
                          {project?.berkeley_details?.total_credits_retired
                            ? parseInt(
                                project?.berkeley_details?.total_credits_retired
                              )?.toLocaleString()
                            : '-'}
                        </span>{' '}
                        retired
                      </div>

                      <div className='mb-1 text-muted position-absolute end-0 me-3'>
                        <span className='badge bg-light text-dark mb-0 fs-12'>
                          <i className='ri-arrow-down-line align-middle'></i>
                          {parseInt(
                            project?.berkeley_details
                              ?.estimated_annual_emission_reductions
                          )?.toLocaleString() || '-'}
                        </span>{' '}
                        est. annual rate
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className='avatar-sm flex-shrink-0'>
                      <span className='avatar-title bg-info rounded-circle fs-2'>
                        <i className='ri-download-cloud-fill'></i>
                      </span>
                    </div>
                  </div>
                </div>
              </CardBody>
              <div
                className='progress animated-progess rounded-bottom rounded-0'
                style={{ height: '6px' }}
              >
                <div
                  className='progress-bar rounded-0 bg-danger'
                  role='progressbar'
                  // style={{ width: '72%' }}
                  style={{
                    width: `${
                      (project?.berkeley_details?.total_credits_retired /
                        project?.berkeley_details?.total_credits_issued) *
                      100
                    }%`,
                  }}
                  aria-valuenow='20'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
                <div
                  className='progress-bar rounded-0 bg-success'
                  role='progressbar'
                  style={{
                    width: `${
                      (project?.berkeley_details?.total_credits_remaining /
                        project?.berkeley_details?.total_credits_issued) *
                      100
                    }%`,
                  }}
                  aria-valuenow='80'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
            </Card>

            <Card>
              <CardHeader>
                <h5 className='card-title'>Sustainable Development Goals</h5>
              </CardHeader>
              <CardBody>
                <div className=''>
                  {project?.projectsdgs_set?.length > 0 ? (
                    project?.projectsdgs_set?.map((el) => {
                      return (
                        el.sdgs.sdgcode && (
                          <span key={`sdg${el.sdgs.sdgcode}`}>
                            <img
                              src={SDGImages[el.sdgs.sdgcode]}
                              className='rounded avatar-lg me-1 mb-1'
                              id={`sdg${el.sdgs.sdgcode}`}
                              alt='SDG Name'
                            />
                            <UncontrolledTooltip
                              placement='top'
                              target={`sdg${el.sdgs.sdgcode}`}
                            >
                              {el.sdg_name}
                            </UncontrolledTooltip>
                          </span>
                        )
                      );
                    })
                  ) : (
                    <p>
                      This project has not been assessed for Sustainable
                      Development Goals.
                    </p>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl={3} lg={4}>
            <Card>
              <CardBody>
                <h5 className='card-title mb-4'>
                  Location: {project?.country?.name}
                </h5>
                <div id='usa-vectormap' style={{ height: '200px' }}>
                  <Vectormap
                    value='world_mill'
                    width='724'
                    color='#695eef'
                    country={project?.country?.iso2 || ''}
                  />
                </div>
              </CardBody>
            </Card>

            <Card>
              <CardHeader className='align-items-center d-flex border-bottom-dashed'>
                <h5 className='card-title mb-0 flex-grow-1'>Links</h5>
              </CardHeader>

              <CardBody>
                <div className='vstack gap-2'>
                  <div className='border rounded border-dashed p-2'>
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0 me-3'>
                        <div className='avatar-sm'>
                          <div
                            className={`avatar-title bg-light ${
                              project?.berkeley_details?.registry_documents
                                ? 'text-secondary'
                                : ''
                            } rounded fs-24`}
                          >
                            <i className='ri-folder-zip-line'></i>
                          </div>
                        </div>
                      </div>
                      <div className='flex-grow-1 overflow-hidden'>
                        <h5 className='fs-13 mb-1'>
                          {project?.berkeley_details?.registry_documents ? (
                            <a
                              href={
                                project?.berkeley_details?.registry_documents ||
                                '#'
                              }
                              target='_blank'
                              rel='noreferrer'
                              className={`${
                                project?.berkeley_details?.registry_documents
                                  ? 'text-secondary'
                                  : 'text-body'
                              } text-truncate d-block`}
                            >
                              Registry
                            </a>
                          ) : (
                            <a
                              className={`${'text-body'} text-truncate d-block`}
                            >
                              Registry
                            </a>
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className='border rounded border-dashed p-2'>
                    <div className='d-flex align-items-center'>
                      <div className='flex-shrink-0 me-3'>
                        <div className='avatar-sm'>
                          <div
                            className={`avatar-title bg-light ${
                              project?.berkeley_details?.project_websiteberkeley_details
                                ? 'text-secondary'
                                : ''
                            } rounded fs-24`}
                          >
                            <i className='ri-link'></i>
                          </div>
                        </div>
                      </div>
                      <div className='flex-grow-1 overflow-hidden'>
                        <h5 className='fs-13 mb-1'>
                          {project?.berkeley_details?.project_website ? (
                            <a
                              href={
                                project?.berkeley_details?.project_website || ''
                              }
                              target='_blank'
                              rel='noreferrer'
                              className={`${
                                project?.berkeley_details?.project_website
                                  ? 'text-secondary'
                                  : 'text-body'
                              } text-truncate d-block`}
                            >
                              Project Website
                            </a>
                          ) : (
                            <a
                              className={`${'text-body'} text-truncate d-block`}
                            >
                              Project Website
                            </a>
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ProjectDetail;
