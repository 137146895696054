import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

//Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout';
import VerticalLayout from '../Layouts/index';
//routes
import { authProtectedRoutes, marketDataRoutes, publicRoutes } from './allRoutes';
import { AuthProtected, AccessRoute } from './AuthProtected';
import { useProfile } from '../Components/Hooks/UserHooks';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAllCookies, getCookie } from '../common/helper';
import { loginUser, logoutUser } from '../store/actions';
import Spinner from '../Components/Common/Spinner';

const Index = () => {
  const [isNotFound, setIsNotFound] = useState(false);
  const dispatch = useDispatch();
  const { Login } = useSelector((state) => state);
  const { userProfile, loading, token } = useProfile();
  const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
  const availableMarketDataPaths = marketDataRoutes.map((r) => r.path);
  const availableAuthRoutesPaths = authProtectedRoutes.map((r) => r.path);
  const isUserAuth = token || userProfile;


  // CHAD AUTH  - here
  useEffect(() => {
      const token = JSON.parse(sessionStorage.getItem("authUser"))
        ? JSON.parse(sessionStorage.getItem("authUser")).access_token
        : null;
      if ( ! token) {
        deleteAllCookies();
        dispatch(logoutUser());
        setIsNotFound(true);
      } 
  }, []);

  let isViewer = false;
  if( Login?.user?.role == 3 ){
    isViewer = true;
  }

  let authorizedPaths = availableAuthRoutesPaths;
  let authorizedRoutes = authProtectedRoutes;
  if( Login?.user?.respondent_has_data_and_pricing_access === true || Login?.user?.has_data_and_pricing_access === true || isViewer ){
    authorizedRoutes = [...authProtectedRoutes, ...marketDataRoutes, ...publicRoutes];
    authorizedPaths = [...availableAuthRoutesPaths, ...availableMarketDataPaths, ...availablePublicRoutesPaths];
  } 

  return (
    <React.Fragment>
      {Login?.loading ? (
        <Spinner />
      ) : !isUserAuth ? (
        <Route path={availablePublicRoutesPaths}>
          <NonAuthLayout>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <Route
                  path={route.path}
                  component={route.component}
                  key={'public' + idx}
                  exact={true}
                />
              ))}
              <Route path='*'>
                {isNotFound && <Redirect to='/page-not-found' />}
              </Route>
            </Switch>
          </NonAuthLayout>
        </Route>
      ) : (
        <Route path={authorizedPaths}>
          <AuthProtected>
            <VerticalLayout>
              <Switch>
                {authorizedRoutes.map((route, idx) => (
                  <AccessRoute
                    path={route.path}
                    component={route.component}
                    key={'protected' + idx}
                    exact={true}
                  />
                ))}
                <Route path='*'>
                  {isNotFound && <Redirect to='/page-not-found' />}
                </Route>
              </Switch>
            </VerticalLayout>
          </AuthProtected>
        </Route>
      )}
    </React.Fragment>
  );
};

export default Index;
