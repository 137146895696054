import React, { useEffect, useState } from 'react';
import { URL } from '../../../constants';
import axios from 'axios';
import { Col, Row } from 'reactstrap'; 
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const VolumeByCategoryAndYearStackedBarChartPublic = () => {

    const [dataReady, setDataReady] = useState(false);

    const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
        'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other', 'Unspecified'];
    const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

    

    let initialBarChartOptions = {
        colors: colors,
        chart: {
            type: 'column',
            height: '600',
            marginTop: '80',
        },
        title: {
            text: 'Volume (tCO2e) by Category and Year',
            align: 'left'
        },
        subtitle: {
            text: 'Click on categories to show/hide them',
            align: 'left'
        },
        xAxis: {
            categories: [2020, 2021, 2022, 2023]
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Volume (tCO2e)'
            },
            stackLabels: {
                enabled: true
            }
        },
        legend: {
            align: 'left',
            x: 70,
            verticalAlign: 'top',
            y: 40,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false,

        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal:,.0f}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: false
                }
            }
        },
        series: [],
        }

    const [stackedBarChartOptions, setStackedBarChartOptions] = useState(initialBarChartOptions);

    useEffect(() => {
        axios.get(URL.get_volume_and_vwap_by_category).then((response) => {
            let volumeAndVWAPByCategory = response.data;
            volumeAndVWAPByCategory = volumeAndVWAPByCategory.reverse();

            const categories = ['Agriculture', 'Chemical Proc. / Industrial Mfg.', 'Energy Efficiency / Fuel Switching', 
                'Forestry and Land Use',  'Household / Community Devices', 'Renewable Energy', 'Transportation', 'Waste Disposal', 'Other', 'Unspecified'];
            const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];

            let years = [];
            volumeAndVWAPByCategory.forEach((d) => {
                if (!years.includes(d.year_transact)) {
                    years.push(d.year_transact);
                }
            });

            
            let volume_by_year_and_category = {};
        
            years.forEach((year) => {
                
                volume_by_year_and_category[year] = {};
                categories.forEach((category) => {
                    
                    volume_by_year_and_category[year][category] = 0;
                });
                volumeAndVWAPByCategory.forEach((d) => {
                    if (d.year_transact === year) {
                        
                        volume_by_year_and_category[year][d.project_category] += d.volume_tco2e;
                    }
                });
            });

            
            let volumeBarChartData = [];

            categories.forEach((category) => {
                
                let volume_obj = {name: category, data: []};
                years.forEach((year) => {
                    
                    volume_obj.data.push(volume_by_year_and_category[year][category]);
                });
                
                volumeBarChartData.push(volume_obj);
            });


            
            //setVolumeSeriesData(volumeBarChartData);
            setStackedBarChartOptions({ ...stackedBarChartOptions, series: volumeBarChartData });
            setDataReady(true);
            
            
            
        });
    }, []);

    
    
    
        
        
    

            



    return (
        <React.Fragment>

            
            
            

            <Row>
                <Col className="col-12 col-lg-12"><div id="sbc">
                {dataReady && <HighchartsReact
                    highcharts={Highcharts}
                    options={stackedBarChartOptions}
                />}</div>
                </Col>
            </Row>

            
        </React.Fragment>
    );


}

export default VolumeByCategoryAndYearStackedBarChartPublic;