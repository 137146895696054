import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { PATH_TRADE, PATH_BUYER, PATH_PROJECT, PATH_RESPONDENT } from "../Routes/paths";


const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  
  const [isLanding, setIsLanding] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  const { Login } = useSelector((state) => state);

  
  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "BaseUi") {
      setIsBaseUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    if (iscurrentState === "Widgets") {
      history.push("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
  ]);

  let menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "home",
      label: "Home",
      icon: "ri-home-line",
      link: "/landing", 
    },
    {
        id: "categories",
        label: "Categories",
        icon: "ri-pie-chart-2-line",
        link: "/categories", 
    },
    {
        id: "taxonomy",
        label: "EM Taxonomy",
        icon: "ri-layout-column-line",
        link: "/taxonomy", 
    },
    {
        id: "standards",
        label: "Standards",
        icon: "ri-bar-chart-grouped-fill",
        link: "#", 
        className: "respondents-only",
        tooltip: "This feature is only available to registered users.",
    },
    {
        id: "projects",
        label: "Projects",
        icon: " ri-archive-line",
        link: "/projects", 
        
    },

    {
      id: "respondent-data",
      label: "Respondent Data",
      icon: "ri-shield-user-line",
      link: "#",
      className: "respondents-only",
      
      subItems: [ 
        {
          id: "my-respondent-data",
          label: "My Data",
          link: "/RespondentData-YourData", 
          parentId: "resources",
          className: "respondents-only",
          tooltip: "This feature is only available to registered users.",
        },
        {
          id: "trades",
          label: "Trades",
          icon: "ri-exchange-dollar-line",
          link: "/RespondentData-Trades", 
          parentId: "respondent-data",
          className: "respondents-only",
          tooltip: "This feature is only available to registered users.",
        },
        
        {
          id: "buyers",
          label: "Buyers",
          icon: "ri-shield-user-line",
          link: "/RespondentData-Buyers", 
          parentId: "respondent-data",
          className: "respondents-only",
          tooltip: "This feature is only available to registered users.",
        },
        
      ],
    },
    
    {
      id: "dataDashboard",
      label: "Supporter Hub",
      icon: "ri-line-chart-fill",
      link: "/SupportersOnly", 
      className: "respondents-only",
      tooltip: "This feature is only available to our supporters.",
    },

    {
      id: "resources",
      label: "Resources",
      icon: "ri-shield-user-line",
      link: "#",
      subItems: [ 
        {
          id: "legal",
          label: "Terms of Use/Privacy Policy",
          link: "/Resources/TermsAndPrivacy", 
          parentId: "resources",
          
        },
        {
          id: "demos",
          label: "Help Center",
          link: "/Resources/HelpCenter", 
          parentId: "resources",
          
        },
        {
          id: "requestaccess",
          label: "Contact Us",
          link: "/requestaccess", 
          parentId: "resources",
          
        },
      ],
    },


    {
      id: "about",
      label: "About",
      icon: "ri-shield-user-line",
      link: "/About/AboutTheHub",
      subItems: [ 
        {
          id: "about-us",
          label: "About Us",
          link: "/About/AboutUs", 
          parentId: "about",
          
        },
        {
          id: "our-supporters",
          label: "Our Supporters",
          link: "/About/OurSupporters", 
          parentId: "about",
          
        },
        {
          id: "respondents-list",
          label: "List of Respondents",
          link: "/About/ListOfRespondents", 
          parentId: "about",
          
        },
      ],
    },
    


    

    
    
  ];

  
    

  

  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
