import React from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 











const DemosAndFAQsPublic = () => {


    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
                <CardHeader className="align-items-center d-flex">
                Demos & FAQs
                </CardHeader>
                <CardBody>
                Content here.
                    
                </CardBody>
                
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default DemosAndFAQsPublic;
