import React from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 
import { useSelector } from 'react-redux';



import StandardsVolumeByCategory from '../../Charts/components/CategoriesStandards-StackedBarChart';
import StandardsVolumeTotals from '../../Charts/components/StandardsTotalVolume-BarChart';







const StandardsPublic = () => {

    const { Login } = useSelector((state) => state);

    console.log('Login',Login);

    const isLoggedIn = Login?.user?.email ? true : false;
    console.log('isLoggedIn',isLoggedIn);

    const notLoggedIn = !isLoggedIn;
    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>

            

                {isLoggedIn && (
                    <>
                    <CardHeader className="align-items-center d-flex">
                    <h4>Standards Data</h4>
                    </CardHeader>
                    
                    <CardBody className="p-0">
                        <StandardsVolumeTotals />
                    </CardBody>
                    <CardBody className="p-0">
                        <StandardsVolumeByCategory />
                    </CardBody>
                
                    </>
                )}

                {notLoggedIn && (
                    <>
                        <CardHeader className="align-items-center d-flex">
                        <h4>Message to Non-Users</h4>
                        </CardHeader>


                        
                        <CardBody className="p-3">
                            <p>
                            The standards section is only available to registered users. Please log in or register to access this section.
                            </p>
                        </CardBody>
                    </>
                )}

                
                
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default StandardsPublic;
