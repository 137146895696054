import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
  UncontrolledCollapse,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import axios from 'axios';

//Import Breadcrumb
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import { getProjectList, deleteProject } from '../../../store/actions';
import ProjectForm from '../../Project/components/ProjectForm';
import { api } from '../../../config';

const ProjectList = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const projectsPerPage = 20;

  const {
    user,
    projects,
    projectNumPages,
    projectNext,
    projectPrev,
    projectLoading,
    error,
    isProjectSuccess,
    isProjectCreated,
    projectListCount,
  } = useSelector((state) => ({
    user: state.Login.user,
    projects: state.Project.projectList.slice(1),
    //projects: getProjectList({ limit: 20, offset: 0, exclude_noid: '1'}),
    projectLoading: state.Project.projectLoading,
    projectNext: state.Project.projectNext,
    projectPrev: state.Project.projectPrev,
    projectListCount: state.Project.projectListCount,
    projectNumPages: state.Project.projectListNumPages,
    isProjectCreated: state.Project.isProjectCreated,
    isProjectSuccess: state.Project.isProjectSuccess,
    error: state.Project.error,
  }));

  let projectListInitializing = true;
  let initialProjects = [];
  

  const [projectList, setProjectList] = useState([]);
  const [projectCount, setProjectCount] = useState(0);
  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesListType, setCategoriesListType] = useState([]);
  const [projectSdgs, setProjectSdgs] = useState([]);
  const [projectStandards, setProjectStandards] = useState([]);
  const [projectCerts, setProjectCerts] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentProject, setCurrentProject] = useState(null);
  const [cate, setCate] = useState([]);
  const [projectSdg, setProjectSdg] = useState([]);
  const [projectStandard, setProjectStandard] = useState([]);
  const [projectCert, setProjectCert] = useState([]);
  const [corsia, setCorsia] = useState(false);
  const [certifications, setCertifications] = useState([]);
  const [cobenefits, setCobenefits] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectId, setProjectId] = useState('');
  const [projectCountry, setProjectCountry] = useState('');
  const [projectType, setProjectType] = useState('');
  const [projectCoBenefits, setProjectCoBenefits] = useState([]);
  const [projectMethdology, setProjectMethdology] = useState('');
  const [projectCertification, setProjectCertification] = useState([]);
  const [loading, setLoading] = useState(false);


  const AdditionalCertifications = [
    { label: 'American Tree Farm System', value: 17 },
    { label: 'Forest Stewardship Council', value: 15 },
    { label: 'Programme for the Endorsement of Forest Certification (PEFC)', value: 20 },
    { label: 'Sustainable Forestry Initiative', value: 6 },
  ];

  

  const clearFilter = () => {
    setProjectName('');
    setProjectId('');
    setProjectCountry('');
    setProjectList(projects);
    setProjectCount(projectListCount);
    setCurrentPage(1);
    setCate([]);
    setProjectStandard([]);
    setProjectSdg([]);
    setProjectCert([]);
    setProjectMethdology('');
    setProjectCertification([]);
    setProjectCoBenefits([]);
    
  };

  const filterByCategories = (value) => {
    let filterVal = [];
    if (cate?.some((cat) => +cat === +value)) {
      filterVal = cate?.filter((cat) => +cat !== +value);
    } else {
      filterVal = [...cate, +value];
    }
    setProjectName('');
    setProjectId('');
    setProjectCountry('');
    setProjectStandard([]);
    setProjectSdg([]);
    setProjectCert([]);
    setCate(filterVal);
    let typeObj = categoriesListType.filter((category) =>
      filterVal.includes(+category?.categoryid)
    );
    if (typeObj.length <= 0 && filterVal?.length === 0)
      return setProjectList(projects);
    if (typeObj.length <= 0) return setProjectList([]);
    let data = [];

    /* API CALL - get all products that have any of the typeids in the typeObj array */
    console.log(typeObj.map((type) => type.typeid));
    let typeidArr = typeObj.map((type) => type.typeid);
    let typeidList = typeidArr.join(',');
    console.log(typeidList);
    axios.get(`/api/data/get_projectlist?types=${typeidList}`).then((res) => {
      console.log(res);
      data = res;
      if (data?.length === 0 && filterVal?.length === 0)
        return setProjectList(projects);
      
      setProjectList(data.data);
      setProjectCount(data.count);
    });
    // projects?.map((project) => {
    //   typeObj?.map((filter) => {
    //     if (+project?.typeid === +filter?.typeid) {
    //       data?.push(project);
    //     }
    //   });
    // });
    
  };

  const filterByCertifications = (value) => {
    let filterVal = [];
    console.log(value);
    if (projectCert?.some((cert) => cert === value)) {
      filterVal = projectCert?.filter((cert) => cert !== value);
    } else {
      filterVal = [...projectCert, value];
    }
    console.log(filterVal);
    setProjectName('');
    setProjectId('');
    setProjectCountry('');
    setCate([]);
    setProjectStandard([]);
    setProjectCert(filterVal);
    setProjectSdg([]);
    
    if (!value) {
      setProjectCount(projectListCount);
      setCurrentPage(1);
      return setProjectList(projects);
    }
    // query for projects with all sdgs in filterVal
    let queryURL = buildCurrentQueryURL({certs: filterVal});
    console.log(queryURL);
    setLoading(true);
    axios.get(`${queryURL}`).then((data) => {
      setLoading(false);
      console.log(data);
      if (data?.length === 0 && filterVal?.length === 0){
        setProjectCount(projectsPerPage);
        setCurrentPage(1);
        setProjectList(projects);
      } else {
        setProjectList(data.data);
        setProjectCount(data.count);
      }
    });

  }

  const filterBySdgs = (value) => {
    let filterVal = [];
    value = value.replace('sdg', '');
    console.log(value);
    if (projectSdg?.some((sdg) => sdg === value)) {
      filterVal = projectSdg?.filter((sdg) => sdg !== value);
    } else {
      filterVal = [...projectSdg, value];
    }
    console.log(filterVal);
    // setProjectName('');
    // setProjectId('');
    // setProjectCountry('');
    setCate([]);
    setProjectStandard([]);
    setProjectSdg(filterVal);
    if (!value) {
      setProjectCount(projectListCount);
      setCurrentPage(1);
      return setProjectList(projects);
    }
    // query for projects with all sdgs in filterVal
    let queryURL = buildCurrentQueryURL({sdgs: filterVal});
    console.log(queryURL);
    setLoading(true);
    axios.get(`${queryURL}`).then((data) => {
      setLoading(false);
      console.log(data);
      if (data?.length === 0 && filterVal?.length === 0){
        setProjectCount(projectsPerPage);
        setCurrentPage(1);
        setProjectList(projects);
      } else {
        setProjectList(data.data);
        setProjectCount(data.count);
      }
    });

    // projects?.map((project) => {
    //   project.sdgs?.filter((sdg) => {
    //     if (filterVal?.includes(sdg)) {
    //       data?.push(project);
    //     }
    //   });
    // });
    // if (data?.length === 0 && filterVal?.length === 0)
    //   return setProjectList(projects);
    // let uniqueArr = [...new Set(data)];
    // setProjectList(uniqueArr);
  };

  const filterByStandards = (value) => {
    let filterVal = [];
    if (projectStandard?.some((standard) => standard === value)) {
      filterVal = projectStandard?.filter((standard) => standard !== value);
    } else {
      filterVal = [...projectStandard, value];
    }
    setProjectName('');
    setProjectId('');
    setProjectCountry('');
    setCate([]);
    setProjectSdg([]);
    setProjectCert([]);
    setProjectStandard(filterVal);
    if (!value) return setProjectList(projects);
    let data = [];
    projects?.map((project) => {
      project.standards?.map((standard) => {
        if (filterVal?.includes(standard)) {
          data?.push(project);
        }
      });
    });
    if (data?.length === 0 && filterVal?.length === 0)
      return setProjectList(projects);
    let uniqueArr = [...new Set(data)];
    setProjectList(uniqueArr);
  };

  const buildCurrentQueryURL = (valuesObj) => {
    console.log('projectSDG', projectSdg);
    let currentQuery = '/api/data/get_projectlist?exclude_noid=1&orderby=project_code';
    if (projectName) {
      currentQuery += `&project_name=${projectName}`;
    }
    if (projectId) {
      currentQuery += `&project_code=${projectId}`;
    }
    if (projectCountry) {
      currentQuery += `&country_name=${projectCountry}`;
    }
    if (projectType) {
      currentQuery += `&project_type=${projectType}`;
    }
    if (projectCoBenefits?.length > 0) {
      currentQuery += `&cobenefits=${projectCoBenefits.join(',')}`;
    }
    if (projectMethdology) {
      currentQuery += `&methodology=${projectMethdology}`;
    }
    if (projectCertification) {
      currentQuery += `&certification=${projectCertification}`;
    }
    if (valuesObj?.certs?.length > 0) {
      currentQuery += `&certs=${valuesObj.certs.join(',')}`;
    } else if( projectCert?.length > 0 ){
      currentQuery += `&certs=${projectCert.join(',')}`;
    }
    if( valuesObj?.sdgs?.length > 0) {
      currentQuery += `&sdgs=${valuesObj.sdgs.join(',')}`;
    } else if( projectSdg?.length > 0 ){
      currentQuery += `&sdgs=${projectSdg.join(',')}`;
    }
    return currentQuery;
  };

  const paginationInput = useRef(null);

  const getPage = (page) => {
    if( Number.isInteger(parseInt(page)) && parseInt(page) > 0 ){
      if( paginationInput.current?.value ){
        paginationInput.current.value = parseInt(page);
      }
      let offset = (page - 1)*projectsPerPage;
      let currentQueryURL = buildCurrentQueryURL();
      console.log('currentQueryURL', currentQueryURL);
      if ((page - 1)*projectsPerPage < projectCount) {
        setLoading(true);
        axios.get(`${currentQueryURL}&limit=20&offset=${offset}`).then((data) => {
          setLoading(false);
          console.log(data);
          setProjectCount(data.count);
          setCurrentPage(page);
          setProjectList(data.data); 
        });
      }
    }
  };

  const getTopfilterProjects = () => {
    if (!projectName && !projectId && !projectCountry && !projectType) {
      console.log(initialProjects);
      //setProjectCount(projectListCount);
      //setCurrentPage(1);
      //setProjectList(projects);
      getPage(1);
    } else {
      // get a list of all the projects that match the search criteria
      setLoading(true);
      getPage(1);
      // axios.get(`/api/data/get_projectlist?exclude_noid=1&orderby=project_code&project_type=${projectType}&project_name=${projectName}&project_code=${projectId}&country_name=${projectCountry}`).then((data) => {
      //   setLoading(false);
      //   console.log(data);
      //   setProjectCount(data.count);
      //   setCurrentPage(1);
      //   setProjectList(data.data);
      // });
    }
    // let data = [];
    // projects?.map((project) => {
    //   if (projectName && projectId && projectCountry) {
    //     if (
    //       project?.project_name
    //         ?.toString()
    //         ?.toLowerCase()
    //         ?.includes(projectName?.toString()?.toLowerCase()) &&
    //       project?.project_code
    //         ?.toString()
    //         ?.toLowerCase()
    //         ?.includes(projectId?.toString()?.toLowerCase()) &&
    //       project?.country_name
    //         ?.toString()
    //         ?.toLowerCase()
    //         ?.includes(projectCountry?.toString()?.toLowerCase())
    //     ) {
    //       data?.push(project);
    //     }
    //   } else if (projectName && projectId) {
    //     if (
    //       project?.project_name
    //         ?.toString()
    //         ?.toLowerCase()
    //         ?.includes(projectName?.toString()?.toLowerCase()) &&
    //       project?.project_code
    //         ?.toString()
    //         ?.toLowerCase()
    //         ?.includes(projectId?.toString()?.toLowerCase())
    //     ) {
    //       data?.push(project);
    //     }
    //   } else if (projectName && projectCountry) {
    //     if (
    //       project?.project_name
    //         ?.toString()
    //         ?.toLowerCase()
    //         ?.includes(projectName?.toString()?.toLowerCase()) &&
    //       project?.country_name
    //         ?.toString()
    //         ?.toLowerCase()
    //         ?.includes(projectCountry?.toString()?.toLowerCase())
    //     ) {
    //       data?.push(project);
    //     }
    //   } else if (projectId && projectCountry) {
    //     if (
    //       project?.project_code
    //         ?.toString()
    //         ?.toLowerCase()
    //         ?.includes(projectId?.toString()?.toLowerCase()) &&
    //       project?.country_name
    //         ?.toString()
    //         ?.toLowerCase()
    //         ?.includes(projectCountry?.toString()?.toLowerCase())
    //     ) {
    //       data?.push(project);
    //     }
    //   } else if (projectId) {
    //     if (
    //       project?.project_code
    //         ?.toString()
    //         ?.toLowerCase()
    //         ?.includes(projectId?.toString()?.toLowerCase())
    //     ) {
    //       data?.push(project);
    //     }
    //   } else if (projectCountry) {
    //     if (
    //       project?.country_name
    //         ?.toString()
    //         ?.toLowerCase()
    //         ?.includes(projectCountry?.toString()?.toLowerCase())
    //     ) {
    //       data?.push(project);
    //     }
    //   } else if (projectName) {
    //     if (
    //       project?.project_name
    //         ?.toString()
    //         ?.toLowerCase()
    //         ?.includes(projectName?.toString()?.toLowerCase())
    //     ) {
    //       data?.push(project);
    //     }
    //   }
    // });
    
  };

  const afterAddProject = () => {
    dispatch(getProjectList({ search: searchTerm, skip: 0 }));
  };

  const handleCorsia = () => {
    setCorsia(!corsia);
    if (corsia) return setProjectList(getTopfilterProjects());
    setProjectList(projectList?.filter((project) => project?.corsia === true));
  };

  const filterMethdology = (value) => {
    setProjectName('');
    setProjectId('');
    setProjectCountry('');
    setCate([]);
    setProjectStandard([]);
    setProjectSdg([]);
    setProjectMethdology(value);
    setProjectCertification([]);
    setProjectCoBenefits([]);
    if (!value) return setProjectList(projects);
    let data = [];
    projects?.map((project) => {
      if (project?.methdology?.includes(value)) data?.push(project);
    });
    setProjectList(data);
  };

  const filterCoBenefits = (value) => {
    let filterVal = [];
    if (projectCoBenefits?.some((benefit) => benefit === value)) {
      filterVal = projectCoBenefits?.filter((cobenefit) => cobenefit !== value);
    } else {
      filterVal = [...projectCoBenefits, value];
    }
    setProjectName('');
    setProjectId('');
    setProjectCountry('');
    setCate([]);
    setProjectStandard([]);
    setProjectSdg([]);
    setProjectMethdology('');
    setProjectCertification([]);
    setProjectCoBenefits(filterVal);
    if (!value) return setProjectList(projects);
    let data = [];
    projects?.map((project) => {
      project.cobenefits?.map((cobenefit) => {
        filterVal?.map((filter) => {
          if (cobenefit === filter) data?.push(project);
        });
      });
    });
    if (data?.length === 0 && filterVal?.length === 0)
      return setProjectList(projects);
    setProjectList(data);
  };

  const filterCertifications = (value) => {
    let filterVal = [];
    if (projectCertification?.some((benefit) => benefit === value)) {
      filterVal = projectCertification?.filter(
        (cobenefit) => cobenefit !== value
      );
    } else {
      filterVal = [...projectCertification, value];
    }
    //setProjectName('');
    //setProjectId('');
    //setProjectCountry('');
    //setCate([]);
    setProjectStandard([]);
    //setProjectSdg([]);
    setProjectMethdology('');
    setProjectCertification(filterVal);
    setProjectCoBenefits([]);
    if (!value) return setProjectList(projects);
    let data = [];
    projects?.map((certification) => {
      certification.certifications?.map((certification) => {
        filterVal?.map((filter) => {
          if (certification === filter) data?.push(certification);
        });
      });
    });
    if (data?.length === 0 && filterVal?.length === 0)
      return setProjectList(projects);
    setProjectList(data);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getTopfilterProjects();
    }, 300)
    return () => clearTimeout(delayDebounceFn)
  }, [projectName, projectId, projectCountry, projectType]);

  const getCertifications = async () => {
    try {
      const result = await axios.get(`${api.API_URL}/api/certifications/`);
      setCertifications(result?.results);
    } catch (error) {
      console.log({ error });
    }
  };

  const getCobenefits = async () => {
    try {
      const result = await axios.get(`${api.API_URL}/api/cobenefit/`);
      setCobenefits(result?.results);
    } catch (error) {
      console.log({ error });
    }
  };

  const getCategories = async () => {
    try {
      const result = await axios.get(`${api.API_URL}/api/category/`);
      setCategoriesList(result?.results);
    } catch (error) {
      console.log({ error });
    }
  };

  const getTypeCategories = async () => {
    try {
      const result = await axios.get(`${api.API_URL}/api/type-category/?limit=200`);
      setCategoriesListType(result?.results);
    } catch (error) {
      console.log({ error });
    }
  };

  const getProjectStandards = async () => {
    try {
      const result = await axios.get(`${api.API_URL}/api/standards/`);
      let data = [];
      result?.results?.map((standard) => {
        if (standard?.name && standard?.shortname) {
          data.push(standard);
        }
      });
      setProjectStandards(data);
    } catch (error) {
      console.log({ error });
    }
  };

  const getProjectSdgs = async () => {
    try {
      const result = await axios.get(`${api.API_URL}/api/data/projectsdgs`);
      setProjectSdgs(result);
    } catch (error) {
      console.log({ error });
    }
  };

  // Project Form Modal
  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCurrentProject(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  
  // useEffect(() => {
  //   setProjectList(projects ? projects : []);
  //   setProjectCount(projectListCount ? projectListCount : 0);
  // }, [projects]);

  useEffect(() => {
    setLoading(true);
    axios.get(`/api/data/get_projectlist?exclude_noid=1&orderby=project_code`).then((data) => {
      console.log(data);
      setLoading(false);
      setProjectCount(data.count);
      setCurrentPage(1);
      setProjectList(data.data);
      setProjectCerts(AdditionalCertifications);
      initialProjects = data.data;
      projectListInitializing = false;
    });
  }, []);


  useEffect(() => {
    dispatch(getProjectList({ search: searchTerm, skip: 0 }));
  }, [dispatch, searchTerm]);


  // // Column
  // const columns = useMemo(() => {
  //   let result = [
  //     {
  //       Header: 'Code',
  //       accessor: 'project_code',
  //       filterable: false,
  //       Cell: (cell) => {
  //         return (
  //           <Link
  //             to={`/project/${cell?.row?.original?.projectid}`}
  //             className='fw-medium link-primary'>
  //             {cell.value}
  //           </Link>
  //         );
  //       },
  //     },
  //     {
  //       Header: 'Name',
  //       accessor: 'project_name',
  //       filterable: false,
  //       cellStyle: {
  //         overflow: 'hidden',
  //         textOverflow: 'ellipsis',
  //       },
  //       Cell: (cell) => {
  //         return (
  //           <Link
  //             to={`/project/${cell?.row?.original?.projectid}`}
  //             className='fw-medium link-primary'>
  //             {cell.value}
  //           </Link>
  //         );
  //       },
  //     },
  //     {
  //       Header: 'Type',
  //       accessor: 'typeid_name',
  //       filterable: false,

  //       size: 50,
  //       minSize: 20,
  //       // maxSize: 100,
  //       cellStyle: {
  //         overflow: 'hidden',
  //         textOverflow: 'ellipsis',
  //       },
  //     },
  //     // {
  //     //   Header: 'Methodology',
  //     //   accessor: 'methdology',
  //     //   filterable: false,
  //     //   cellStyle: {
  //     //     overflow: 'hidden',
  //     //     textOverflow: 'ellipsis',
  //     //   },
  //     // },
  //     {
  //       Header: 'Country',
  //       accessor: 'country_name',
  //       cellStyle: {
  //         overflow: 'hidden',
  //         textOverflow: 'ellipsis',
  //       },
  //     },
  //   ];
  //   if (user?.is_superuser)
  //     result?.push({
  //       Header: 'Action',
  //       Cell: (cellProps) => {
  //         return (
  //           <ul className='list-inline hstack gap-2 mb-0'>
  //             <li className='list-inline-item edit'>
  //               <Link
  //                 to={`/project/edit/${cellProps?.row?.original?.projectid}`}
  //                 className='text-primary d-inline-block edit-item-btn'>
  //                 <i className='ri-pencil-fill fs-16'></i>
  //               </Link>
  //             </li>
  //             {/* <li className='list-inline-item'>
  //               <Link
  //                 to='#'
  //                 className='text-danger d-inline-block remove-item-btn'
  //                 onClick={() => {
  //                   const row = cellProps.row.original;
  //                   console.log(row);
  //                   onClickDelete(row);
  //                 }}>
  //                 <i className='ri-delete-bin-5-fill fs-16'></i>
  //               </Link>
  //             </li> */}
  //           </ul>
  //         );
  //       },
  //     });
  //   return result;
  // }, []);

  // Update Data
  const handleCustomerClick = useCallback(
    (arg) => {
      const project = arg;
      setCurrentProject({
        projectid: project.projectid,
        project_code: project.project_code,
        project_name: project.project_name,
        typeid: project.typeid.typeid,
        methdology: project.methdology,
        corsia: project.corsia,
        country: project.country.id,
      });

      toggle();
    },
    [toggle]
  );

  // Delete Data
  const onClickDelete = (buyer) => {
    setCurrentProject(buyer);
    setDeleteModal(true);
  };

  const onClosed = () => {
    setCurrentProject(null);
  };

  // Delete Data
  const handleDeleteTask = () => {
    if (currentProject) {
      dispatch(deleteProject(currentProject));
      setDeleteModal(false);
      setCurrentProject(null);
    }
  };

  const getRowId = (row, relativeIndex, parent) => {
    // In row object you have access to data.
    // Yon choose parameter. In this example I used uniqueId
    return row.projectid;
  };

  const onChangeSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  const handleCobenefit = (e) => {
    console.log({ e });
  };

  const handleCertification = (e) => {
    console.log({ e });
  };

  // Paginate the data for react table
  // const fetchData = useCallback(
  //   ({ pageSize, pageIndex }) => {
  //     console.log(pageSize, pageIndex, searchTerm);
  //     dispatch(
  //       getProjectList({
  //         limit: pageSize,
  //         skip: pageSize * pageIndex,
  //         search: searchTerm,
  //       })
  //     );
  //     setCurrentPage(pageIndex);
  //   },
  //   [dispatch, searchTerm]
  // );

  const debouncedResults = useMemo(() => {
    getCategories();
    getTypeCategories();
    getProjectStandards();
    getProjectSdgs();
    getCobenefits();
    getCertifications();
    return debounce(onChangeSearch, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const handlePaginationInputChange = debounce( (input) => {
    let { value, max } = input;
    let page = parseInt(value);
    max = parseInt(max);
    if( page > max ){
      input.value = max;
      page = max;
    } else {
      input.value = page;
    }
    if( Number.isInteger(page) && page > 0 ){
      setCurrentPage(page);
      getPage(page);
    }
  }, 250);

  document.title = 'Ecosystem Marketplace - Project List';
  return (
    <>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTask}
        onCloseClick={() => {
          setCurrentProject(null);
          setDeleteModal(false);
        }}
      />
      <ProjectForm
        isEdit={currentProject}
        modal={modal}
        setModal={setModal}
        currentProject={currentProject}
        toggle={toggle}
        onClosed={onClosed}
      />
      <div className='page-content'>
        <Container fluid={true}>
          <BreadCrumb title='Project List' pageTitle='Projects' />
          <Row>
            <Col xl={2} lg={3}>
              <Card>
                <CardHeader>
                  <div className='d-flex mb-3'>
                    <div className='flex-grow-1'>
                      <h5 className='fs-16'>Search Filters</h5>
                    </div>
                    <div className='flex-shrink-0'>
                      <button
                        onClick={clearFilter}
                        className='bg-transparent border-0 text-decoration-underline'>
                        Clear All
                      </button>
                    </div>
                  </div>

                  <div className='search-box mb-2 d-block'>
                    <input
                      type='text'
                      className='form-control ps-3'
                      placeholder={`Project Name`}
                      value={projectName}
                      onChange={(e) => setProjectName(e.target.value)}
                    />
                  </div>
                  <div className='search-box mb-2 d-block'>
                    <input
                      type='text'
                      className='form-control ps-3'
                      placeholder={`Project Registry ID`}
                      value={projectId}
                      onChange={(e) => setProjectId(e.target.value)}
                    />
                  </div>
                  <div className='search-box mb-2 d-block'>
                    <input
                      type='text'
                      className='form-control ps-3'
                      placeholder={`Country`}
                      value={projectCountry}
                      onChange={(e) => setProjectCountry(e.target.value)}
                    />
                  </div>

                  

                  
                </CardHeader>


                <div className='accordion-item d-none'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button bg-transparent shadow-none'
                      type='button'
                      id='flush-headingFMC'>
                      <span className='text-muted text-uppercase fs-12 fw-medium'>
                      Forest Management Certifications
                      </span>
                    </button>
                  </h2>
                  <UncontrolledCollapse toggler='#flush-headingFMC'>
                    <div
                      id='flush-collapseFMC'
                      className='accordion-collapse collapse show'
                      aria-labelledby='flush-headingFMC'>
                      <div className='accordion-body text-body pt-0'>
                    
                    
                    <ul className='list-unstyled mb-0 filter-list'>
                    {projectCerts?.map(
                        (cert) =>
                          cert?.value &&
                          cert?.label && (
                            <li
                              className='d-flex flex-column gap-2 filter-check mb-3'
                              key={`keycert${cert?.value}`}>
                              <div className='form-check'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  onChange={(e) =>
                                    filterByCertifications(e.target.value)
                                  }
                                  value={cert?.value}
                                  checked={projectCert?.some(
                                    (certification) => parseInt(certification) === cert?.value
                                  )}
                                  id={'cert'+cert?.value}
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor={cert?.label}>
                                  {cert?.label}
                                </label>
                              </div>
                            </li>
                          )
                        )}
                    </ul>


                    </div>
                    </div>
                  </UncontrolledCollapse>
                </div>




                <div className='accordion-item d-none'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button bg-transparent shadow-none'
                      type='button'
                      id='flush-headingBrands'>
                      <span className='text-muted text-uppercase fs-12 fw-medium'>
                        Categories
                      </span>
                    </button>
                  </h2>
                  <UncontrolledCollapse toggler='#flush-headingBrands'>
                    <div
                      id='flush-collapseBrands'
                      className='accordion-collapse collapse show'
                      aria-labelledby='flush-headingBrands'>
                      <div className='accordion-body text-body pt-0'>
                        <ul className='list-unstyled mb-0 filter-list'>
                          {categoriesList?.map(
                            (category) =>
                              category?.categoryid &&
                              category?.categoryname && (
                                <li
                                  className='d-flex flex-column gap-2 filter-check mb-3'
                                  key={`seycategory${category?.categoryid}`}>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      onChange={(e) =>
                                        filterByCategories(e.target.value)
                                      }
                                      value={category?.categoryid}
                                      checked={cate?.some(
                                        (cat) => +cat === +category?.categoryid
                                      )}
                                      id={category?.categoryname}
                                    />
                                    <label
                                      className='form-check-label'
                                      htmlFor={category?.categoryname}>
                                      {category?.categoryname}
                                    </label>
                                  </div>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <div className='accordion-item d-none'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button bg-transparent shadow-none'
                      type='button'
                      id='flush-headingBrandsStandards'>
                      <span className='text-muted text-uppercase fs-12 fw-medium'>
                        Standards
                      </span>
                    </button>
                  </h2>
                  <UncontrolledCollapse toggler='#flush-headingBrandsStandards'>
                    <div
                      id='flush-collapseBrands'
                      className='accordion-collapse collapse show'
                      aria-labelledby='flush-headingBrandsStandards'>
                      <div className='accordion-body text-body pt-0'>
                        <ul className='list-unstyled mb-0 filter-list'>
                          {projectStandards?.map(
                            (standards) =>
                              standards?.name &&
                              standards?.shortname && (
                                <li
                                  className='d-flex flex-column gap-2 filter-check mb-3'
                                  key={`seyStandards${standards?.shortname}`}>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      onChange={(e) =>
                                        filterByStandards(e.target.value)
                                      }
                                      value={standards?.name}
                                      checked={projectStandard?.some(
                                        (standard) =>
                                          standard === standards?.name
                                      )}
                                      id={standards?.shortname}
                                    />
                                    <label
                                      className='form-check-label'
                                      htmlFor={standards?.shortname}>
                                      {standards?.name}
                                    </label>
                                  </div>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button bg-transparent shadow-none'
                      type='button'
                      id='flush-headingBrandsSdgs'>
                      <span className='text-muted text-uppercase fs-12 fw-medium'>
                        SDGS
                      </span>
                    </button>
                  </h2>
                  <UncontrolledCollapse toggler='#flush-headingBrandsSdgs'>
                    <div
                      id='flush-collapseBrands'
                      className='accordion-collapse collapse show'
                      aria-labelledby='flush-headingBrandsSdgs'>
                      <div className='accordion-body text-body pt-0'>
                        <ul className='list-unstyled mb-0 filter-list'>
                          {projectSdgs?.map(
                            (sdg) =>
                              sdg?.sdgcode &&
                              sdg?.sdgname && (
                                <li
                                  className='d-flex flex-column gap-2 filter-check mb-3'
                                  key={`seysdg${sdg?.sdgcode}`}>
                                  <div className='form-check'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      onChange={(e) =>
                                        filterBySdgs(e.target.id)
                                      }
                                      value={sdg?.sdgname}
                                      checked={projectSdg?.some(
                                        (cat) => parseInt(cat) === sdg?.sdgcode
                                      )}
                                      id={'sdg'+sdg?.sdgcode}
                                    />
                                    <label
                                      className='form-check-label '
                                      htmlFor={sdg?.sdgname}>
                                      <span
                                        style={{
                                          backgroundColor: sdg?.colour,
                                          marginRight: '2px',
                                        }}
                                        className='text-white badge'>
                                        {sdg?.sdgcode}
                                      </span>
                                      {sdg?.sdgname}
                                    </label>
                                  </div>
                                </li>
                              )
                          )}
                          {/* {projectSdgs?.map((list) => (
                            <li key={`CategoryListKey${list?.sdgcode}`}>
                              <Link
                                to='#'
                                className={
                                  +projectSdg === +list?.sdgcode
                                    ? 'active d-flex py-1 align-items-center'
                                    : 'd-flex py-1 align-items-center'
                                }
                                onClick={() => filterBySdgs(list)}>
                                <div className='flex-grow-1'>
                                  <h5 className='fs-13 mb-0 listname'>
                                    {list?.sdgname}
                                  </h5>
                                </div>
                              </Link>
                            </li>
                          ))} */}
                        </ul>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
                <div className='accordion-item d-none'>
                  <h2 className='accordion-header'>
                    <button
                      className='accordion-button bg-transparent shadow-none'
                      type='button'
                      id='flush-headingBrandsAdvance'>
                      <span className='text-muted text-uppercase fs-12 fw-medium'>
                        Advanced
                      </span>
                    </button>
                  </h2>
                  <UncontrolledCollapse toggler='#flush-headingBrandsAdvance'>
                    <div
                      id='flush-collapseBrands'
                      className='accordion-collapse collapse show'
                      aria-labelledby='flush-headingBrandsAdvance'>
                      <div className='accordion-body text-body pt-0'>
                        <div className='d-flex flex-column gap-2 filter-check mb-3'>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              onChange={handleCorsia}
                              value={corsia}
                              id='productCorsia'
                            />
                            <label
                              className='form-check-label'
                              htmlFor='productCorsia'>
                              <span className='text-muted'>
                                <i className='mdi mdi-airplane-check text-success'></i>
                              </span>{' '}
                              CORSIA eligible
                            </label>
                          </div>
                        </div>
                        <h2 className='accordion-header'>
                          <button
                            className='border-0 p-0 button bg-transparent shadow-none mb-3'
                            type='button'>
                            <span className='text-muted text-uppercase fs-12 fw-medium'>
                              Cobenefits
                            </span>
                          </button>
                        </h2>
                        {cobenefits?.map(
                          (cobenefit) =>
                            cobenefit?.cobenefitid &&
                            cobenefit?.cobenefitname && (
                              <div
                                className='d-flex flex-column gap-2 filter-check mb-3'
                                key={`KeyCobenefit${cobenefit?.cobenefitid}`}>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    onChange={(e) =>
                                      filterCoBenefits(e.target.value)
                                    }
                                    value={cobenefit?.cobenefitname}
                                    checked={projectCoBenefits?.some(
                                      (cobenefits) =>
                                        cobenefits === cobenefit?.cobenefitname
                                    )}
                                    id={cobenefit?.cobenefitname}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={cobenefit?.cobenefitname}>
                                    {cobenefit?.cobenefitname}
                                  </label>
                                </div>
                              </div>
                            )
                        )}

                        <h2 className='accordion-header'>
                          <button
                            className='border-0 p-0 button bg-transparent shadow-none my-3'
                            type='button'>
                            <span className='text-muted text-uppercase fs-12 fw-medium'>
                              Certifications
                            </span>
                          </button>
                        </h2>
                        {certifications?.map(
                          (certification) =>
                            certification?.certificationid &&
                            certification?.certificationname && (
                              <div
                                className='d-flex flex-column gap-2 filter-check mb-3'
                                key={`Keycertification${certification?.certificationid}`}>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    onChange={(e) =>
                                      filterCertifications(e.target.value)
                                    }
                                    checked={projectCertification?.some(
                                      (certificate) =>
                                        certificate ===
                                        certification?.certificationname
                                    )}
                                    value={certification?.certificationname}
                                    id={certification?.certificationname}
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor={certification?.certificationname}>
                                    {certification?.certificationname}
                                  </label>
                                </div>
                              </div>
                            )
                        )}

                        <div className='filter-choices-input mb-3'>
                          <input
                            className='form-control'
                            type='text'
                            id='filter-choices-input'
                            value={projectMethdology}
                            onChange={(e) => filterMethdology(e?.target?.value)}
                            placeholder='Methdology'
                          />
                        </div>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
              </Card>
            </Col>
            <Col xl={10} lg={9}>
              <Card>
                <CardHeader>
                  <div className='d-flex align-items-center'>
                    <h5 className='card-title mb-0 flex-grow-1'>Projects</h5>
                    
                  </div>
                </CardHeader>
                {/* <CardBody className='border border-dashed border-end-0 border-start-0'>
                  <Row>
                    <Col sm={5}>
                      <div className='search-box me-2 mb-2 d-inline-block col-12'>
                        <input
                          id='search-bar-0'
                          type='text'
                          className='form-control search /'
                          placeholder={`Search...`}
                          onChange={debouncedResults}
                        />
                        <i className='bx bx-search-alt search-icon'></i>
                      </div>
                    </Col>
                  </Row>
                </CardBody> */}
                <CardBody>
                  { loading && <div className="loadingOverlay">
                      <div className="loadingSpinner"><Spinner /></div>
                    </div> }
                  <div className='table-responsive table-card mb-1'>
                    <table role="table" className="align-middle table-nowrap table table-hover" style={{tableLayout: 'fixed'}}>
                      <thead className="table-success text-muted">
                          <tr role="row">
                            <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>
                                Project Registry ID
                                <div><input id="top_project_code" className="form-control form-control-sm" placeholder="Project Registry ID" value={projectId}
                                  onChange={(e) => setProjectId(e.target.value)} /></div>
                            </th>
                            <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>
                                Name
                                <div><input id="top_project_name" className="form-control form-control-sm" placeholder="Name" value={projectName}
                                        onChange={(e) => setProjectName(e.target.value)} /></div>
                            </th>
                            <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>
                                Type
                                <div><input id="top_project_type" className="form-control form-control-sm" placeholder="Type" value={projectType} 
                                    onChange={(e) => setProjectType(e.target.value)} /></div>
                            </th>
                            <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>
                                Country
                                <div><input id="top_project_country" className="form-control form-control-sm" placeholder="Country" value={projectCountry} 
                                    onChange={(e) => setProjectCountry(e.target.value)} /></div>
                            </th>
                            
                            {user?.is_superuser && (<th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>action</th>)}
                          </tr>
                      </thead>
                    
                  {isProjectSuccess && projectList.length ? (
                    <tbody id="project_table_body" role="rowgroup">
                      {projectList.map((project) => (
                        <tr key={project?.projectid}>
                          <td role="cell" style={{minWidth: '0px', width: '150px'}}><Link to={`/projectDetail/${project?.projectid}`} className='fw-medium link-primary'>{project?.project_code}</Link></td>
                          <td role="cell" style={{minWidth: '0px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                            
                            <Link to={`/projectDetail/${project?.projectid}`} className='fw-medium link-primary'>{project?.project_name}</Link>
                            {/* <a className="fw-medium link-primary" href={"/project/" + project?.projectid}>{project?.project_name}</a> */}
                            
                          </td>
                          <td role="cell" style={{minWidth: '0px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}} >{project?.typeid_name}</td>
                          <td role="cell" style={{minWidth: '0px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}} >{project?.country_name}</td>
                          {user?.is_superuser && (
                          <td role="cell" style={{minWidth: '0px', width: '150px'}}>
                            <ul className='list-inline hstack gap-2 mb-0'>
                              <li className='list-inline-item edit'>
                               <Link
                                  to={`/project/edit/${project?.projectid}`}
                                  className='text-primary d-inline-block edit-item-btn'>
                                  <i className='ri-pencil-fill fs-16'></i>
                                </Link>
                              </li>
                              <li className='list-inline-item'>
                                <Link
                                  to='#'
                                  className='text-danger d-inline-block remove-item-btn'
                                  onClick={() => {
                                    console.log(project);
                                    onClickDelete(project);
                                  }}>
                                  <i className='ri-delete-bin-5-fill fs-16'></i>
                                </Link>
                              </li>
                            </ul>
                          </td>
                          )}
                        </tr>
                      ))}
                        

                        
                    </tbody>
                    
                    // <ReactTable
                    //   tableStyles={{ tableLayout: 'fixed' }}
                    //   total={projectListCount}
                    //   columns={columns}
                    //   data={projectList}
                    //   customPageSize={20}
                    //   divClass='table-responsive table-card mb-1'
                    //   tableClass='align-middle table-nowrap'
                    //   theadClass='table-success text-muted'
                    //   getRowId={getRowId}
                    //   loading={projectLoading}
                    //   fetchData={fetchData}
                    //   pageCount={projectNumPages}
                    //   currentPage={currentPage}
                    //   isNextPage={projectNext}
                    //   isPrevPage={projectPrev}
                    //   nextHandler={() =>
                    //     dispatch(
                    //       getProjectList({
                    //         page: projectNext,
                    //       })
                    //     )
                    //   }
                    //   prevHandler={() =>
                    //     dispatch(
                    //       getProjectList({
                    //         page: projectPrev > 2 ? projectPrev - 1 : 1,
                    //       })
                    //     )
                    //   }
                    //   // onItemClick={(data) =>
                    //   //   history.push(`/project/${data?.original?.projectid}`)
                    //   // }
                    // />
                  ) : isProjectSuccess && !error ? (
                    <div className={projectListInitializing?'d-none':''} style={{padding:'20px'}}>No projects found</div>
                  ) : (
                    <Loader error={error} />
                  )}
                    </table>
                  </div>
                  {projectCount && projectCount > 20 ? (
                  <div className="justify-content-md-end justify-content-center align-items-center p-2 row">
                    <div className="col">Total Results : {projectCount} </div>
                    <div className="col-md-auto col">
                      <div className="d-flex gap-1">
                        <button id="prev_page_btn" onClick={() => { getPage(currentPage - 1); }} type="button" disabled={currentPage == 1 && ('disabled')} className={currentPage == 1 ? 'disabled btn btn-primary' : 'btn btn-primary'}>&lt;</button>
                      </div>
                    </div>
                    <div className="col-md-auto d-none d-md-block col">Page <strong>{currentPage} of {Math.ceil(projectCount/projectsPerPage)}</strong></div>
                    <div className="col-md-auto col">
                    <input ref={paginationInput} onChange={(e) => { handlePaginationInputChange(e.target); }}  type="number" className="form-control" defaultValue={1} max={Math.ceil(projectCount/projectsPerPage)} style={{width: '70px'}} />
                    </div>
                    <div className="col-md-auto col">
                      <div className="d-flex gap-1">
                        <button id="next_page_btn" onClick={() => { getPage(currentPage + 1); }} type="button" disabled={currentPage == Math.ceil(projectCount/projectsPerPage) && ('disabled')} className={currentPage == Math.ceil(projectCount/projectsPerPage) ? "disabled btn btn-primary" : "btn btn-primary"}>&gt;</button>
                      </div>
                    </div>
                  </div>
                  ) : ''}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default withRouter(ProjectList);
