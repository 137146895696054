import React, {useEffect, useState} from 'react';
import {
  Container,
  CardHeader,
  Row,
  Card,
  CardBody, 
} from 'reactstrap'; 
 
import { URL } from '../../../constants';
import axios from 'axios';
import { useSelector } from 'react-redux';

import StandardsScatterWithJitter from '../../Charts/components/StandardsScatterWithJitter-Private';







const TaxonomyPublic = () => {

    const { Login } = useSelector((state) => state);

    const [categoryData, setCategoryData] = useState([]);
    const [clusterData, setClusterData] = useState([]);
    const [dataReady, setDataReady] = useState(false);
    const [categoriesFiltered, setCategoriesFiltered] = useState([]);

    console.log('Login',Login);

    const isLoggedIn = Login?.user?.email ? true : false;
    console.log('isLoggedIn',isLoggedIn);

    const notLoggedIn = !isLoggedIn;

    useEffect(() => {
        axios.get(URL.get_hub_categories_clusters).then((response) => {
            setClusterData(response.data);
        });
        axios.get(URL.get_hub_categories).then((response) => {
            setCategoryData(response.data);
        });
    }, []);

    useEffect(() => {
        if (categoryData.length > 0 && clusterData.length > 0) {
            let categoryDataCopy = [...categoryData];
            categoryDataCopy = categoryDataCopy.filter((category) => { 
                let include = false;
                clusterData.forEach((cluster) => {
                    if (cluster.categoryid === category.categoryid && cluster.summary != '') {
                        if( category.categoryid == 3 ){
                            console.log('cluster',cluster);
                        }
                        include = true;
                    }
                });
                return include;
            });
            console.log('categoryDataCopy',categoryDataCopy);
            setCategoriesFiltered(categoryDataCopy);
            
        }
    }, [categoryData, clusterData]);

    useEffect(() => {
        setDataReady(true);
    }, [categoriesFiltered]);

    const toggleShowCatSummary = (e) => {
        const target = e.target;
        const summary = target.closest('.categoryContainer').querySelector('.taxCategory__Summary');
        if( ! summary.classList.contains('show') ) {
            summary.classList.add('show');
        } else {
            summary.classList.remove('show');
        }
    }

    const toggleShowClusters = (e) => {
        const target = e.target;
        const clusters = target.closest('.categoryContainer').querySelector('.taxClusterContainer');
        if( ! clusters.classList.contains('show') ) {
            clusters.classList.add('show');
            e.target.innerHTML = 'Hide Clusters <i class="ri-arrow-right-line"></i>';
        } else {
            clusters.classList.remove('show');
            e.target.innerHTML = 'View Clusters <i class="ri-arrow-right-line"></i>';
        }
    }

    const toggleShowClusterSummary = (e) => {
        const target = e.target;
        const summary = target.closest('.taxCluster').querySelector('.taxCluster__Summary');
        if( ! summary.classList.contains('show') ) {
            summary.classList.add('show');
        } else {
            summary.classList.remove('show');
        }
    }
    
    return ( 
        <React.Fragment>
        <div className='page-content'>
            <Container fluid>
            {/* <BreadCrumb title="Dashboard" pageTitle="Dashboards" />  */}
        
            <Row>
            <Card>
                <CardHeader className="align-items-center d-flex">
                Taxonomy
                </CardHeader>
                
                {isLoggedIn && (
                    <>
                    <CardHeader className="align-items-center d-flex">
                    <h4>Cluster Data</h4>
                    </CardHeader>
                    <CardBody className="p-0">
                        <StandardsScatterWithJitter />
                    </CardBody> 
                    
                    </>
                )}
                
                <CardBody>
                    <style dangerouslySetInnerHTML={{__html: `
                        .categoryContainer {
                            padding:30px 30px 0 30px;
                            color:#fff;
                        } 
                        .categoryContainer * {
                            transition:all 0.25s ease-out;
                            transform-origin:top center;
                            padding:3px;
                            
                        }
                        .categoryContainer i {
                            position:relative;
                            top:3px;
                        }
                        .taxCategory__name {
                            font-size: 24px;
                            font-weight: bold;
                            color: #ffffff;
                            cursor:pointer;
                        }  
                        .taxCategory__name:hover {
                            opacity:0.8;
                            transform:translateX(-3px);
                        }
                        .taxCategory__Summary {
                            font-size: 16px;
                            color: #ffffff;
                            font-weight:400;
                        }  
                        .taxClusterHeading {    
                            font-size: 20px;
                        } 
                        .btn-toggle {
                            cursor:pointer;
                            padding-left:0;
                            font-weight:bold;
                        }
                        .btn-toggle:hover {
                            opacity:0.8;
                            transform:translateX(-3px);
                        }
                        .taxClusterContainer {
                            padding:0 30px 30px 30px;
                            line-height:1.2;
                        }
                        .taxCluster {
                            height:auto!important;
                            min-height:0;
                            
                            font-weight:bold;
                        }
                        .taxCluster__name {
                            cursor:pointer;
                        }

                        .taxCluster__name:hover {
                            opacity:0.8;
                            transform:translateX(-3px);
                        }
                        .taxCluster__Summary {
                            font-size:16px;
                            font-weight:400;
                        }
                        .taxClusterHeading {
                            font-weight:bold;
                        }
                        .hide {
                            height:0;
                            overflow:hidden;
                            transform:scale(1,0);
                            margin-top:-9px;
                        }    
                        .show {
                            height:auto!important;
                            transform:scale(1,1)!important;
                            margin-top:0;
                        }
                    }          
                `}} />
                    {dataReady && (
                        <>
                        {console.log('categoryData',categoriesFiltered)}
                        {console.log('clusterData',clusterData)}
                        { /* Category objects look like this:  {
    "categoryid": 1,
    "categoryname": "Agriculture",
    "color_med": "#accf62",
    "color_light": "#9ac440",
    "summary": "The Agriculture category includes project types focused on the sustainable management of farmland and pasture, including soil carbon sequestration through sustainable farmland or pasture management, livestock waste methane management, conservation of grassland habitats, and avoidance of methane production in rice cultivation."
}  
  
  cluster objects look like this:
  {
    "id": 38,
    "tagid": 10,
    "categoryname": "Household / Community Devices",
    "clustername": "Clean cookstove distribution",
    "summary": "Projects involving the distribution of cookstoves using more efficient technology or clean fuel sources such as biogas, which in turn reduces local reliance on firewood harvested from nearby forests.",
    "categoryid": 5
}

loop through categoryData and clusterData to display the data with a structure like this:
<div className="categoryContainer"><div style={{backgroundColor: color_med}}>categoryname</div> {foreach cluster with matching categoryname }<div className="cluster">clustername</div></div>
  */ }

                        {categoriesFiltered.filter((category) => category.summary != '').map((category) => (
                            <div key={'category'+category.categoryid} className="categoryContainer" style={{backgroundColor: category.color_med}}>
                                
                                <div className="taxCategory"><div className="taxCategory__name" onClick={toggleShowCatSummary}>{category.categoryname} <i className="ri-arrow-right-line"></i></div>
                                    <div className="taxCategory__Summary hide">{category.summary}
                                        <br />
                                        <br />
                                        <div className="btn-toggle" onClick={toggleShowClusters}>View Clusters <i className="ri-arrow-right-line"></i></div>
                                    
                                    </div>
                                </div>
                                <div className="taxClusterContainer hide">
                                    <div className="taxClusterHeading">Clusters</div>
                                {clusterData.filter((cluster) => cluster.categoryid === category.categoryid  && cluster.summary != '').map((cluster) => (
                                    <div key={'cluster'+cluster.tagid} className="taxCluster" style={{color: '#ffffff'}} >
                                        <div className="taxCluster__name" onClick={toggleShowClusterSummary}>{cluster.clustername} <i className="ri-arrow-right-line"></i></div>
                                        <div className="taxCluster__Summary hide">{cluster.summary}</div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        ))}
                        
                        </>
                    )}

                </CardBody>
                
                
                
                
                
                </Card>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    );
};

export default TaxonomyPublic;
