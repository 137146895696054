import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Col, Card, CardHeader, CardBody, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import Loader from '../../../Components/Common/Loader';
import { getTradeList } from '../../../store/actions';




const TradeList = () => {
  
    const {
      trades,
      totalTrades,
      tradesLoading,
      isTradeSuccess,
      error,
    } = useSelector((state) => ({
      totalTrades: state.Trade.tradeListCount,
      trades: state.Trade.tradeList,
      tradesLoading: state.Trade.tradesLoading,
      isTradeSuccess: state.Trade.isTradeSuccess,
      error: state.Trade.error,
    }));

  const tradesPerPage = 10;
  const { Login } = useSelector((state) => state);
  const respondent_id = Login?.user?.is_superuser ? localStorage.getItem('respondentId')
    : Login?.user?.respondent_id;
  const [currentPage, setCurrentPage] = useState(1);
  const [buyer, setBuyer] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [projectCode, setProjectCode] = useState(null);
  const [dateTransactionQuery, setDateTransactionQuery] = useState(null);
  const [volume, setVolume] = useState(null);
  const [priceTonne, setPriceTonne] = useState(null);
  const [valueUSD, setValueUSD] = useState(null);

  const dispatch = useDispatch();

  document.title = 'Ecosystem Marketplace - Trade List';

  const updateDateTransaction = (date) => {
    
    let candidateDate = moment(date);
    if( candidateDate.isValid() ){
      setDateTransactionQuery(moment.utc(date).format('yyyy-MM-DD'));
    } else {
      setDateTransactionQuery(null);
    }
  };
  const updateProjectName = (name) => {
    setProjectName(name);
  };
  const updateProjectCode = (code) => {
    setProjectCode(code);
  };
  const updateBuyer = (buyer) => {
    setBuyer(buyer);
  };
  const updateVolume = (volume) => {
    setVolume(volume);
  };
  const updatePriceTonne = (price) => {
    setPriceTonne(price);
  };
  const updateValueUSD = (value) => {
    setValueUSD(value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getPage(1);
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [dateTransactionQuery, projectName, projectCode, buyer, volume, priceTonne, valueUSD]);

  const buildCurrentQueryObject = () => {
    let currentQueryObject = { respondent_id:respondent_id, limit:10 };
    if (projectName) {
      currentQueryObject.project_name = projectName;
    }
    if (projectCode) {
      currentQueryObject.project_code = projectCode;
    }
    if (buyer) {
      currentQueryObject.buyer = buyer;
    }
    if (dateTransactionQuery) {
      currentQueryObject.date_transaction = dateTransactionQuery;
    }
    if (volume) {
      currentQueryObject.volume = volume;
    }
    if (priceTonne) {
      currentQueryObject.price_tonne = priceTonne;
    }
    if (valueUSD) {
      currentQueryObject.value_usd = valueUSD;
    }
    return currentQueryObject;
  };


  const paginationInput = useRef(null);

  const getPage = (page) => {
    if( Number.isInteger(parseInt(page)) && parseInt(page) > 0 ){
      if( paginationInput.current?.value ){
        paginationInput.current.value = parseInt(page);
      }
      let offset = (page - 1)*tradesPerPage;
      setCurrentPage(page);
      let currentQueryObject = buildCurrentQueryObject();
      currentQueryObject.offset = offset;
      dispatch(getTradeList(currentQueryObject));
    } 
  };
  const handlePaginationInputChange = debounce( (input) => {
    let { value, max } = input;
    let page = parseInt(value);
    max = parseInt(max);
    if( page > max ){
      input.value = max;
      page = max;
    } else {
      input.value = page;
    }
    if( Number.isInteger(page) && page > 0 ){
      setCurrentPage(page);
      getPage(page);
    }
  }, 250);

  return (
    <>
      <Col>
        <Card>
          <CardHeader>
            <div className='d-flex align-items-center'>
              <h5 className='card-title mb-0 flex-grow-1'>Latest Trades</h5>
              <div className='d-flex align-items-center flex-shrink-0'>
                <Link to='/trade/list'>
                  <button
                    type='button'
                    className='btn btn-success add-btn me-3'
                    id='create-btn'
                    href='/trade/list'>
                    <i className='ri-add-line align-bottom '></i> Add new trades
                  </button>{' '}
                </Link>
              </div>
            </div>
          </CardHeader>
          
          <CardBody>
            { tradesLoading && <div className="loadingOverlay">
              <div className="loadingSpinner"><Spinner /></div>
            </div> }
            <div className='table-responsive table-card mb-1'>
              <table role="table" className="align-middle table-nowrap table table-hover" style={{tableLayout: 'fixed'}}>
                <thead className="table-success text-muted">
                    <tr role="row">
                      <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '120px'}}>
                          Date
                            <input
                              type='date'
                              className='form-control form-control-sm'
                              placeholder='Date'
                              max={moment(new Date()).format('yyyy-MM-DD')}
                              onChange={(e) => {  
                                updateDateTransaction(e.target.value)}}
                            />
            
                      </th>
                      <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '360px'}}>
                          Project name
                          <input id="top_project_name" className="form-control form-control-sm" placeholder="Project Name" value={projectName || ''}
                                  onChange={(e) => updateProjectName(e.target.value)} />
                      </th>
                      <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>
                          Project Code
                          <input id="top_project_code" className="form-control form-control-sm" placeholder="Project Code" value={projectCode || ''} 
                              onChange={(e) => updateProjectCode(e.target.value)} />
                      </th>
                      <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '200px'}}>
                          Buyer
                          <input id="top_buyer" className="form-control form-control-sm" placeholder="Buyer" value={buyer || ''} 
                              onChange={(e) => updateBuyer(e.target.value)} />
                      </th>
                      <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '120px'}}>
                          Volume (TCO2e)
                          <input id="top_volume" className="form-control form-control-sm" placeholder="Volume (TCO2e)" value={volume || ''} 
                              onChange={(e) => updateVolume(e.target.value)} />
                      </th>
                      <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '100px'}}>
                          USD/TCO2e
                          <input id="top_usd_tc02e" className="form-control form-control-sm" placeholder="USD/TCO2e" value={priceTonne || ''} 
                              onChange={(e) => updatePriceTonne(e.target.value)} />
                      </th>
                      <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '100px'}}>
                          Value (USD)
                          <input id="top_value_usd" className="form-control form-control-sm" placeholder="USD/TCO2e" value={valueUSD || ''} 
                              onChange={(e) => updateValueUSD(e.target.value)} />
                      </th>
                      
                    </tr>
                </thead>
              
            { trades?.length > 0 ? (
              <tbody id="trade_table_body" role="rowgroup">
                {trades.map((trade) => (
                  <tr key={trade?.trade_id}>
                    
                    <td role="cell" style={{minWidth: '0px', width: '120px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                      {moment.utc(trade?.date_transaction).format('MM/DD/YYYY')}
                    </td>
                    <td role="cell" style={{minWidth: '0px', width: '360px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                      {trade?.projectid !== null && trade?.projectid?.projectid == 0 && trade?.project_name != "" ? 
                        trade?.project_name : trade?.projectid?.project_name}
                    </td>
                    <td role="cell" style={{minWidth: '0px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                      {trade?.projectid?.project_code}
                    </td>
                    <td role="cell" style={{minWidth: '0px', width: '200px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                      {trade?.buyer?.buyer_name}
                    </td>
                    <td role="cell" style={{minWidth: '0px', width: '120px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                      {trade?.volume_tco2e}
                    </td>
                    <td role="cell" style={{minWidth: '0px', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                      {trade?.price_usd_tcoe ? Math.round(trade?.price_usd_tcoe * 100) / 100 : ''}
                    </td>
                    <td role="cell" style={{minWidth: '0px', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                      {trade?.value_usd ? Math.round(trade?.value_usd * 100) / 100 : ''}
                    </td>
                    
                    
                    
                  </tr>
                ))}
                  

                  
              </tbody>
              
              
            ) : isTradeSuccess && !error ? (
              <tr><td colSpan={8}><div style={{padding:'20px', width:'100%'}}>No matching trades found</div></td></tr>
            ) : (
              <Loader error={error} />
            )}
              </table>
            </div>
            {totalTrades && totalTrades > 10 ? (
            <div className="justify-content-md-end justify-content-center align-items-center p-2 row">
              <div className="col">Total Results : {totalTrades} </div>
              <div className="col-md-auto col">
                <div className="d-flex gap-1">
                  <button id="prev_page_btn" onClick={() => { getPage(currentPage - 1); }} type="button" disabled={currentPage == 1 && ('disabled')} className={currentPage == 1 ? 'disabled btn btn-primary' : 'btn btn-primary'}>&lt;</button>
                </div>
              </div>
              <div className="col-md-auto d-none d-md-block col">Page <strong>{currentPage} of {Math.ceil(totalTrades/tradesPerPage)}</strong></div>
              <div className="col-md-auto col">
              <input ref={paginationInput} onChange={(e) => { handlePaginationInputChange(e.target); }}  type="number" className="form-control" defaultValue={1} max={Math.ceil(totalTrades/tradesPerPage)} style={{width: '70px'}} />
              </div>
              <div className="col-md-auto col">
                <div className="d-flex gap-1">
                  <button id="next_page_btn" onClick={() => { getPage(currentPage + 1); }} type="button" disabled={currentPage == Math.ceil(totalTrades/tradesPerPage) && ('disabled')} className={currentPage == Math.ceil(totalTrades/tradesPerPage) ? "disabled btn btn-primary" : "btn btn-primary"}>&gt;</button>
                </div>
              </div>
            </div>
            ) : ''}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default TradeList;
