import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Link, useParams, withRouter } from 'react-router-dom';
import {
  Container,
  Table,
  Input,
  Alert,
  Label,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  AccordionItem,
  Collapse,
  Accordion,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  FormFeedback,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Spinner,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
//Import Breadcrumb
import BreadCrumb from '../../Components/Common/BreadCrumb';
import ReactTable from '../../Components/Common/ReactTable';
// import DeleteModal from "../../Components/Common/DeleteModal";
// import Loader from "../../Components/Common/Loader";
import { getRespondentUsersList, inviteRespondent } from '../../store/actions';
import * as moment from 'moment';
import { RespondentUserApiResponseSuccess } from '../../store/respondentUser/action';
import { GET_RESPONDENT_USERS_LIST } from '../../store/respondentUser/actionType';
import { getRespondentList } from '../../helpers/backend_helper';
// import classnames from "classnames";
// import TradeForm from "../Trade/components/TradeForm";
// import Select from "react-select";
// import Cleave from "cleave.js/react";
// import BuyerForm from "./components/BuyerForm";

const handleValidDate = (date) => {
  return moment(new Date(date)).format('DD MMM Y');
};

const handleValidTime = (time) => {
  const time1 = new Date(time);
  const getHour = time1.getUTCHours();
  const getMin = time1.getUTCMinutes();
  const getTime = `${getHour}:${getMin}`;
  var meridiem = '';
  if (getHour >= 12) {
    meridiem = 'PM';
  } else {
    meridiem = 'AM';
  }
  const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + ' ' + meridiem;
  return updateTime;
};

const InviteModal = ({ modal, toggle, onClose, respondent_id }) => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');

  const inviteUser = () => {
    dispatch(inviteRespondent({ email, respondent: respondent_id }));
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size='md'
        className='border-0'
        modalClassName='modal fade zoomIn'
        onClosed={onClose}>
        <ModalHeader className='p-3 bg-soft-info' toggle={toggle}>
          Invite User
        </ModalHeader>
        <ModalBody className='modal-body'>
          <Row className='mb-2'>
            <Col sm={12}>
              <p>This will send an email invitation.</p>
              <p>
                The person you invite will have access to the Respondent's trade
                history, and be able to add new trades.
              </p>
              <p>All their activity will be logged.</p>
            </Col>
          </Row>

          <Row className='bg-soft-info p-1 py-3 mb-2'>
            <Col sm={12}>
              <Label for='date_transaction-field' className='form-label'>
                What's the user's email?
              </Label>
              <Input
                name='email'
                className='form-control'
                placeholder='Enter email'
                type='email'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Col>
          </Row>
        </ModalBody>
        <div className='modal-footer'>
          <div className='hstack gap-2 justify-content-end'>
            <Button type='button' onClick={onClose} className='btn-light'>
              Close
            </Button>
            <button
              onClick={inviteUser}
              className='btn btn-success'
              id='add-btn'>
              Invite User
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const RespondentEmployee = () => {
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch();
  const { respondent_id } = useParams();
  const {
    respondent,
    respondentListNumPages,
    isInviteRespondentSuccess,
    error,
    isRespondentUsersSuccess,
    user,
    isInviteRespondentModalOpened,
  } = useSelector((state) => ({
    respondent: state.RespondentUser.respondentUsersList,
    isInviteRespondentModalOpened:
      state.RespondentUser.isInviteRespondentModalOpened,
    respondentListNumPages: state.RespondentUser.respondentListUsersNumPages,
    isRespondentUsersSuccess: state.RespondentUser.isRespondentUsersSuccess,
    error: state.RespondentUser.error,
    user: state.Login.user,
  }));
  const [searchTerm, setSearchTerm] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const [respondentData, setRespondentData] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getRespondentList({ search: respondent_id }).then((data) => {
      setRespondentData(data?.results?.[0]);
      setLoading(false);
    });
    return () => {
      // debouncedResults.cancel();
      dispatch(RespondentUserApiResponseSuccess(GET_RESPONDENT_USERS_LIST, []));
    };
  }, [respondent_id]);

  useEffect(() => {
    setModal(isInviteRespondentModalOpened);
  }, [isInviteRespondentModalOpened]);
  // TODO so this will work like; it will call the backend and return the respondent users
  // TODO get data from API end point respondent-user/<pk>
  // TODO create a popup invite which will contain an email field only
  // TODO call on submit of the form /invite-respondent/ API
  // TODO show success or error
  // useEffect(() => {
  //   setRespondentList(respondent? respondent : []);
  // }, [respondent]);

  // console.log('respondentList',respondentList )
  // useEffect(() => {
  //   dispatch(getRespondentUsersList({ search: searchTerm, skip: 0 }));
  // }, [dispatch, searchTerm]);
  useEffect(() => {
    respondent_id &&
      dispatch(
        getRespondentUsersList({
          id: respondent_id,
          search: searchTerm,
          skip: 0,
        })
      );
  }, [dispatch, searchTerm]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'user.first_name',
        filterable: false,
        // Cell: (cell) => {
        //   return (
        //     <Link
        //       className="fw-medium link-primary"
        //     >
        //       {cell.value}
        //     </Link>
        //   );
        // },
      },
      {
        Header: 'Last Name',
        accessor: 'user.last_name',
        filterable: false,
      },
      {
        Header: 'Email',
        accessor: 'user.email',
        filterable: false,
      },
      {
        Header: 'Respondent Name',
        accessor: 'user.respondent_name',
      },
    ],
    []
  );

  const getRowId = (row, relativeIndex, parent) => {
    // In row object you have access to data.
    // Yon choose parameter. In this example I used uniqueId
    return row.id;
  };

  const onChangeSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Paginate the data for react table
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      console.log(pageSize, pageIndex, searchTerm);
      dispatch(
        getRespondentUsersList({
          id: respondent_id,
          limit: pageSize,
          skip: pageSize * pageIndex,
          search: searchTerm,
        })
      );
      setCurrentPage(pageIndex);
      // console.log("fetchData is being called")
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.
      // Give this fetch an ID
      // const fetchId = ++fetchIdRef.current;
      // setLoading(true);
      // if (fetchId === fetchIdRef.current) {
      //   fetchAPIData({
      //     limit: pageSize,
      //     skip: pageSize * pageIndex,
      //     search: searchTerm,
      //   });
      // }
    },
    [dispatch]
  );

  const debouncedResults = useMemo(() => {
    return debounce(onChangeSearch, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
      // dispatch(RespondentUserApiResponseSuccess(GET_RESPONDENT_USERS_LIST, []));
    };
  });

  document.title = 'Ecosystem Marketplace - Responden';

  return (
    <>
      <div className='page-content'>
        <Container fluid={true}>
          <BreadCrumb
            title={
              !loading ? (
                `${respondentData?.respondent_name}'s Users`
              ) : (
                <Spinner size='md' />
              )
            }
            pageTitle='Respondent'
            pageTitleLink='/respondent/list'
          />
          {!loading && !respondentData ? (
            <h1>No Data Found</h1>
          ) : (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody className="p-0 card-border-success border">
                      <Alert color='success' className="border-0 rounded-top rounded-0 m-0 d-flex align-items-center">
                          <i className="ri-user-add-fill me-2 fs-16"
                          />
                          <div className="flex-grow-1 text-truncate">
                          Would you like to give access to other people from{' '}
                          <b>{respondentData?.respondent_name}</b>?
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              className='btn btn-success add-btn'
                              onClick={() => {
                                setModal(true);
                              }}>
                              <i className='ri-user-add-line me-1 align-bottom'></i>{' '}
                              Invite
                            </button> 
                          </div>
                      </Alert>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={12} xxl={12}>
                <Card>
                  <CardBody className='border border-dashed border-end-0 border-start-0'>
                    <Row>
                      <Col sm={3} className='d-flex'>
                        <div className='search-box me-2 mb-2 d-inline-block col-12'>
                          <input
                            id='search-bar-0'
                            type='text'
                            className='form-control search /'
                            placeholder={`Search users ...`}
                            onChange={debouncedResults}
                          />
                          <i className='bx bx-search-alt search-icon'></i>
                        </div>

                        {/*<button type="submit" className="btn btn-success" id="add-btn">  Add Contact </button>*/}
                      </Col>
                      {/* <Col sm={5} className="d-flex">
                    <div className="flex-shrink-0">
                            <button
                                type="button"
                                className="btn btn-success add-btn"
                                id="create-btn"
                                onClick={() => setModal(true)}
                            >
                                <i className="ri-add-line align-bottom me-1"></i>
                                Invite User
                            </button>{" "}
                        </div></Col> */}
                    </Row>
                  </CardBody>

                  <CardBody>
                    {/* {isRespondentUsersSuccess && respondentList.length ? ( */}
                    <ReactTable
                      loading={!respondent || respondent.length === 0}
                      columns={columns}
                      data={respondent}
                      customPageSize={10}
                      divClass='table-responsive table-card mb-1'
                      tableClass='align-middle table-nowrap'
                      theadClass='table-success text-muted'
                      // getRowId={getRowId}
                      fetchData={fetchData}
                      pageCount={respondentListNumPages}
                      currentPage={currentPage}
                      withPagination={false}
                    />
                    {/* ) : isRespondentUsersSuccess && !error ? (
                    "No respondent found"
                  ) : (
                    <Loader error={error} />
                  )} */}
                  </CardBody>
                </Card>
              </Col>
              {/* <Col xxl={3}>
                  <Card id="contact-view-detail">
                      <CardBody className="text-center">
                          <div className="position-relative d-inline-block">
                              <img
                                  src={process.env.REACT_APP_API_URL + "/images/users/" + ("avatar-10.jpg")}
                                  alt=""
                                  className="avatar-lg rounded-circle img-thumbnail"
                              />
                              <span className="contact-active position-absolute rounded-circle bg-success">
                      <span className="visually-hidden"></span>
                    </span>
                          </div>
                          <h5 className="mt-4 mb-1">{ "xx_Tonya Noble"}</h5>
                          <p className="text-muted">{ "xx_Nesta Technologies"}</p>

                          <ul className="list-inline mb-0">
                              <li className="list-inline-item avatar-xs">
                                  <Link
                                      to="#"
                                      className="avatar-title bg-soft-success text-success fs-15 rounded"
                                  >
                                      <i className="ri-phone-line"></i>
                                  </Link>
                              </li>
                              <li className="list-inline-item avatar-xs">
                                  <Link
                                      to="#"
                                      className="avatar-title bg-soft-danger text-danger fs-15 rounded"
                                  >
                                      <i className="ri-mail-line"></i>
                                  </Link>
                              </li>
                              <li className="list-inline-item avatar-xs">
                                  <Link
                                      to="#"
                                      className="avatar-title bg-soft-warning text-warning fs-15 rounded"
                                  >
                                      <i className="ri-question-answer-line"></i>
                                  </Link>
                              </li>
                          </ul>
                      </CardBody>
                      <CardBody>
                          <h6 className="text-muted text-uppercase fw-semibold mb-3">
                              Personal Information
                          </h6>
                          <p className="text-muted mb-4">
                              Hello, I'm { "xx_Tonya Noble"}, The most effective objective is one
                              that is tailored to the job you are applying for. It states
                              what kind of career you are seeking, and what skills and
                              experiences.
                          </p>
                          <div className="table-responsive table-card">
                              <Table className="table table-borderless mb-0">
                                  <tbody>
                                  <tr>
                                      <td className="fw-medium">
                                          Designation
                                      </td>
                                      <td>Lead Designer / Developer</td>
                                  </tr>
                                  <tr>
                                      <td className="fw-medium">
                                          Email ID
                                      </td>
                                      <td>{ "xx_tonyanoble@velzon.com"}</td>
                                  </tr>
                                  <tr>
                                      <td className="fw-medium">
                                          Phone No
                                      </td>
                                      <td>{"xx_414-453-5725"}</td>
                                  </tr>
                                  <tr>
                                      <td className="fw-medium">
                                          Lead Score
                                      </td>
                                      <td>{"xx_154"}</td>
                                  </tr>
                                  <tr>
                                      <td className="fw-medium">
                                          Tags
                                      </td>
                                      <td>
                                          {(
                                              [
                                                  "Lead",
                                                  "Partner"
                                              ]
                                          ).map((item, key) => (<span className="badge badge-soft-primary me-1" key={key}>{item}</span>))
                                          }
                                      </td>
                                  </tr>
                                  <tr>
                                      <td className="fw-medium">
                                          Last Contacted
                                      </td>
                                      <td>
                                          {'xx_'+handleValidDate("2021-04-13T18:30:00.000Z")}{" "}
                                          <small className="text-muted">{'xx_'+ handleValidTime("2021-04-13T18:30:00.000Z")}</small>
                                      </td>
                                  </tr>
                                  </tbody>
                              </Table>
                          </div>
                      </CardBody>
                  </Card>
              </Col> */}
            </Row>
          )}
        </Container>
      </div>
      <InviteModal
        modal={modal}
        toggle={() => setModal((prevValue) => !prevValue)}
        onClose={() => setModal(false)}
        respondent_id={respondent_id}
      />
    </>
  );
};

export default RespondentEmployee;
