import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { CSVBoxButton } from '@csvbox/react';
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Spinner,
} from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import uploadIcon from '../../assets/images/icons/icon-upload.svg';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DeleteModal from '../../Components/Common/DeleteModal';
import Loader from '../../Components/Common/Loader';
import { getTradeList, deleteTrade, logoutUser, backFillCurrencyConversionValues, getTypeList } from '../../store/actions';
import TradeForm from './components/TradeForm';
import DownloadTrades from './components/DownloadTrades';
import axios from 'axios';
import { api } from '../../config';
import { useProfile } from '../../Components/Hooks/UserHooks';
import BuyerForm from '../Buyer/components/BuyerForm';
import { getRespondentList } from '../../helpers/backend_helper';
import { URL } from '../../constants';


import ReactGA from 'react-ga4';
ReactGA.initialize('G-P7N889SGDD');
ReactGA.send({ hitType: "pageview", page: "/trade/list", title: "Respondent Trades Page" });



const TradeList = () => {
  const tradesPerPage = 10;
  const { Login } = useSelector((state) => state);
  const { userProfile } = useProfile();
  const [respondentUser, setRespondentUser] = useState();
  const [respondentData, setRespondentData] = useState();
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTrade, setCurrentTrade] = useState(null);
  const [modal, setModal] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [buyer, setBuyer] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [projectCode, setProjectCode] = useState(null);
  const [dateTransactionQuery, setDateTransactionQuery] = useState(null);
  const [volume, setVolume] = useState(null);
  const [priceTonne, setPriceTonne] = useState(null);
  const [valueUSD, setValueUSD] = useState(null);
  

  const [typeList, setTypeList] = useState([]);
  const respondent_id = Login?.user?.respondent_id;
  const dispatch = useDispatch();

  const {
    trades,
    totalTrades,
    isTradeSuccess,
    isTradeCreated,
    tradesLoading,
    respondentName,
    newBuyer,
    error,
  } = useSelector((state) => ({
    trades: state.Trade.tradeList,
    totalTrades: state.Trade.tradeListCount,
    isTradeSuccess: state.Trade.isTradeSuccess,
    isTradeCreated: state.Trade.isTradeCreated,
    tradesLoading: state.Trade.tradesLoading,
    respondentName: state.Login?.user?.respondent_name,
    newBuyer: state.Buyer.newBuyer,
    error: state.Trade.error,
  }));


  useEffect(() => {
    if (Login?.user?.is_superuser) {
      setLoading(true);
      getRespondentList({ limit: 100 })
        .then((res) => {
          const respondent = res.results.map(
            ({ respondent_name, respondent_id }) => ({
              label: respondent_name,
              value: respondent_id,
            })
          );
          setRespondentData(respondent);
          setLoading(false);
        })
        .catch((e) => {
          console.log('🔥🍊🍉 ERROR ', e);
        });
    }
  }, [Login?.user?.is_superuser]);

  const toggleCreateModal = () => {
    setCreateUserModal(false);
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setCurrentTrade(null);
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleEditTradeClick = useCallback(
    (arg) => {
      const trade = arg;
      setCurrentTrade(trade);
      toggle();
    },
    [toggle]
  );

  const onClickDelete = (trade) => {
    setCurrentTrade(trade);
    setDeleteModal(true);
  };

  const onClosed = () => {
    setCurrentTrade(null);
  };

  const handleDeleteTask = () => {
    if (currentTrade) {
      dispatch(deleteTrade(currentTrade));
      setDeleteModal(false);
      setCurrentTrade(null);
    }
  };

  useEffect(() => {
    if( isTradeCreated == true ){
      setCurrentPage(1);
      dispatch(getTradeList({ offset: 0, respondent_id: respondent_id }));
    }
  }, [isTradeCreated]);

  document.title = 'Ecosystem Marketplace - Trade List';

  useEffect(() => {
    dispatch(backFillCurrencyConversionValues());
  }, []);

  const updateDateTransaction = (date) => {
    let candidateDate = moment(date);
    if( candidateDate.isValid() ){
      setDateTransactionQuery(moment.utc(date).format('yyyy-MM-DD'));
    } else {
      setDateTransactionQuery(null);
    }
  };
  const updateProjectName = (name) => {
    setProjectName(name);
  };
  const updateProjectCode = (code) => {
    setProjectCode(code);
  };
  const updateBuyer = (buyer) => {
    setBuyer(buyer);
  };
  const updateVolume = (volume) => {
    setVolume(volume);
  };
  const updatePriceTonne = (price) => {
    setPriceTonne(price);
  };
  const updateValueUSD = (value) => {
    setValueUSD(value);
  };

  // prevents multiple requests while user is typing
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getPage(1);
    }, 300)

    return () => clearTimeout(delayDebounceFn)
  }, [dateTransactionQuery, projectName, projectCode, buyer, volume, priceTonne, valueUSD]);

  const buildCurrentQueryObject = () => {
    let currentQueryObject = { respondent_id:respondent_id, limit:10 };
    if (projectName) {
      currentQueryObject.project_name = projectName;
    }
    if (projectCode) {
      currentQueryObject.project_code = projectCode;
    }
    if (buyer) {
      currentQueryObject.buyer = buyer;
    }
    if (dateTransactionQuery) {
      currentQueryObject.date_transaction = dateTransactionQuery;
    }
    if (volume) {
      currentQueryObject.volume = volume;
    }
    if (priceTonne) {
      currentQueryObject.price_tonne = priceTonne;
    }
    if (valueUSD) {
      currentQueryObject.value_usd = valueUSD;
    }
    return currentQueryObject;
  };

  const paginationInput = useRef(null);

  const getPage = (page) => {
    if( Number.isInteger(parseInt(page)) && parseInt(page) > 0 ){
      if( paginationInput.current?.value ){
        paginationInput.current.value = parseInt(page);
      }
      let offset = (page - 1)*tradesPerPage;
      setCurrentPage(page);
      let currentQueryObject = buildCurrentQueryObject();
      currentQueryObject.offset = offset;
      dispatch(getTradeList(currentQueryObject));
    } 
  };

  const handlePaginationInputChange = debounce( (input) => {
    let { value, max } = input;
    let page = parseInt(value);
    max = parseInt(max);
    if( page > max ){
      input.value = max;
      page = max;
    } else {
      input.value = page;
    }
    if( Number.isInteger(page) && page > 0 ){
      setCurrentPage(page);
      getPage(page);
    }
  }, 250);

  let csvkey = 'eitTHGRmqW7aWOgsUJgza9s76f30ZN';
  let email = Login?.user?.email;
  if( Login?.user?.is_superuser == true ){ 
    csvkey = '0OA5dHWPy43Uy4Mt3TJeQAZMDwCbiB';
  } 

  if( window.location.href.includes('localhost') || window.location.href.includes('staging') ){
    csvkey = 'DcBVajk0iAzaFTWvhsREGUS9JxMsb3';
  }

  
  const respondent_name_possessive = respondentName.slice(-1) != 's' ? respondentName + "'s" : respondentName+"'";

  
  return (
    <>
      {currentTrade && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteTask}
          currentTrade={currentTrade}
          onCloseClick={() => {
            setCurrentTrade(null);
            setDeleteModal(false);
          }}
        />
      )}

      <TradeForm
        isEdit={currentTrade}
        modal={modal}
        setModal={setModal}
        currentTrade={currentTrade}
        toggle={toggle}
        onClosed={onClosed}
        setCreateUserModal={setCreateUserModal}
        newBuyer={newBuyer}
      />


      <BuyerForm
        isEdit={false}
        modal={createUserModal}
        setModal={setCreateUserModal}
        toggle={toggleCreateModal}
      />
      <div className='page-content'>
        <div className='upload-progress-container d-none'>
          <div className='upload-progress-container-inner'>
            <div className='upload-progress'>
              <div className='upload-progress-header'><h5 className="modal-title">Processing Uploaded Data</h5></div>
              <div className='upload-progress-content'>
                <div>
                  <div className="col-8 offset-2 col-md-4 offset-md-4 text-center mt-5">
                      <h3 className="mb-4 font-weight-light">Creating Trades (<span className="current-trades">0</span>/<span className="total-trades"></span>)</h3>
                      <div className="progress mb-3">
                          <div className="progress-bar progress-bar-animated progress-bar-striped" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width: 0}}></div>
                      </div>
                      
                      <div id="do-not-close-browser" className="mt-5 small text-muted">
                          <span data-i18n="donot_close_the_browser">Your uploaded file is being processed now.<br />Do not close the browser window or navigate away from this page.</span>
                      </div>
                      <div className="col-8 offset-2 col-md-8 offset-md-2 text-center mb-0" id="resubmit-btn">
                          
                      </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        
          <Container fluid={true}>
            <BreadCrumb
              title={`${
                respondentName ? `${respondentName} Trades` : 'Trades'
              }`}
              pageTitle='Trades'
            />

            
            <Row>
              <Col xl={12} lg={12}>
                <Card>
                  <CardHeader>
                    <div className='d-flex align-items-center'>
                      <h5 className='card-title mb-0 flex-grow-1'>Trades</h5>
                      <div className='d-flex align-items-end flex-shrink-0'>
                        
                        
                        <DownloadTrades />
                      
                        
                        
                        <CSVBoxButton
                          licenseKey={csvkey}
                          user={{
                            user_id: 'default123',
                          }}

                          onImport={(result, data) => {
                            if (result) {
                                const totalTradesSpan = document.querySelector('.upload-progress .total-trades');
                                totalTradesSpan.innerHTML = data.row_success;
                                const currentTradeSpan = document.querySelector('.upload-progress .current-trades');
                                const progressDiv = document.querySelector('.upload-progress-container');
                                progressDiv.classList.remove('d-none');
                                const progressBar = document.querySelector('.upload-progress .progress-bar');
                                
                                let tradesWithoutProject = false;
                                let first_trade_id = 0;
                                let first_chunk = true;
                                
                                let upload_chunk = (offset, chunk_size, file, respondent_id, email) => {
                                  axios
                                  .post(`${api.API_URL}/api/bulk-trade/`, { 
                                    raw_link: file,
                                    respondent_id: respondent_id,
                                    user_email: email,
                                    offset: offset,
                                    chunk_size: chunk_size,
                                  })
                                  .then((result) => {
                                    if(result.terminate == 'true'){
                                      progressDiv.classList.add('d-none');
                                      toast(
                                        <div style={{fontSize:'125%'}}>
                                          
                                          <br />
                                          {result.msg}
                                        </div>,
                                        {
                                          autoClose: false,
                                          position: "top-center",
                                          hideProgressBar: false,
                                          className: "bg-success text-white",
                                          progress: undefined,
                                          toastId: "",
                                        }
                                      );
                                      
                                    } else {
                                      if( first_chunk === true){
                                        first_trade_id = result.first_trade_id[0];
                                        first_chunk = false;
                                      }
                                      if (result.moreInfo[0] == true) {
                                        tradesWithoutProject = true;
                                      }
                                      if( offset + chunk_size < data.row_success ){
                                        currentTradeSpan.innerHTML = offset + chunk_size;
                                        progressBar.style.width = `${((offset + chunk_size) / data.row_success) * 100}%`;
                                        upload_chunk(offset + chunk_size, chunk_size, file, respondent_id, email);
                                      } else {
                                        // axios.get(URL.send_new_bulk_trade_notification+'?user_email='+email+'&current_url='+window.location.href+'&respondent_name='+respondentName+'&count='+data.row_success+'&first_trade_id='+first_trade_id);
                                        progressDiv.classList.add('d-none');
                                        if (tradesWithoutProject) {
                                            toast(
                                            <div style={{fontSize:'125%'}}>
                                              {data.row_success} trades successfully created.
                                              <br />
                                              <br />
                                              We observed blank or non-existent
                                              project IDs in your upload. When this information becomes available, please update these trades
                                              and assign the project from the registry.
                                            </div>,
                                            {
                                              autoClose: false,
                                              position: "top-center",
                                              hideProgressBar: false,
                                              className: "bg-success text-white",
                                              progress: undefined,
                                              toastId: "",
                                            }
                                          );
                                        } else {
                                          toast(
                                            <div style={{fontSize:'125%'}}>
                                              <br />
                                              <br />
                                              {data.row_success} trades successfully created.
                                            </div>,
                                            {
                                              autoClose: false,
                                              position: "top-center",
                                              hideProgressBar: false,
                                              className: "bg-success text-white",
                                              progress: undefined,
                                              toastId: "",
                                            }
                                          );
                                          getPage(1);
                                        }
                                      }
                                      //fetchData(10, 0);
                                      
                                    }
                                  })
                                  .catch((error) => {
                                    let msg = 'An error occurred while processing your file. Please try again.';
                                    if( error?.data?.msg ){
                                      msg = error.data.msg;
                                    } 
                                    toast.error(msg, {
                                      position: 'top-right',
                                      hideProgressBar: false,
                                      progress: undefined,
                                      toastId: '',
                                    });
                                    
                                    
                                  });
                                };

                                let offset = 0;
                                let chunk_size = 5;
                                let file = data.raw_file;
                                let respondent_id = Login?.user?.is_superuser ? respondentUser ? respondentUser
                                    : localStorage.getItem('respondentId')
                                  : Login?.user?.respondent_id;
                                let email = Login?.user?.email;
                                upload_chunk(offset, chunk_size, file, respondent_id, email);      
                              
                            } else {
                              console.log('Trade Error');
                              console.log({ result, data });
                              console.log('fail');
                            }
                          }}
                          render={(launch) => {
                            return (
                              <>
                                {Login?.user?.is_superuser ? (
                                  <div className='me-4'>
                                    <Label
                                      for='buyer_profit_status'
                                      className='form-label'
                                    >
                                      Select Respondent to upload file
                                    </Label>
                                    <Select
                                      isLoading={loading}
                                      onChange={(e) => {
                                        setRespondentUser(e?.value);
                                        localStorage.setItem(
                                          'respondentId',
                                          e?.value
                                        );
                                        launch();
                                      }}
                                      options={respondentData}
                                      name='respondent_id'
                                      id='respondent_id'
                                      classNamePrefix='select2-selection form-select'
                                    />
                                  </div>
                                ) : (
                                  <button
                                    data-csvbox
                                    disabled={
                                      Login?.user?.is_superuser ||
                                      Login?.user?.is_staff
                                    }
                                    onClick={() => {
                                      if (Login?.user?.respondent_id) {
                                        launch();
                                      } else {
                                        // CHADAUTH  Not sure why we log out the user here
                                        dispatch(logoutUser());
                                        window.location.href =
                                          window?.location?.origin;
                                        toast.error(
                                          'You are unable to perform this action because you do not have a respondent',
                                          {
                                            position: 'top-right',
                                            hideProgressBar: false,
                                            progress: undefined,
                                            toastId: '',
                                          }
                                        );
                                      }
                                    }}
                                    className='btn btn-success add-btn me-2'
                                  >
                                    <img src={uploadIcon} width="20" /> &nbsp;&nbsp;Upload Trade File
                                  </button>
                                )}
                              </>
                            );
                          }}
                        >
                          Import
                        </CSVBoxButton>
                        <button
                          type='button'
                          // disabled={
                          //   Login?.user?.is_superuser || Login?.user?.is_staff
                          // }
                          className='btn btn-success add-btn'
                          id='create-btn'
                          onClick={() => setModal(true)}
                        >
                          <i className='ri-add-line align-bottom '></i> Submit New Trade
                        </button>{' '}
                      </div>
                    </div>
                  </CardHeader>

                  <CardBody className="p-0 card-border-info ">
                      <Alert color='info' className="border-0 rounded-top rounded-0 m-0 d-flex align-items-center">
                          <i className="ri-information-line me-2 fs-16"
                          />
                          <div className="flex-grow-1 text-truncate">
                          Privacy Note: this page and the trades list contained herein is private per EM Respondent organization.                         
                          </div>
                      </Alert>
                  </CardBody>

                  <CardBody>
                    { tradesLoading && <div className="loadingOverlay">
                      <div className="loadingSpinner"><Spinner /></div>
                    </div> }
                    <div className='table-responsive table-card mb-1'>
                      <table role="table" className="align-middle table-nowrap table table-hover" style={{tableLayout: 'fixed'}}>
                        <thead className="table-success text-muted">
                            <tr role="row">
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '120px'}}>
                                  Date
                                    <input
                                      type='date'
                                      className='form-control form-control-sm'
                                      placeholder='Date'
                                      max={moment(new Date()).format('yyyy-MM-DD')}
                                      onChange={(e) => {updateDateTransaction(e.target.value)}}
                                    />
                              </th>
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '360px'}}>
                                  Project name
                                  <input id="top_project_name" className="form-control form-control-sm" placeholder="Project Name" value={projectName || ''}
                                          onChange={(e) => updateProjectName(e.target.value)} />
                              </th>
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>
                                  Project Code
                                  <input id="top_project_code" className="form-control form-control-sm" placeholder="Project Code" value={projectCode || ''} 
                                      onChange={(e) => updateProjectCode(e.target.value)} />
                              </th>
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '200px'}}>
                                  Buyer
                                  <input id="top_buyer" className="form-control form-control-sm" placeholder="Buyer" value={buyer || ''} 
                                      onChange={(e) => updateBuyer(e.target.value)} />
                              </th>
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '120px'}}>
                                  Volume (TCO2e)
                                  <input id="top_volume" className="form-control form-control-sm" placeholder="Volume (TCO2e)" value={volume || ''} 
                                      onChange={(e) => updateVolume(e.target.value)} />
                              </th>
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '100px'}}>
                                  USD/TCO2e
                                  <input id="top_usd_tc02e" className="form-control form-control-sm" placeholder="USD/TCO2e" value={priceTonne || ''} 
                                      onChange={(e) => updatePriceTonne(e.target.value)} />
                              </th>
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '100px'}}>
                                  Value (USD)
                                  <input id="top_value_usd" className="form-control form-control-sm" placeholder="USD/TCO2e" value={valueUSD || ''} 
                                      onChange={(e) => updateValueUSD(e.target.value)} />
                              </th>
                              <th colSpan={1} role="columnheader" style={{minWidth: '0px', width: '150px'}}>action</th>
                            </tr>
                        </thead>
                      
                    {trades?.length > 0 ? (
                      <tbody id="trade_table_body" role="rowgroup">
                        {trades.map((trade) => (
                          <tr key={trade?.trade_id}>
                            
                            <td role="cell" style={{minWidth: '0px', width: '120px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                              {trade?.trade_month ? trade?.trade_month.toString().padStart(2,'0')+'/'+trade?.trade_year :
                                trade?.trade_quarter ? 'Q'+trade?.trade_quarter.toString()+' '+trade?.trade_year :
                                  trade?.trade_semester ? 'SEM'+trade?.trade_semester.toString()+' '+trade?.trade_year :
                                    trade?.trade_year ? trade?.trade_year :
                                      trade?.date_transaction ? moment.utc(trade?.date_transaction).format('MM/DD/YYYY') : ''
                               }
                            </td>
                            <td role="cell" style={{minWidth: '0px', width: '360px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                              {trade?.projectid !== null && trade?.projectid?.projectid == 0 && trade?.project_name != "" ? 
                                trade?.project_name : trade?.projectid?.project_name}
                            </td>
                            <td role="cell" style={{minWidth: '0px', width: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                              {trade?.projectid?.project_code}
                            </td>
                            <td role="cell" style={{minWidth: '0px', width: '200px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                              {trade?.buyer?.buyer_name}
                            </td>
                            <td role="cell" style={{minWidth: '0px', width: '120px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                              {trade?.volume_tco2e}
                            </td>
                            <td role="cell" style={{minWidth: '0px', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                              {trade?.price_usd_tcoe ? Math.round(trade?.price_usd_tcoe * 100) / 100 : ''}
                            </td>
                            <td role="cell" style={{minWidth: '0px', width: '100px', overflow: 'hidden', textOverflow: 'ellipsis'}} >
                              {trade?.value_usd ? Math.round(trade?.value_usd * 100) / 100 : ''}
                            </td>
                            
                            <td role="cell" style={{minWidth: '0px', width: '150px'}}>
                            <ul className='list-inline hstack gap-2 mb-0'>
                                <li className='list-inline-item edit'>
                                  <Link
                                    to={`/trade/edit/${trade.trade_id}`}
                                    className='text-primary d-inline-block edit-item-btn'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleEditTradeClick(trade);
                                    }}
                                  >
                                    <i className='ri-pencil-fill fs-16'></i>
                                  </Link>
                                </li>
                                <li className='list-inline-item'>
                                  <Link
                                    to='#'
                                    className='text-danger d-inline-block remove-item-btn'
                                    onClick={() => {
                                      onClickDelete(trade);
                                    }}
                                  >
                                    <i className='ri-delete-bin-5-fill fs-16'></i>
                                  </Link>
                                </li>
                                
                              </ul>
                            </td>
                            
                          </tr>
                        ))}
                          

                          
                      </tbody>
                      
                      
                    ) : isTradeSuccess && !error ? (
                      <tbody><tr><td colSpan={8}><div style={{padding:'20px', width:'100%'}}>No matching trades found</div></td></tr></tbody>
                    ) : (
                      <Loader error={error} />
                    )}
                      </table>
                    </div>

                    {totalTrades  ? (
                    <div className="justify-content-md-end justify-content-center align-items-center p-2 row">
                      <div className="col">Total Results : {totalTrades} </div>
                      
                      {totalTrades > 10 && (
                      <>
                      <div className="col-md-auto col">
                        <div className="d-flex gap-1">
                          <button id="prev_page_btn" onClick={() => { getPage(currentPage - 1); }} type="button" disabled={currentPage == 1 && ('disabled')} className={currentPage == 1 ? 'disabled btn btn-primary' : 'btn btn-primary'}>&lt;</button>
                        </div>
                      </div>
                      <div className="col-md-auto d-none d-md-block col">Page <strong>{currentPage} of {Math.ceil(totalTrades/tradesPerPage)}</strong></div>
                      <div className="col-md-auto col">
                        <input ref={paginationInput} onChange={(e) => { handlePaginationInputChange(e.target); }}  type="number" className="form-control" defaultValue={1} max={Math.ceil(totalTrades/tradesPerPage)} style={{width: '70px'}} />
                      </div>
                      <div className="col-md-auto col">
                        <div className="d-flex gap-1">
                          <button id="next_page_btn" onClick={() => { getPage(currentPage + 1); }} type="button" disabled={currentPage == Math.ceil(totalTrades/tradesPerPage) && ('disabled')} className={currentPage == Math.ceil(totalTrades/tradesPerPage) ? "disabled btn btn-primary" : "btn btn-primary"}>&gt;</button>
                        </div>
                      </div>
                      </>
                      )}

                    </div>
                    ) : ''}
                  </CardBody>

                  
                </Card>
              </Col>
            </Row>
          </Container>
      </div>
    </>
  );
  
};


export default withRouter(TradeList);
