import {
  GET_PROJECT_LIST,
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAIL,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
  GET_TYPE_LIST,
  SET_PROJECT_LOADING,
  GET_PROJECT,
  GET_STANDARD_LIST,
  GET_COBENEFIT_LIST,
  GET_SDG_LIST,
} from './actionType';

const INIT_STATE = {
  projectList: [],
  project: {},
  projectLoading: true,
  projectListCount: null,
  projectPrev: null,
  projectNext: null,
  projectListNumPages: null,
  typeList: [],
  standardList: [],
  coBenefitList: [],
  sdgList: [],
};

const Project = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PROJECT_LIST:
          return {
            ...state,
            projectList: action.payload.data.results,
            projectNext: parseInt(
              action.payload.data.next?.split('page=')[1]?.split('&')[0]
            ),
            projectPrev: parseInt(
              action.payload.data.previous?.split('page=')[1]?.split('&')[0]
            ),
            projectListCount: action.payload.data.count,
            projectListNumPages: action.payload.data.num_pages,
            isProjectCreated: false,
            isProjectSuccess: true,
          };

        case GET_PROJECT:
          return {
            ...state,
            project: action.payload.data,
          };

        case GET_TYPE_LIST:
          return {
            ...state,
            typeList: action.payload.data.results,
          };

        case GET_STANDARD_LIST:
          return {
            ...state,
            standardList: action.payload.data.results,
          };

        case GET_COBENEFIT_LIST:
          return {
            ...state,
            coBenefitList: action.payload.data.results,
          };
        
        case GET_SDG_LIST:
          return {
            ...state,
            sdgList: action.payload.data.results,
          };

        default:
          return { ...state };
      }

    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_PROJECT_LIST:
          return {
            ...state,
            error: action.payload.error,
            isProjectCreated: false,
            isProjectSuccess: false,
          };

        default:
          return { ...state };
      }

    case GET_PROJECT_LIST: {
      return {
        ...state,
        isProjectCreated: false,
      };
    }

    case GET_STANDARD_LIST:
      //TODO Error logic
      return {
        ...state,
      };
    
    case GET_COBENEFIT_LIST:
      //TODO Error logic
      return {
        ...state,
      };

    case GET_SDG_LIST:
      //TODO Error logic
      return {
        ...state,
      };

    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        isProjectCreated: true,
        projectList: [...state.projectList, action.payload],
        isProjectAdd: true,
        isProjectAddFail: false,
      };

    case ADD_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        isProjectAdd: false,
        isProjectAddFail: true,
      };

    case UPDATE_PROJECT_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        projectList: state.projectList.map((project) =>
          project.projectid.toString() === action.payload.projectid.toString()
            ? { ...project, ...action.payload }
            : project
        ),
        isProjectUpdate: true,
        isProjectUpdateFail: false,
      };

    case UPDATE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        isProjectUpdate: false,
        isProjectUpdateFail: true,
      };

    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        projectList: state.projectList.filter(
          (project) =>
            project.projectid.toString() !==
            action.payload.project.projectid.toString()
        ),
        isProjectDelete: true,
        isProjectDeleteFail: false,
      };

    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        error: action.payload,
        isProjectDelete: false,
        isProjectDeleteFail: true,
      };

    case SET_PROJECT_LOADING:
      return {
        ...state,
        projectLoading: action.payload.value,
      };

    default:
      return { ...state };
  }
};

export default Project;
