import React, { useEffect, useState } from 'react';
import Navbar from './navbar';
import Home from './home';
import Client from './client';
import Cta from './cta';
import Footer from './footer';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../../Components/Common/Spinner';
import { useLocation, withRouter } from 'react-router-dom';
import {
  Alert,
  Container,
  CardHeader,
  Row,
  Col,
  Card,
  CardBody, 
} from 'reactstrap'; 
import TotalVolumeBarChartPublic from '../Charts/components/TotalVolume-BarChart-Public';
import VWAPLineChartPublic from '../Charts/components/VWAP-Line-Chart-Public';
import VolumeByCountryMapPublic from '../Charts/components/VolumeByCountry-Map-Public';

import Sidebar from '../../Layouts/Sidebar';


//import actions
import {
    changeLayoutMode,
} from "../../store/actions";



const Index = (props) => {
  const loading = useSelector((state) => state?.Login?.loading);

  let location = useLocation();
    const [headerClass, setHeaderClass] = useState("");
    const dispatch = useDispatch();
    const {
        layoutType,
        layoutModeType,
        
    } = useSelector(state => ({
        layoutType: state.Layout.layoutType,
        layoutModeType: state.Layout.layoutModeType,
    }));

    const onChangeLayoutMode = (value) => {
      if (changeLayoutMode) {
          dispatch(changeLayoutMode(value));
      }
  };

  document.title = ' Voluntary Carbon Market Data | Ecosystem Marketplace ';

  if (loading) return <Spinner />;

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" data-layout="horizontal">
        <Navbar />
        {!location?.pathname?.includes('not-found') && (
          <>
              <Sidebar layoutType={layoutType} />
          </>
        )}
        
        {/* <Home /> */}
        
        
        <div data-layout='horizontal'>
          <Container fluid={true}>
            <div className="page-content">
          <Row>
            <Col xl={12}>
              <Card className='bg-primary'>
                <CardBody className='p-0'>
                  <Row className='align-items-end'>
                    <Col sm={10} className=''>
                      <div className='p-3 ps-4'>
                        <h4 className='display-6 fw-bold text-white'>
                          Welcome to the Global Carbon Markets Hub
                        </h4>
                        <p className='fs-16 lh-base text-white'>
                          This is the new home for all things data from
                          Ecosystem Marketplace. 
                        
                        </p>

                        <Alert color="danger">
                        
                        <p className="fs-16 lh-base">
                          <i className='ri-information-line me-2 align-middle fs-16'></i>
                          We're currently in <b>Beta</b>, so a few things to note:  
                        </p>
                        <ul className=" fs-16">
                            <li className="">Currently only trades from 2020 onwards are included.</li>
                            <li className="">If you find any <i className=' ri-bug-line align-middle fs-16 fw-bold'></i>                            
                            bugs, <a href="mailto:hub@ecosystemmarketplace.com" className='text-danger fw-bold'>please let us know</a>!</li>
                            <li className="">Please be generous with your {' '}
                              <a href="http://roadmap.ecosystemmarketplace.com/feature-requests"
                              target='_blank'
                              className='fw-bold text-danger'
                              >feedback and feature requests</a>.  </li>
                            <li className="">Help is always on hand via the menu.</li> 
                        </ul>
                        </Alert>
                      </div>
                    </Col> 
                  </Row>
                  <Alert
                    color='danger'
                    className='rounded-top alert-solid alert-label-icon border-0 rounded-0 m-0 d-flex align-items-center'>
                    <i className='ri-user-smile-line label-icon'></i>
                    <div className='flex-grow-1 text-truncate'>
                        We're a not-for-profit here to serve you and the sector. 
                        {' '}
                        <a href="http://roadmap.ecosystemmarketplace.com/feature-requests"
                         target='_blank'
                         className='fw-bold text-white'
                         >
                        Be abundant with your feature requests
                        </a>. The more there are, the better we can serve you!
                    </div>
                  </Alert>
                </CardBody>
                <CardBody className="p-0 card-border-info ">
                  <Alert color='info' className="border-0 rounded-top rounded-0 m-0 d-flex align-items-center">
                      <i className="ri-information-line me-2 fs-16"
                      />
                      <div className="flex-grow-1 text-truncate">
                      Privacy Note: we aggregate and protect your data.                     
                      </div>
                  </Alert>
                </CardBody>
              </Card>
            </Col>
          </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody className="p-0">
                    <TotalVolumeBarChartPublic />
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <CardBody className="p-0">
                    <VWAPLineChartPublic />
                </CardBody>
              </Col>
            </Row>
            <Row>
              <Card>
                <CardBody className="p-0">
                    <VolumeByCountryMapPublic />
                </CardBody>
              </Card>
            </Row>
            </div>
          </Container>
        </div>
        <Client />
        <Cta />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Index;
