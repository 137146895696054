import React, { useEffect, useState } from 'react';
import Navbar from '../../Landing/navbar';
import Footer from '../../Landing/footer';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from '../../../Components/Common/Spinner';


import Sidebar from '../../../Layouts/Sidebar';
import DemosAndFAQsPublic from './DemosAndFAQs-Public';

//import actions
import {
    changeLayoutMode,
} from "../../../store/actions";


const Index = (props) => {
  const loading = useSelector((state) => state?.Login?.loading);

  
  const [headerClass, setHeaderClass] = useState("");
  const dispatch = useDispatch();
  const {
      layoutType,
      layoutModeType,
      
  } = useSelector(state => ({
      layoutType: state.Layout.layoutType,
      layoutModeType: state.Layout.layoutModeType,
  }));

  const onChangeLayoutMode = (value) => {
      if (changeLayoutMode) {
          dispatch(changeLayoutMode(value));
      }
  };
  document.title = 'Demos & FAQs | Ecosystem Marketplace';

  if (loading) return <Spinner />;

  return (
    <React.Fragment>
      <div className="layout-wrapper landing" data-layout="horizontal">
        <Navbar />
        <Sidebar layoutType={layoutType} />

        <DemosAndFAQsPublic />

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Index;
