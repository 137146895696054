import React, { useEffect, useState } from 'react';
import { URL } from '../../../constants';
import axios from 'axios';
import { Col, Row, CardHeader } from 'reactstrap'; 
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from 'highcharts/highcharts-more';
HC_more(Highcharts);



const StandardsScatterWithJitterPrivate = () => { 

    const [seriesData, setSeriesData] = useState([]);
    const [dataReady, setDataReady] = useState(false);
    const [years, setYears] = useState([]);
    const [categories, setCategories] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [yearMin, setYearMin] = useState(0);
    const [yearMax, setYearMax] = useState(0);
    const [catOrdinals, setCatOrdinals] = useState({});

    
    const colors = ['#accf62', '#602451', '#3a81c5', '#375f47', '#c02a27', '#c37224', '#599bb4', '#86493c', '#5d666f', '#bdc8d3'];
    const radius_multiplier = 10000;


    let initialBubbleChartOptions = {
        chart: {
            type: 'scatter',
        },
        
        colors,

        title: {
            text: '',
        },
        plotOptions: {
            scatter: {
                showInLegend: false,
                jitter: {
                    x: 0.4,
                    y: 0
                },
                marker: {
                    radius: 2,
                    symbol: 'circle'
                },
                tooltip: {
                    pointFormat: 'Measurement: {point.y:.3f}'
                }
            }
        },
        // tooltip: {
        //     useHTML: true,
        //     headerFormat: '<table>',
        //     pointFormat: '<tr><th colspan="2"><h4>{point.cluster}</h4></th></tr>' +
        //         '<tr><th>Category:</th><td>{point.category}</td></tr>' +
        //         '<tr><th>Average Price: </th><td> ${point.vwap}</td></tr>' +
        //         '<tr><th>Volume:</th><td> {point.volume:,.0f} tCO2e</td></tr>',
        //     footerFormat: '</table>',
        //     followPointer: true
        // },
        // colorByPoint: true,
        // colors: colors,

    };

    const [chartOptions, setChartOptions] = useState({});

    let tempdata = [
      {
        "year_transact": 2024,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Improved Forest Management (IFM)",
        "volume_tco2e": 271742,
        "value_USD": 4471384.08,
        "vwap": 16.45,
        "n_resp": 4,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2024,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Afforestation-Reforestation and Revegetation (ARR)",
        "volume_tco2e": 192896,
        "value_USD": 2278512.4204116305,
        "vwap": 11.81,
        "n_resp": 5,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2024,
        "project_category": "Forestry and Land Use",
        "project_cluster": "REDD+ All",
        "volume_tco2e": 173728,
        "value_USD": 1578042.7230607031,
        "vwap": 9.08,
        "n_resp": 8,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2024,
        "project_category": "Household / Community Devices",
        "project_cluster": "Clean cookstove distribution",
        "volume_tco2e": 332573,
        "value_USD": 2241454.2800000003,
        "vwap": 6.74,
        "n_resp": 3,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2024,
        "project_category": "Renewable Energy",
        "project_cluster": "Hydropower",
        "volume_tco2e": 71176,
        "value_USD": 204867.13417206076,
        "vwap": 2.88,
        "n_resp": 3,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2023,
        "project_category": "Agriculture",
        "project_cluster": "Sustainable Agricultural Land Management",
        "volume_tco2e": 4154755,
        "value_USD": 29044486.832753733,
        "vwap": 6.99,
        "n_resp": 4,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2023,
        "project_category": "Agriculture",
        "project_cluster": "Livestock Methane",
        "volume_tco2e": 490940,
        "value_USD": 740848.8152932199,
        "vwap": 1.51,
        "n_resp": 5,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2023,
        "project_category": "Agriculture",
        "project_cluster": "Grassland / Rangeland management",
        "volume_tco2e": 50057,
        "value_USD": 681572.613721,
        "vwap": 13.62,
        "n_resp": 7,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2023,
        "project_category": "Agriculture",
        "project_cluster": "Soil Carbon",
        "volume_tco2e": 8033,
        "value_USD": 141016.06,
        "vwap": 17.55,
        "n_resp": 3,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2023,
        "project_category": "Chemical Proc. / Industrial Mfg.",
        "project_cluster": "N20 Destruction: Adipic / Nitric Acid",
        "volume_tco2e": 5241178.86,
        "value_USD": 19721386.49,
        "vwap": 3.76,
        "n_resp": 4,
        "cat_ordinal": 2
      },
      {
        "year_transact": 2023,
        "project_category": "Chemical Proc. / Industrial Mfg.",
        "project_cluster": "Unspecified Chemical Processes / Industrial Manufacturing",
        "volume_tco2e": 4267022,
        "value_USD": 16519062.413123997,
        "vwap": 3.87,
        "n_resp": 5,
        "cat_ordinal": 2
      },
      {
        "year_transact": 2023,
        "project_category": "Chemical Proc. / Industrial Mfg.",
        "project_cluster": "Refrigerant Gas - HFC",
        "volume_tco2e": 910196,
        "value_USD": 6601212.499303289,
        "vwap": 7.25,
        "n_resp": 3,
        "cat_ordinal": 2
      },
      {
        "year_transact": 2023,
        "project_category": "Chemical Proc. / Industrial Mfg.",
        "project_cluster": "Other- Chemical Processes / Industrial Manufacturing",
        "volume_tco2e": 816365,
        "value_USD": 3768907.481596387,
        "vwap": 4.62,
        "n_resp": 4,
        "cat_ordinal": 2
      },
      {
        "year_transact": 2023,
        "project_category": "Chemical Proc. / Industrial Mfg.",
        "project_cluster": "Fugitive Emissions",
        "volume_tco2e": 739317,
        "value_USD": 3283939.2217515307,
        "vwap": 4.44,
        "n_resp": 3,
        "cat_ordinal": 2
      },
      {
        "year_transact": 2023,
        "project_category": "Chemical Proc. / Industrial Mfg.",
        "project_cluster": "Coal Mine Methane",
        "volume_tco2e": 274186,
        "value_USD": 698578.0414979999,
        "vwap": 2.55,
        "n_resp": 3,
        "cat_ordinal": 2
      },
      {
        "year_transact": 2023,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Energy Efficiency",
        "volume_tco2e": 8633019.7,
        "value_USD": 29810651.993924,
        "vwap": 3.45,
        "n_resp": 7,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2023,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Other - Energy Efficiency / Fuel Switching",
        "volume_tco2e": 227468,
        "value_USD": 1222723.44539318,
        "vwap": 5.38,
        "n_resp": 6,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2023,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Fuel Switching",
        "volume_tco2e": 204282,
        "value_USD": 511781.32963117573,
        "vwap": 2.51,
        "n_resp": 3,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2023,
        "project_category": "Forestry and Land Use",
        "project_cluster": "REDD+ All",
        "volume_tco2e": 28245013.8878299,
        "value_USD": 222276365.94456425,
        "vwap": 7.87,
        "n_resp": 42,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2023,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Afforestation-Reforestation and Revegetation (ARR)",
        "volume_tco2e": 3472732,
        "value_USD": 57623497.14559047,
        "vwap": 16.59,
        "n_resp": 27,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2023,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Improved Forest Management (IFM)",
        "volume_tco2e": 2402018.846,
        "value_USD": 38933210.74125684,
        "vwap": 16.21,
        "n_resp": 24,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2023,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Unspecified Forestry and Land Use",
        "volume_tco2e": 962788.6799999999,
        "value_USD": 19366704.903587025,
        "vwap": 20.12,
        "n_resp": 8,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2023,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Agro-Forestry",
        "volume_tco2e": 700988,
        "value_USD": 8119913.894441091,
        "vwap": 11.58,
        "n_resp": 7,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2023,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Blue Carbon",
        "volume_tco2e": 379686,
        "value_USD": 3163584.0693122637,
        "vwap": 8.33,
        "n_resp": 7,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2023,
        "project_category": "Household / Community Devices",
        "project_cluster": "Clean cookstove distribution",
        "volume_tco2e": 7869959,
        "value_USD": 59893372.6235563,
        "vwap": 7.61,
        "n_resp": 21,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2023,
        "project_category": "Household / Community Devices",
        "project_cluster": "Biogas - Community",
        "volume_tco2e": 688924,
        "value_USD": 5559741.810454959,
        "vwap": 8.07,
        "n_resp": 5,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2023,
        "project_category": "Household / Community Devices",
        "project_cluster": "Energy Efficiency",
        "volume_tco2e": 526360.78,
        "value_USD": 4607124.84604908,
        "vwap": 8.75,
        "n_resp": 4,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2023,
        "project_category": "Household / Community Devices",
        "project_cluster": "Clean Water Distribution",
        "volume_tco2e": 318850,
        "value_USD": 3709831.7723920774,
        "vwap": 11.64,
        "n_resp": 5,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2023,
        "project_category": "Renewable Energy",
        "project_cluster": "Wind",
        "volume_tco2e": 8973649.4414821,
        "value_USD": 38440681.783143304,
        "vwap": 4.28,
        "n_resp": 27,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2023,
        "project_category": "Renewable Energy",
        "project_cluster": "Hydropower",
        "volume_tco2e": 6210373.02,
        "value_USD": 19109580.39376013,
        "vwap": 3.08,
        "n_resp": 18,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2023,
        "project_category": "Renewable Energy",
        "project_cluster": "Solar",
        "volume_tco2e": 5759955.339999999,
        "value_USD": 20489343.80725776,
        "vwap": 3.56,
        "n_resp": 16,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2023,
        "project_category": "Renewable Energy",
        "project_cluster": "Biogas",
        "volume_tco2e": 3016686,
        "value_USD": 16140788.0230615,
        "vwap": 5.35,
        "n_resp": 20,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2023,
        "project_category": "Renewable Energy",
        "project_cluster": "Geothermal",
        "volume_tco2e": 2226883,
        "value_USD": 10180525.509455377,
        "vwap": 4.57,
        "n_resp": 6,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2023,
        "project_category": "Renewable Energy",
        "project_cluster": "Unspecified Renewable Energy",
        "volume_tco2e": 1711798,
        "value_USD": 4952578.89601163,
        "vwap": 2.89,
        "n_resp": 8,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2023,
        "project_category": "Renewable Energy",
        "project_cluster": "Biomass / Biochar",
        "volume_tco2e": 743160,
        "value_USD": 1863549.4865986998,
        "vwap": 2.51,
        "n_resp": 10,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2023,
        "project_category": "Waste Disposal",
        "project_cluster": "Waste Gas Recovery",
        "volume_tco2e": 1276817,
        "value_USD": 8179649.375943884,
        "vwap": 6.41,
        "n_resp": 7,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2023,
        "project_category": "Waste Disposal",
        "project_cluster": "Waste Gas Avoidance",
        "volume_tco2e": 110793.55999999998,
        "value_USD": 694713.1593364617,
        "vwap": 6.27,
        "n_resp": 3,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2023,
        "project_category": "Waste Disposal",
        "project_cluster": "Recycling",
        "volume_tco2e": 58781.16999999993,
        "value_USD": 1065818.6059561989,
        "vwap": 18.13,
        "n_resp": 3,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2022,
        "project_category": "Agriculture",
        "project_cluster": "Grassland / Rangeland management",
        "volume_tco2e": 2098209,
        "value_USD": 21394919.226656757,
        "vwap": 10.20,
        "n_resp": 5,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2022,
        "project_category": "Agriculture",
        "project_cluster": "Sustainable Agricultural Land Management",
        "volume_tco2e": 1420346,
        "value_USD": 18778287.57192244,
        "vwap": 13.22,
        "n_resp": 7,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2022,
        "project_category": "Agriculture",
        "project_cluster": "Livestock Methane",
        "volume_tco2e": 261838,
        "value_USD": 1463231.03,
        "vwap": 5.59,
        "n_resp": 3,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2022,
        "project_category": "Chemical Proc. / Industrial Mfg.",
        "project_cluster": "Fugitive Emissions",
        "volume_tco2e": 6792241,
        "value_USD": 38569030.730000004,
        "vwap": 5.68,
        "n_resp": 3,
        "cat_ordinal": 2
      },
      {
        "year_transact": 2022,
        "project_category": "Chemical Proc. / Industrial Mfg.",
        "project_cluster": "N20 Destruction: Adipic / Nitric Acid",
        "volume_tco2e": 3456810,
        "value_USD": 15700777.057054998,
        "vwap": 4.54,
        "n_resp": 3,
        "cat_ordinal": 2
      },
      {
        "year_transact": 2022,
        "project_category": "Chemical Proc. / Industrial Mfg.",
        "project_cluster": "Other- Chemical Processes / Industrial Manufacturing",
        "volume_tco2e": 436786,
        "value_USD": 2366505.0658986825,
        "vwap": 5.42,
        "n_resp": 5,
        "cat_ordinal": 2
      },
      {
        "year_transact": 2022,
        "project_category": "Chemical Proc. / Industrial Mfg.",
        "project_cluster": "Refrigerant Gas - Ozone Depleting",
        "volume_tco2e": 161758,
        "value_USD": 657125.2555120088,
        "vwap": 4.06,
        "n_resp": 4,
        "cat_ordinal": 2
      },
      {
        "year_transact": 2022,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Energy Efficiency",
        "volume_tco2e": 3373588,
        "value_USD": 17934387.526149224,
        "vwap": 5.32,
        "n_resp": 6,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2022,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Other - Energy Efficiency / Fuel Switching",
        "volume_tco2e": 1350096,
        "value_USD": 5030832.949999999,
        "vwap": 3.73,
        "n_resp": 3,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2022,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Fuel Switching",
        "volume_tco2e": 607325.02,
        "value_USD": 4414466.302679339,
        "vwap": 7.27,
        "n_resp": 8,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2022,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Energy Efficiency- Industrial",
        "volume_tco2e": 521972,
        "value_USD": 2372146.25,
        "vwap": 4.54,
        "n_resp": 4,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2022,
        "project_category": "Forestry and Land Use",
        "project_cluster": "REDD+ All",
        "volume_tco2e": 57356405.46381819,
        "value_USD": 584217339.4374038,
        "vwap": 10.19,
        "n_resp": 45,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2022,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Unspecified Forestry and Land Use",
        "volume_tco2e": 36968470,
        "value_USD": 326368314.1674231,
        "vwap": 8.83,
        "n_resp": 4,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2022,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Afforestation-Reforestation and Revegetation (ARR)",
        "volume_tco2e": 9572796,
        "value_USD": 115142950.70623629,
        "vwap": 12.03,
        "n_resp": 39,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2022,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Improved Forest Management (IFM)",
        "volume_tco2e": 4509331.49,
        "value_USD": 66171192.03987944,
        "vwap": 14.67,
        "n_resp": 26,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2022,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Blue Carbon",
        "volume_tco2e": 3390570.2,
        "value_USD": 39254884.51095198,
        "vwap": 11.58,
        "n_resp": 11,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2022,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Agro-Forestry",
        "volume_tco2e": 1209859,
        "value_USD": 14906135.069735177,
        "vwap": 12.32,
        "n_resp": 9,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2022,
        "project_category": "Household / Community Devices",
        "project_cluster": "Clean cookstove distribution",
        "volume_tco2e": 5946602,
        "value_USD": 51646438.27835611,
        "vwap": 8.69,
        "n_resp": 29,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2022,
        "project_category": "Household / Community Devices",
        "project_cluster": "Energy Efficiency",
        "volume_tco2e": 1081558.9000000001,
        "value_USD": 10975917.680577682,
        "vwap": 10.15,
        "n_resp": 3,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2022,
        "project_category": "Household / Community Devices",
        "project_cluster": "Clean Water Distribution",
        "volume_tco2e": 1015377,
        "value_USD": 7588954.7108308785,
        "vwap": 7.47,
        "n_resp": 10,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2022,
        "project_category": "Household / Community Devices",
        "project_cluster": "Biogas - Community",
        "volume_tco2e": 968257,
        "value_USD": 6757744.654140455,
        "vwap": 6.98,
        "n_resp": 10,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2022,
        "project_category": "Household / Community Devices",
        "project_cluster": "Lighting Efficiency",
        "volume_tco2e": 54924,
        "value_USD": 442774.2742218295,
        "vwap": 8.06,
        "n_resp": 4,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2022,
        "project_category": "Renewable Energy",
        "project_cluster": "Unspecified Renewable Energy",
        "volume_tco2e": 30123972,
        "value_USD": 144526196.25,
        "vwap": 4.80,
        "n_resp": 6,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2022,
        "project_category": "Renewable Energy",
        "project_cluster": "Wind",
        "volume_tco2e": 25836062,
        "value_USD": 109177643.21875525,
        "vwap": 4.23,
        "n_resp": 33,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2022,
        "project_category": "Renewable Energy",
        "project_cluster": "Hydropower",
        "volume_tco2e": 17665695,
        "value_USD": 51311102.69676114,
        "vwap": 2.90,
        "n_resp": 24,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2022,
        "project_category": "Renewable Energy",
        "project_cluster": "Solar",
        "volume_tco2e": 8890558,
        "value_USD": 41834780.840901464,
        "vwap": 4.71,
        "n_resp": 20,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2022,
        "project_category": "Renewable Energy",
        "project_cluster": "Biogas",
        "volume_tco2e": 5760431,
        "value_USD": 27210020.3505919,
        "vwap": 4.72,
        "n_resp": 22,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2022,
        "project_category": "Renewable Energy",
        "project_cluster": "Biomass / Biochar",
        "volume_tco2e": 3768776,
        "value_USD": 7639734.474531559,
        "vwap": 2.03,
        "n_resp": 12,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2022,
        "project_category": "Renewable Energy",
        "project_cluster": "Geothermal",
        "volume_tco2e": 698596,
        "value_USD": 4443694.172817751,
        "vwap": 6.36,
        "n_resp": 4,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2022,
        "project_category": "Transportation",
        "project_cluster": "Shipping",
        "volume_tco2e": 174588,
        "value_USD": 755753.77,
        "vwap": 4.33,
        "n_resp": 3,
        "cat_ordinal": 7
      },
      {
        "year_transact": 2022,
        "project_category": "Waste Disposal",
        "project_cluster": "Waste Gas Recovery",
        "volume_tco2e": 4953971,
        "value_USD": 37893032.57000536,
        "vwap": 7.65,
        "n_resp": 8,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2022,
        "project_category": "Waste Disposal",
        "project_cluster": "Waste Gas Avoidance",
        "volume_tco2e": 387606.73000000004,
        "value_USD": 2160716.318845716,
        "vwap": 5.57,
        "n_resp": 5,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2022,
        "project_category": "Waste Disposal",
        "project_cluster": "Recycling",
        "volume_tco2e": 34931.93,
        "value_USD": 763942.5925672237,
        "vwap": 21.87,
        "n_resp": 4,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2021,
        "project_category": "Agriculture",
        "project_cluster": "Sustainable Agricultural Land Management",
        "volume_tco2e": 510228,
        "value_USD": 4481071.11,
        "vwap": 8.78,
        "n_resp": 5,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2021,
        "project_category": "Agriculture",
        "project_cluster": "Livestock Methane",
        "volume_tco2e": 355606,
        "value_USD": 2816237.55,
        "vwap": 7.92,
        "n_resp": 4,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2021,
        "project_category": "Agriculture",
        "project_cluster": "Grassland / Rangeland management",
        "volume_tco2e": 121192,
        "value_USD": 1404461.0317928097,
        "vwap": 11.59,
        "n_resp": 7,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2021,
        "project_category": "Chemical Proc. / Industrial Mfg.",
        "project_cluster": "N20 Destruction: Adipic / Nitric Acid",
        "volume_tco2e": 10322727,
        "value_USD": 38192596.95,
        "vwap": 3.70,
        "n_resp": 4,
        "cat_ordinal": 2
      },
      {
        "year_transact": 2021,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Other - Energy Efficiency / Fuel Switching",
        "volume_tco2e": 5684746,
        "value_USD": 6093966.62,
        "vwap": 1.07,
        "n_resp": 4,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2021,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Energy Efficiency",
        "volume_tco2e": 1338550,
        "value_USD": 2517407.9022621587,
        "vwap": 1.88,
        "n_resp": 11,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2021,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Energy Efficiency- Industrial",
        "volume_tco2e": 1124930,
        "value_USD": 1930930.9800000002,
        "vwap": 1.72,
        "n_resp": 5,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2021,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Fuel Switching",
        "volume_tco2e": 1056272,
        "value_USD": 4720779.401510804,
        "vwap": 4.47,
        "n_resp": 9,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2021,
        "project_category": "Forestry and Land Use",
        "project_cluster": "REDD+ All",
        "volume_tco2e": 167104214,
        "value_USD": 869059311.6394529,
        "vwap": 5.20,
        "n_resp": 63,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2021,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Unspecified Forestry and Land Use",
        "volume_tco2e": 32365157.02,
        "value_USD": 197391729.66847998,
        "vwap": 6.10,
        "n_resp": 4,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2021,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Improved Forest Management (IFM)",
        "volume_tco2e": 24493157.5,
        "value_USD": 199334896.17836428,
        "vwap": 8.14,
        "n_resp": 33,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2021,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Afforestation-Reforestation and Revegetation (ARR)",
        "volume_tco2e": 17254468,
        "value_USD": 142468311.3543388,
        "vwap": 8.26,
        "n_resp": 60,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2021,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Blue Carbon",
        "volume_tco2e": 3660347,
        "value_USD": 27644778.448211905,
        "vwap": 7.55,
        "n_resp": 12,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2021,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Agro-Forestry",
        "volume_tco2e": 565287,
        "value_USD": 6639216.091527481,
        "vwap": 11.74,
        "n_resp": 11,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2021,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Urban Forestry",
        "volume_tco2e": 3261,
        "value_USD": 80975,
        "vwap": 24.83,
        "n_resp": 3,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2021,
        "project_category": "Household / Community Devices",
        "project_cluster": "Clean cookstove distribution",
        "volume_tco2e": 4341093,
        "value_USD": 26769817.284827173,
        "vwap": 6.17,
        "n_resp": 36,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2021,
        "project_category": "Household / Community Devices",
        "project_cluster": "Biogas - Community",
        "volume_tco2e": 1977479,
        "value_USD": 10899042.672988342,
        "vwap": 5.51,
        "n_resp": 10,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2021,
        "project_category": "Household / Community Devices",
        "project_cluster": "Lighting Efficiency",
        "volume_tco2e": 1377808,
        "value_USD": 1341385.3601778152,
        "vwap": 0.97,
        "n_resp": 7,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2021,
        "project_category": "Household / Community Devices",
        "project_cluster": "Clean Water Distribution",
        "volume_tco2e": 883572,
        "value_USD": 6945253.710536218,
        "vwap": 7.86,
        "n_resp": 17,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2021,
        "project_category": "Household / Community Devices",
        "project_cluster": "Clean cookstove and water purification distribution",
        "volume_tco2e": 95006,
        "value_USD": 633977.232241769,
        "vwap": 6.67,
        "n_resp": 5,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2021,
        "project_category": "Renewable Energy",
        "project_cluster": "Unspecified Renewable Energy",
        "volume_tco2e": 121899968,
        "value_USD": 262737871.60000002,
        "vwap": 2.16,
        "n_resp": 5,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2021,
        "project_category": "Renewable Energy",
        "project_cluster": "Wind",
        "volume_tco2e": 35032034,
        "value_USD": 82374072.69890721,
        "vwap": 2.35,
        "n_resp": 42,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2021,
        "project_category": "Renewable Energy",
        "project_cluster": "Hydropower",
        "volume_tco2e": 33285007,
        "value_USD": 64341657.716122665,
        "vwap": 1.93,
        "n_resp": 28,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2021,
        "project_category": "Renewable Energy",
        "project_cluster": "Solar",
        "volume_tco2e": 11274298,
        "value_USD": 28066392.208406106,
        "vwap": 2.49,
        "n_resp": 28,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2021,
        "project_category": "Renewable Energy",
        "project_cluster": "Biogas",
        "volume_tco2e": 6478820,
        "value_USD": 15603178.14936962,
        "vwap": 2.41,
        "n_resp": 22,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2021,
        "project_category": "Renewable Energy",
        "project_cluster": "Biomass / Biochar",
        "volume_tco2e": 3987715,
        "value_USD": 10765342.976522306,
        "vwap": 2.70,
        "n_resp": 19,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2021,
        "project_category": "Renewable Energy",
        "project_cluster": "Geothermal",
        "volume_tco2e": 2550739,
        "value_USD": 5973961.346197153,
        "vwap": 2.34,
        "n_resp": 5,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2021,
        "project_category": "Transportation",
        "project_cluster": "Public Transportation",
        "volume_tco2e": 4726534,
        "value_USD": 5110601.29,
        "vwap": 1.08,
        "n_resp": 5,
        "cat_ordinal": 7
      },
      {
        "year_transact": 2021,
        "project_category": "Transportation",
        "project_cluster": "Shipping",
        "volume_tco2e": 664279,
        "value_USD": 1075210.7169455667,
        "vwap": 1.62,
        "n_resp": 4,
        "cat_ordinal": 7
      },
      {
        "year_transact": 2021,
        "project_category": "Waste Disposal",
        "project_cluster": "Waste Gas Recovery",
        "volume_tco2e": 7398997,
        "value_USD": 25868651.632524803,
        "vwap": 3.50,
        "n_resp": 21,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2021,
        "project_category": "Waste Disposal",
        "project_cluster": "Unspecified Waste Disposal",
        "volume_tco2e": 3650599,
        "value_USD": 11410264.756760985,
        "vwap": 3.13,
        "n_resp": 5,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2021,
        "project_category": "Waste Disposal",
        "project_cluster": "Recycling",
        "volume_tco2e": 299014,
        "value_USD": 3770887.613775057,
        "vwap": 12.61,
        "n_resp": 7,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2021,
        "project_category": "Waste Disposal",
        "project_cluster": "Waste Incineration",
        "volume_tco2e": 265212,
        "value_USD": 1563085.6,
        "vwap": 5.89,
        "n_resp": 4,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2021,
        "project_category": "Waste Disposal",
        "project_cluster": "Waste Gas Avoidance",
        "volume_tco2e": 32851,
        "value_USD": 318380.1260470477,
        "vwap": 9.69,
        "n_resp": 8,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2020,
        "project_category": "Agriculture",
        "project_cluster": "Grassland / Rangeland management",
        "volume_tco2e": 162253,
        "value_USD": 1732610.1500000001,
        "vwap": 10.68,
        "n_resp": 8,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2020,
        "project_category": "Agriculture",
        "project_cluster": "Sustainable Agricultural Land Management",
        "volume_tco2e": 151800,
        "value_USD": 1752774.9200000002,
        "vwap": 11.55,
        "n_resp": 5,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2020,
        "project_category": "Agriculture",
        "project_cluster": "Livestock Methane",
        "volume_tco2e": 136643,
        "value_USD": 1194196.48,
        "vwap": 8.74,
        "n_resp": 3,
        "cat_ordinal": 1
      },
      {
        "year_transact": 2020,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Energy Efficiency",
        "volume_tco2e": 30226749,
        "value_USD": 28170321.450000003,
        "vwap": 0.93,
        "n_resp": 7,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2020,
        "project_category": "Energy Efficiency / Fuel Switching",
        "project_cluster": "Fuel Switching",
        "volume_tco2e": 254363,
        "value_USD": 1123989.105450773,
        "vwap": 4.42,
        "n_resp": 8,
        "cat_ordinal": 3
      },
      {
        "year_transact": 2020,
        "project_category": "Forestry and Land Use",
        "project_cluster": "REDD+ All",
        "volume_tco2e": 40845196.36,
        "value_USD": 188845543.70990962,
        "vwap": 4.62,
        "n_resp": 55,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2020,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Afforestation-Reforestation and Revegetation (ARR)",
        "volume_tco2e": 7950402.619999999,
        "value_USD": 57325223.50979124,
        "vwap": 7.21,
        "n_resp": 46,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2020,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Improved Forest Management (IFM)",
        "volume_tco2e": 5637847.5,
        "value_USD": 44947711.149220005,
        "vwap": 7.97,
        "n_resp": 27,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2020,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Blue Carbon",
        "volume_tco2e": 3460608,
        "value_USD": 24837569.04272436,
        "vwap": 7.18,
        "n_resp": 5,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2020,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Unspecified Forestry and Land Use",
        "volume_tco2e": 1496203,
        "value_USD": 7434284.360408328,
        "vwap": 4.97,
        "n_resp": 4,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2020,
        "project_category": "Forestry and Land Use",
        "project_cluster": "Agro-Forestry",
        "volume_tco2e": 249465,
        "value_USD": 2804624.419999999,
        "vwap": 11.24,
        "n_resp": 8,
        "cat_ordinal": 4
      },
      {
        "year_transact": 2020,
        "project_category": "Household / Community Devices",
        "project_cluster": "Clean cookstove distribution",
        "volume_tco2e": 5083411,
        "value_USD": 21436346.04584513,
        "vwap": 4.22,
        "n_resp": 29,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2020,
        "project_category": "Household / Community Devices",
        "project_cluster": "Biogas - Community",
        "volume_tco2e": 1631354,
        "value_USD": 7474672.65996025,
        "vwap": 4.58,
        "n_resp": 9,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2020,
        "project_category": "Household / Community Devices",
        "project_cluster": "Clean Water Distribution",
        "volume_tco2e": 1402821.3,
        "value_USD": 6088826.915900002,
        "vwap": 4.34,
        "n_resp": 13,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2020,
        "project_category": "Household / Community Devices",
        "project_cluster": "Clean cookstove and water purification distribution",
        "volume_tco2e": 326057,
        "value_USD": 1257605.482954201,
        "vwap": 3.86,
        "n_resp": 4,
        "cat_ordinal": 5
      },
      {
        "year_transact": 2020,
        "project_category": "Renewable Energy",
        "project_cluster": "Unspecified Renewable Energy",
        "volume_tco2e": 49158828,
        "value_USD": 28300321.720000003,
        "vwap": 0.58,
        "n_resp": 5,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2020,
        "project_category": "Renewable Energy",
        "project_cluster": "Wind",
        "volume_tco2e": 23724849,
        "value_USD": 41472079.49006317,
        "vwap": 1.75,
        "n_resp": 30,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2020,
        "project_category": "Renewable Energy",
        "project_cluster": "Hydropower",
        "volume_tco2e": 8808813,
        "value_USD": 12096032.33630421,
        "vwap": 1.37,
        "n_resp": 21,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2020,
        "project_category": "Renewable Energy",
        "project_cluster": "Solar",
        "volume_tco2e": 8722130,
        "value_USD": 10906620.268907256,
        "vwap": 1.25,
        "n_resp": 18,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2020,
        "project_category": "Renewable Energy",
        "project_cluster": "Biogas",
        "volume_tco2e": 2604807,
        "value_USD": 6806298.599230524,
        "vwap": 2.61,
        "n_resp": 21,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2020,
        "project_category": "Renewable Energy",
        "project_cluster": "Biomass / Biochar",
        "volume_tco2e": 740502,
        "value_USD": 1432014.1999999997,
        "vwap": 1.93,
        "n_resp": 10,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2020,
        "project_category": "Renewable Energy",
        "project_cluster": "Geothermal",
        "volume_tco2e": 115206,
        "value_USD": 750972.6,
        "vwap": 6.52,
        "n_resp": 3,
        "cat_ordinal": 6
      },
      {
        "year_transact": 2020,
        "project_category": "Transportation",
        "project_cluster": "Public Transportation",
        "volume_tco2e": 1058366,
        "value_USD": 654709.7999999999,
        "vwap": 0.62,
        "n_resp": 3,
        "cat_ordinal": 7
      },
      {
        "year_transact": 2020,
        "project_category": "Waste Disposal",
        "project_cluster": "Waste Gas Recovery",
        "volume_tco2e": 5558664,
        "value_USD": 13511787.399999999,
        "vwap": 2.43,
        "n_resp": 19,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2020,
        "project_category": "Waste Disposal",
        "project_cluster": "Unspecified Waste Disposal",
        "volume_tco2e": 1700882,
        "value_USD": 5488786.28,
        "vwap": 3.23,
        "n_resp": 3,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2020,
        "project_category": "Waste Disposal",
        "project_cluster": "Waste Gas Avoidance",
        "volume_tco2e": 76771,
        "value_USD": 617685.866551664,
        "vwap": 8.05,
        "n_resp": 7,
        "cat_ordinal": 8
      },
      {
        "year_transact": 2020,
        "project_category": "Waste Disposal",
        "project_cluster": "Recycling",
        "volume_tco2e": 45480,
        "value_USD": 1023365.18,
        "vwap": 22.50,
        "n_resp": 4,
        "cat_ordinal": 8
      }
    ];

    
    const updateChart = (initialLoad = false, yearRangeUpdated = false) => {
        if( initialLoad ){
            axios.get(URL.get_resp_category_clusters).then((response) => {
                let categoryClusters = response.data;
                categoryClusters = tempdata;
                setSeriesData(categoryClusters);

                console.log('categoryClusters', categoryClusters);

                
                let all_years = [];
                let all_categories = [];
                let all_clusters = [];
                let cat_ordinals = {};
                
                let volume_max = 0;
                categoryClusters.forEach((d) => {
                    if (!all_years.includes(d.year_transact)) {
                        all_years.push(d.year_transact);
                    }
                    if (!all_categories.includes(d.project_category)) {
                        all_categories.push(d.project_category);
                        cat_ordinals[d.project_category] = d.cat_ordinal - 1;
                    }
                    if (!all_clusters.includes(d.project_cluster)) {
                        all_clusters.push(d.project_cluster);
                    }
                    if (d.volume_tco2e > volume_max) {
                        volume_max = d.volume_tco2e;
                    }
                    
                    
                });

                all_years.sort();
                all_categories.sort();
                
                setYears(all_years);
                setYearMin(all_years[0]);
                setYearMax(all_years[all_years.length - 1]);
                setCategories(all_categories);
                setClusters(all_clusters);
                setCatOrdinals(cat_ordinals);
                


                //setYears(all_years);
                console.log('years', all_years);

                

                
                let VWAPClusterDataObj = {};

                all_categories.forEach((category) => {
                    VWAPClusterDataObj[category] = {};  
                });

                all_categories.forEach((category) => {
                    all_clusters.forEach((cluster) => {
                        VWAPClusterDataObj[category][cluster] = {x:cat_ordinals[category], y:0, z:0, cluster: cluster, valueUSD: 0, visible: false};
                    });
                });

                console.log('VWAPClusterDataObj', VWAPClusterDataObj);
                
                categoryClusters.forEach((d) => {
                    if( d.vwap ){   
                        VWAPClusterDataObj[d.project_category][d.project_cluster].valueUSD += d.value_USD;
                        VWAPClusterDataObj[d.project_category][d.project_cluster].z += d.volume_tco2e;
                    }
                });

                all_categories.forEach((category) => {
                  all_clusters.forEach((cluster) => {
                    if( VWAPClusterDataObj[category][cluster].z > 0 ){
                        VWAPClusterDataObj[category][cluster].y = VWAPClusterDataObj[category][cluster].valueUSD / VWAPClusterDataObj[category][cluster].z;
                        let radius = Math.pow(VWAPClusterDataObj[category][cluster].z,0.5)/200;
                        if( VWAPClusterDataObj[category][cluster].z < 500000 ){
                            radius = 3.5355339059;
                        }
                      
                        VWAPClusterDataObj[category][cluster].visible = true;
                        VWAPClusterDataObj[category][cluster].marker = {radius: radius, symbol: 'circle'};
                    }
                    });
                });


                // categoryClusters.forEach((d) => {
                    
                //     // VWAPClusterDataObj[d.project_category]['data'].push({
                //     //     name: d.project_cluster,
                //     //     cluster: d.project_cluster,
                //     //     vwap: d.vwap,
                //     //     value: d.vwap,
                //     //     volume: d.volume_tco2e,
                //     //     category: d.project_category,
                //     // });
                //     if( d.vwap ){
                //         let radius = Math.pow(d.volume_tco2e,0.5)/200;
                //         if( d.volume_tco2e < 500000 ){
                //             radius = 3.5355339059;
                //         }
                //         VWAPClusterDataObj[d.project_category][d.project_cluster] = {x:cat_ordinals[d.project_category], y:d.vwap, z:d.volume_tco2e, cluster: d.project_cluster, visible: true, marker: {radius: radius, symbol: 'circle'}};
                //     }
                    
                // });

                
                console.log('VWAPClusterDataObj', VWAPClusterDataObj);

                let VWAPClusterData = [];
                all_categories.forEach((category) => {
                    let clusterData = [];
                    all_clusters.forEach((cluster) => {
                        clusterData.push(VWAPClusterDataObj[category][cluster]);
                    });
                    VWAPClusterData.push({
                        name: category,
                        data: clusterData,

                    });
                });
                
                

                let plot_options = {
                    
                    scatter: {
                        showInLegend: false,
                        jitter: {
                            x: 0.4,
                            y: 0
                        },
                        marker: {
                            radius: 5,
                            symbol: 'circle',
                        },
                        tooltip: {
                            pointFormat: '<strong>{point.cluster}</strong><br />Price: ${point.y:.2f}<br />Volume: {point.z:,.0f} tCO2e'
                        }
                    }
                };

                console.log('VWAPClusterData', VWAPClusterData);
                setChartOptions({ ...initialBubbleChartOptions, xAxis: { categories: categories }, yAxis: { title: { text: '' } }, series: VWAPClusterData, plotOptions: plot_options, });
                setDataReady(true);
                
                

            });
        } else {
            let VWAPClusterDataObj = {};

            categories.forEach((category) => {
                VWAPClusterDataObj[category] = {};  
            });

            categories.forEach((category) => {
                clusters.forEach((cluster) => {
                    VWAPClusterDataObj[category][cluster] = { x:catOrdinals[category], y:0, z:0, valueUSD:0, cluster: cluster, visible:false};
                });
            });

            console.log('VWAPClusterDataObj', VWAPClusterDataObj);
            
            
            // let volume_max = 0;
            // seriesData.forEach((d) => {
            //     if( d.year_transact >= yearMin && d.year_transact <= yearMax ) {
            //         if( d.volume_tco2e > volume_max ){
            //             volume_max = d.volume_tco2e;
            //         }
            //     }
            // });

            seriesData.forEach((d) => {
              if( d.year_transact >= yearMin && d.year_transact <= yearMax ) {
                if( d.vwap ){   
                    VWAPClusterDataObj[d.project_category][d.project_cluster].valueUSD += d.value_USD;
                    VWAPClusterDataObj[d.project_category][d.project_cluster].z += d.volume_tco2e;
                }
              }
            });
            categories.forEach((category) => {
              clusters.forEach((cluster) => {
                if( VWAPClusterDataObj[category][cluster].z > 0 ){
                    VWAPClusterDataObj[category][cluster].y = VWAPClusterDataObj[category][cluster].valueUSD / VWAPClusterDataObj[category][cluster].z;
                    let radius = Math.pow(VWAPClusterDataObj[category][cluster].z,0.5)/200;
                    if( VWAPClusterDataObj[category][cluster].z < 500000 ){
                        radius = 3.5355339059;
                    }
                  
                    VWAPClusterDataObj[category][cluster].visible = true;
                    VWAPClusterDataObj[category][cluster].marker = {radius: radius, symbol: 'circle'};
                }
                });
            });
            // seriesData.forEach((d) => {
            //     if( d.year_transact >= yearMin && d.year_transact <= yearMax ) {
            //         // VWAPClusterDataObj[d.project_category]['data'].push({
            //         //     name: d.project_cluster,
            //         //     cluster: d.project_cluster,
            //         //     vwap: d.vwap,
            //         //     value: d.vwap,
            //         //     volume: d.volume_tco2e,
            //         //     category: d.project_category,
            //         // });
            //         if( d.vwap ){
            //             let radius = Math.pow(d.volume_tco2e,0.5)/200;
            //             if( d.volume_tco2e < 500000 ){
            //                 radius = 3.5355339059;
            //             }
            //             VWAPClusterDataObj[d.project_category][d.project_cluster] = { x : catOrdinals[d.project_category], y:d.vwap, z:d.volume_tco2e, cluster: d.project_cluster, visible:true, marker: {radius: radius, symbol: 'circle'}};    
            //         }
            //     }
            // });

            

          // all_categories.forEach((category) => {
          //   all_clusters.forEach((cluster) => {
          //     if( VWAPClusterDataObj[category][cluster].z > 0 ){
          //         VWAPClusterDataObj[category][cluster].y = VWAPClusterDataObj[category][cluster].valueUSD / VWAPClusterDataObj[category][cluster].z;
          //         let radius = Math.pow(VWAPClusterDataObj[category][cluster].z,0.5)/200;
          //         if( VWAPClusterDataObj[category][cluster].z < 500000 ){
          //             radius = 3.5355339059;
          //         }
                
          //         VWAPClusterDataObj[category][cluster].visible = true;
          //         VWAPClusterDataObj[category][cluster].marker = {radius: radius, symbol: 'circle'};
          //     }
          //     });
          // });  


            
            console.log('VWAPClusterDataObj', VWAPClusterDataObj);

            let VWAPClusterData = [];
            categories.forEach((category) => {
                let clusterData = [];
                clusters.forEach((cluster) => {
                    clusterData.push(VWAPClusterDataObj[category][cluster]);
                });
                VWAPClusterData.push({
                    name: category,
                    data: clusterData,

                });
            });
            
            

            let plot_options = {
                
                scatter: {
                    showInLegend: false,
                    jitter: {
                        x: 0.4,
                        y: 0
                    },
                    marker: {
                        radius: 2,
                        symbol: 'circle',
                        
                        
                    },
                    tooltip: {
                        pointFormat: '<strong>{point.cluster}</strong><br />Price: ${point.y:.2f}<br />Volume: {point.z:,.0f} tCO2e'
                    }
                }
            };

            console.log('categories', categories);

            console.log('VWAPClusterData2', VWAPClusterData);
            setChartOptions({ ...initialBubbleChartOptions, xAxis: { categories: categories }, yAxis: { title: { text: '' } }, series: VWAPClusterData, plotOptions: plot_options });

        }
    };

    useEffect(() => {
        updateChart(true);
    }, []);

    useEffect(() => {
        updateChart(false);
    }, [yearMin, yearMax]);

    

       
    

       

        
    

            



    return (
        <React.Fragment>

            
            
            
            <style dangerouslySetInnerHTML={{__html: `
            .highcharts-credits { display:none }
            table td, table th { font-size:14px; padding:4px 8px; }
            #category-and-cluster .highcharts-markers path { opacity:0.55;}
            
            `}} />

<Row>
            <Col className="col-12 col-lg-12"><CardHeader><strong style={{fontSize : "1.4em"}}>Price and Volume by Category and Cluster ({yearMin} - {yearMax})</strong></CardHeader></Col>
            </Row>
            { /* insert years dropdown selector here */ }
            <CardHeader>
                <Row>
                    <Col className="col-12 col-lg-12 dateRangeSelectors">
                        <strong>Date Range: <label style={{ textTransform:"none", marginLeft:"20px", fontWeight: "bold"}}>Year Min</label> </strong>
                        
                        <select id="yearMin" name="yearMin" onChange={(e) => {
                            let selectedYear = e.target.value;
                            console.log('selectedYear', selectedYear);
                            
                            setYearMin(selectedYear);
                        }}>
                            { years.map((year, index) => {
                                if( year <= yearMax ){
                                    return <option key={index} value={year} selected={year == yearMin}>{year}</option>
                                }
                            })}
                            
                        </select>

                        <strong>Year Max </strong>
                        
                        <select id="yearMax" name="yearMax" onChange={(e) => {
                            let selectedYear = e.target.value;
                            console.log('selectedYear', selectedYear);
                            setYearMax(selectedYear);
                        }}>
                            { years.reverse().map((year, index) => {
                                if( year >= yearMin ){
                                    return <option key={index} value={year} selected={year == yearMax}>{year}</option>
                                }
                            })}
                            {years.reverse()}
                            
                        </select>

                        { (yearMin != years[0] || yearMax != years[years.length - 1]) && <><input type="checkbox" id="resetYearRange" name="resetYearRange" value="resetYearRange" onChange={(e) => {
                            if( e.target.checked ){
                                setYearMin(years[0]);
                                setYearMax(years[years.length - 1]);
                                e.target.checked = false;
                            }
                        }} /> Reset Date Range</> }
                    </Col>
                </Row>    

            </CardHeader>

            <Row>
                <Col className="col-12 col-lg-12"><div id="category-and-cluster">
                {dataReady && <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />}</div>
                </Col>
            </Row>

            
        </React.Fragment>
    );


}

export default StandardsScatterWithJitterPrivate;